import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import defaultImage from "../../assets/img/Company-Placeholder.png";
import { useHistory } from "react-router";
import { useGQLContext } from "../../api_client/client";
import ProfileIcon from "../../assets/icons/ProfileIcon";
import { withApiValue } from "../../modules/WithApiValue";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAppValue } from "../../contexts/Context";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";

const config = {
  company: { displayImage: true, name: true, fullName: true },
  profile: { firstName: true, lastName: true },
};

const Loader = () => (
  <Skeleton className="rounded desktop" height={45} width={100} />
);

function CompanyDropdown(props) {
  const [context] = useAppValue();
  const history = useHistory();
  const [, dispatch] = useGQLContext();
  const userLevel = context.user.isOwner
    ? "Owner"
    : context.user.isOwnerOrAdmin
    ? "Administrator"
    : "User";
  const isWireToEFTMode = useIsWireToEFTMode();

  return (
    <UncontrolledDropdown className="admin-nav-dropdown">
      <DropdownToggle style={{ paddingLeft: "12px" }} className="pr-0" nav>
        <img
          width={25}
          height={25}
          style={{ marginBottom: "2px" }}
          src={props.company?.displayImage || defaultImage}
          alt="Company Logo"
        />
        <span style={{ lineHeight: "30px" }} className="ml-2 font-weight-600">
          {props.company?.fullName ? (
            <>
              {props.company?.fullName.length > 32 ? (
                <span>{`${props.company?.fullName.substring(0, 32)}...`}</span>
              ) : (
                props.company?.fullName
              )}
            </>
          ) : (
            <span>{`${props.company?.name.substring(0, 32)}...`}</span>
          )}
        </span>
      </DropdownToggle>
      <DropdownMenu
        right
        className="custom-dropdown-menu dropdown-menu-lg pb-1"
      >
        <hr />
        <div style={{ cursor: "default" }} className="mt-3 mb-3">
          <div style={{ width: "24px" }} className="ml-3 mr-2 mt-1 float-left">
            <ProfileIcon width={22} height={22} />
          </div>
          <div>
            <span className="font-weight-600 d-inline-block">
              {props.profile?.firstName || "New"}{" "}
              {props.profile?.lastName || "User"}
            </span>
            <br />
            <h5 className="small text-muted mb-0">{userLevel}</h5>
          </div>
        </div>
        {!isWireToEFTMode && (
          <>
            <hr />
            <DropdownItem
              onClick={() => history.push("/admin/settings#company")}
            >
              <span className="font-weight-bold">Edit company</span>
            </DropdownItem>
            <hr />
            <DropdownItem
              onClick={() => history.push("/admin/settings#profile")}
            >
              <span className="font-weight-bold">Edit profile</span>
            </DropdownItem>
            <hr />
          </>
        )}
        <DropdownItem onClick={() => dispatch({ type: "logout" })}>
          <span className="font-weight-600 text-danger">Sign out</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default withApiValue(CompanyDropdown, config, Loader);

CompanyDropdown.propTypes = {
  company: PropTypes.object,
  profile: PropTypes.object,
};
