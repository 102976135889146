import { useAppValue } from "../contexts/Context";

/**
WireToEFTMode is a mode of Jetpay that is customized for merchants that want to use Jetpay for cross border payables.
Since Jetpay does not currently support cross-border payments directly, this mode provides a workaround flow in which merchants can send funds from 
a US bank account to a CA bank account.

In this flow, merchants wire funds from their US bank account to a designated CA bank account owned by Baseline. 
Then, the funds are sent from this bank account to the destination bank account acccount via EFT.
*/
export const useIsWireToEFTMode = () => {
  const [context] = useAppValue();
  const isWireToEFTMode = context.user?.company?.settings?.isWireToEftMode;
  return !!isWireToEFTMode;
};
