import { useSimpleContext } from "contexts/SimpleContext";
import React, { Fragment, useEffect, useState } from "react";
import useGQL from "api_client/UseGQL";
import { Button, Col, Row } from "reactstrap";
import pickBy from "lodash.pickby";
import { PulseLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { CustomTooltip, pluralize } from "modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { createBatchBankAccountCredit } from "api_client/mutations/transaction";
import { batchCharges } from "api_client/queries/wireToEft";
import {
  BULK_CREDIT_BASE_PATH,
  getStepNumber,
  STEPS,
} from "../BulkCreditFlows";
import { useToasts } from "react-toast-notifications";
import { CustomLoader } from "components/loaders/CustomLoader";

export const BulkCreditReviewWireToEFTMode = () => {
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const numTransactions = state.bulkCredits?.length || 0;
  const many = numTransactions > 1;
  const [transactionsFee, setTransactionsFee] = useState(0);
  const [batchFee, setBatchFee] = useState(0);
  const [monthlyFee, setMonthlyFee] = useState(0);
  const [batchAmount, setBatchAmount] = useState(0);
  const [disbursedAmount, setDisbursedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const { addToast } = useToasts();

  useEffect(async () => {
    setLoading(true);
    let transactions = state.bulkCredits?.map((transaction) => ({
      amount: transaction.amount,
    }));
    if (transactions) {
      let input = { transactions: transactions };
      let output = {
        transactionsNumber: true,
        transactionsAmount: true,
        transactionsDisbursementAmount: true,
        transactionsFee: true,
        batchFee: true,
        monthlyFee: true,
        totalAmount: true,
      };
      const response = await batchCharges(input, output, gqlHooks);

      setBatchFee(Number(response.data.batchFee));
      setMonthlyFee(Number(response.data.monthlyFee));
      setTransactionsFee(Number(response.data.transactionsFee));
      setBatchAmount(Number(response.data.transactionsAmount));
      setDisbursedAmount(Number(response.data.transactionsDisbursementAmount));
      setTotalAmount(Number(response.data.totalAmount));
      setLoading(false);
    }
  }, []);

  const createBulkCredits = async () => {
    const creditBatch = state.bulkCredits.map((rawCredit) => {
      const credit = {
        name: rawCredit.contact.name,
        amount: rawCredit.amount,
        statement: rawCredit.statement,
        note: rawCredit.note,
        toRoutingInfo: {
          accountNumber: rawCredit.toBankAccountRoutingInfo.accountNumber,
          transitNumber: rawCredit.toBankAccountRoutingInfo.transitNumber,
          institutionNumber:
            rawCredit.toBankAccountRoutingInfo.institutionNumber,
        },
      };
      return pickBy(credit, (creditProp) => creditProp);
    });
    const input = {
      routingInfoCreditBatch: creditBatch,
    };
    const output = {
      transactionsAmount: true,
      transactionsFee: true,
      monthlyFee: true,
      batchFee: true,
      totalAmount: true,
      validationErrors: {
        index: true,
        identifier: true,
        message: true,
      },
      transactions: {
        identifier: true,
      },
    };
    return await createBatchBankAccountCredit(input, output, gqlHooks);
  };

  const handleValidationErrors = (validationErrors) => {
    validationErrors.forEach((error) => {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    });

    const detailsPath = BULK_CREDIT_BASE_PATH + STEPS.DETAILS;
    const stepNumber = getStepNumber(detailsPath, state.flow);

    history.push(detailsPath, {
      ...history.location.state,
      currStep: stepNumber,
    });
  };

  const submit = async () => {
    setLoading(true);

    const response = await createBulkCredits();
    const { validationErrors } = response || {};

    if (validationErrors?.length) {
      handleValidationErrors(validationErrors);
      setLoading(false);
      return;
    }

    if (response) {
      setState({
        ...state,
        totalAmountSent: response.transactionsAmount,
        transactions: response.transactions,
      });

      setLoading(false);

      const completePath = BULK_CREDIT_BASE_PATH + STEPS.COMPLETE;
      const stepNumber = getStepNumber(completePath, state.flow);

      history.push(completePath, {
        ...history.location.state,
        stepNumber,
      });
    }
  };

  if (loading && !totalAmount) {
    return (
      <div className="content">
        <CustomLoader size={30} general={true} />
      </div>
    );
  }

  return (
    <Fragment>
      <h2 className="text-center">Review draft {pluralize("payment", many)}</h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span className="text-center">
          You&apos;re creating {numTransactions} {pluralize("payment", many)}{" "}
          totalling <b>{formattedDollarValue(batchAmount)}</b>.{" "}
          <>
            {pluralize(
              <>
                This is a <b>draft payment</b>
              </>,
              many,
              <>
                These are <b>draft payments</b>
              </>
            )}{" "}
            and will require an additional approval.
          </>
        </span>
      </div>
      <div className="new-payment-inner-content">
        <div className="review-payment">
          <Row>
            <Col>
              <span>Batch Amount</span>
              <h3>{formattedDollarValue(batchAmount)}</h3>
            </Col>
            <Col>
              <span>Number of payments</span>
              <h3>{numTransactions}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Transactions Fee</span>
              <h5>{formattedDollarValue(transactionsFee)}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Batch Fee</span>
              <h5>{formattedDollarValue(batchFee)}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Monthly Fee</span>
              <h5>{formattedDollarValue(monthlyFee)}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Disbursed Amount</span>{" "}
              <CustomTooltip
                title="Amount to be credited (batch amount after transaction fees)"
                placement="top"
                arrow
              >
                <i className="fas fa-info-circle" />
              </CustomTooltip>
              <h3>{formattedDollarValue(disbursedAmount)}</h3>
            </Col>
            <Col>
              <span>Total Amount Due</span>{" "}
              <CustomTooltip
                title="Total amount to be wired"
                placement="top"
                arrow
              >
                <i className="fas fa-info-circle" />
              </CustomTooltip>
              <h2>{formattedDollarValue(totalAmount)}</h2>
            </Col>
          </Row>
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          type="button"
          className="btn-primary text-black mt-4"
        >
          {loading ? (
            <PulseLoader color="white" size={10} />
          ) : (
            <>{`Create draft ${pluralize("payment", many)}`}</>
          )}
        </Button>
      </div>
    </Fragment>
  );
};
