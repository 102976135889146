import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import BankAccountSelection from "../../../../../components/dropdowns/BankAccountSelection";
import AddIcon from "../../../../../assets/icons/AddIcon";
import { useHistory } from "react-router";
import { formattedDollarValue } from "modules/Formatters";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import AddBankAccountModal from "../../../../../components/modals/AddBankAccountModal";
import { useLocation } from "react-router-dom";
import { useAppValue } from "contexts/Context";

function DebitAccountStep() {
  const [context] = useAppValue();
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [account, setAccount] = useState();
  const [newAccountModal, setNewAccountModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (state.toBankAccount) {
      setAccount(state.toBankAccount);
    }
  }, []);

  return (
    <Fragment>
      <AddBankAccountModal
        toggle={() => setNewAccountModal(!newAccountModal)}
        isOpen={newAccountModal}
        title={"Add bank account"}
      />
      <h2 className="text-center">Select your deposit account</h2>
      {location.pathname.includes("bulk") ? null : (
        <span className="text-center mb-2">
          You&apos;re requesting a{" "}
          <b>
            {typeof state.frequency === "object"
              ? state.frequency.value
              : state.frequency}
          </b>{" "}
          payment of <b>{formattedDollarValue(state.amount)}</b> from{" "}
          <b>
            {state.contactAccount.name
              ? state.contactAccount.name
              : state.contactAccount.email}
          </b>
          .
        </span>
      )}
      <div className="new-payment-inner-content">
        <Label>Deposit account</Label>
        <Row className="new-payment-account-wrapper">
          {!context.user.company?.isUsingConverge && (
            <Button
              color="links"
              className="btn-link new-payment-new-account-btn"
              onClick={() => setNewAccountModal(true)}
            >
              <AddIcon /> New account
            </Button>
          )}
          <Col>
            <FormGroup>
              <BankAccountSelection
                bankAccount={account}
                setBankAccount={setAccount}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          onClick={() => {
            setState({ ...state, toBankAccount: account });
            if (
              typeof state.frequency === "object" &&
              state.frequency !== null
            ) {
              history.push("review", {
                ...history.location.state,
                currStep: 6,
              });
            } else if (location.pathname.includes("bulk")) {
              history.push("details", {
                ...history.location.state,
                currStep: 2,
              });
            } else {
              history.push("delivery-method", {
                ...history.location.state,
                currStep: 5,
              });
            }
          }}
          disabled={!account || account.value === ""}
          block
          color="primary"
          type="button"
        >
          Proceed
        </Button>
      </div>
    </Fragment>
  );
}

export default DebitAccountStep;

DebitAccountStep.propTypes = {};
