import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function DetailsCloseButton({ onClick, disabled }) {
  return (
    <Button
      className="btn-link details-close-btn"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );
}

export default DetailsCloseButton;

DetailsCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
