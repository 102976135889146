import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import useGQL from "../../../api_client/UseGQL";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { trackEvent } from "../../../modules/analytics";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PADModal from "../../../components/modals/PADModal";
import {
  appName,
  BUSINESS,
  CREDIT_CARD_FEE_RATE,
  CustomTooltip,
  formatDate,
} from "../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";

function RecurringPaymentReviewDetails(props) {
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  let gqlHooks = useGQL();
  const [signaturePAD, setSignaturePAD] = useState(false);
  const [tooltipPADIsOpen, setTooltipPADIsOpen] = useState(false);
  const [padModal, setPadModal] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  useEffect(() => {
    trackEvent("toggleSignaturePad", {}, gqlHooks);
    setTooltipPADIsOpen(false);
    if (signaturePAD) {
      setState({
        ...state,
        signaturePAD: signaturePAD,
        signaturePADTime: moment().unix(),
      });
    }
  }, [signaturePAD]);

  const agreeToPAD = (event) => {
    setSignaturePAD(event.target.checked);
  };

  return (
    <Fragment>
      {padModal && (
        <PADModal
          isOpen={true}
          toggle={() => setPadModal(!padModal)}
          amount={state?.recurringPlan.amount}
          recurringFrequency={state?.recurringPlan.frequency.slice(0, -2)}
          agreeToPAD={agreeToPAD}
          signaturePad={signaturePAD}
          type={BUSINESS}
        />
      )}
      <Container>
        <div className="payment-info">
          <Row>
            <Col
              style={{ paddingTop: "34px", paddingBottom: "34px" }}
              className="text-center"
            >
              <span className="text-xl font-weight-600">Review & Accept</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="mt-0">Recurring Payment Request</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Amount</h5>
              <p>{formattedDollarValue(state?.recurringPlan.amount)}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Frequency</h5>
              <p>{state.recurringPlan.frequency.toLowerCase().capitalize()}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Paid to</h5>
              <p>
                {state?.recurringPlan.company.fullName ||
                  state?.recurringPlan.company.name}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Start date</h5>
              <p>{formatDate(state?.recurringPlan.start)}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>End date</h5>
              <p>
                {state?.recurringPlan.end
                  ? formatDate(state?.recurringPlan.end)
                  : "Continue until cancelled"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Statement</h5>
              <p>{state.recurringPlan.statement}</p>
            </Col>
          </Row>
          {state.recurringPlan.description && (
            <Row>
              <Col>
                <h5>Notes</h5>
                <p className="font-italic">
                  <q>{state.recurringPlan.description}</q>
                </p>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <h4 className="mb-1">Payment Method</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{props.paymentMethod}</h5>{" "}
                <Button
                  aria-label="Change payment method"
                  className="btn-link btn-links btn-sm pr-0"
                  onClick={() => {
                    setState({ ...state, bankAccount: undefined });
                    history.push("bank-transfer");
                  }}
                >
                  Change...
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{ background: "#4e4ef51a", marginBottom: "10px" }}
                className="p-3 rounded justify-content-between d-flex"
              >
                <span>{props.paymentMethodBrand}</span>
                <span>{props.paymentMethodNumber}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Charges</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Debit request</h5>
              <p>{formattedDollarValue(state.recurringPlan.amount)}</p>
            </Col>
          </Row>
          {props.paymentCharges ? (
            <>
              <Row>
                <Col>
                  <h5>Platform fee</h5>{" "}
                  <CustomTooltip
                    title={`${appName} charges a 3.5% platform fee for using this checkout system. This is not a credit card service fee`}
                    placement="top"
                    arrow
                    open={tooltipIsOpen}
                    onOpen={() => setTooltipIsOpen(true)}
                    onClose={() => setTooltipIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <p>
                    {formattedDollarValue(
                      state.recurringPlan.amount * CREDIT_CARD_FEE_RATE
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5>Total</h5>{" "}
                  <p className="font-weight-bold text-xl">
                    {formattedDollarValue(
                      (1 + CREDIT_CARD_FEE_RATE) * state.recurringPlan.amount
                    )}
                    <span className="text-muted font-weight-400 text-sm">
                      /
                      {state.recurringPlan.frequency.slice(0, -2).toLowerCase()}
                    </span>
                  </p>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <h5>Bank transfer fee</h5> <p>{formattedDollarValue(0.0)}</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5>Total</h5>{" "}
                  <p className="font-weight-bold text-xl">
                    {formattedDollarValue(state.recurringPlan.amount)}{" "}
                    <span className="text-muted font-weight-400 text-sm">
                      /
                      {state.recurringPlan.frequency.slice(0, -2).toLowerCase()}
                    </span>
                  </p>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col className="mt-4">
              <h5>Your confirmation email</h5>
              <p>{state?.recurringPlan.contact?.email}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mt-4">
                <FormGroup check>
                  <Label className="form-check-label">
                    <Input
                      id="cb-1"
                      className="form-check-input"
                      type="checkbox"
                      checked={signaturePAD}
                      onChange={(e) => agreeToPAD(e)}
                    />
                    <CustomTooltip
                      title="Please read and agree to the terms and conditions before paying."
                      placement="top"
                      arrow
                      open={tooltipPADIsOpen}
                    >
                      <span className="form-check-sign">
                        <span className="check" />
                      </span>
                    </CustomTooltip>
                    I agree to the terms and conditions of the {appName}{" "}
                    <span
                      onClick={(e) => {
                        setPadModal(!padModal);
                        e.preventDefault();
                      }}
                      className="link"
                      style={{ color: "#0000FF" }}
                    >
                      pre-authorized debit agreement
                    </span>
                    .
                  </Label>
                </FormGroup>
              </div>
              <Button
                aria-label="Accept recurring plan now"
                onClick={props.acceptAction}
                block
                className="btn-primary btn-lg mb-4 mt-4"
                disabled={props.loading || !signaturePAD}
              >
                {props.loading ? (
                  <PulseLoader color="white" size={10} />
                ) : (
                  "Accept now"
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default RecurringPaymentReviewDetails;

RecurringPaymentReviewDetails.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  paymentMethodBrand: PropTypes.string,
  paymentMethodNumber: PropTypes.string,
  acceptAction: PropTypes.func,
  loading: PropTypes.any,
  paymentCharges: PropTypes.bool,
};
