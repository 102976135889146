import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DownloadButton({ onClick }) {
  return (
    <Button onClick={onClick} size="sm" color="primary" className="mb-0">
      <span className="d-inline-block">
        <FontAwesomeIcon className="mr-2 mt-1 mb-0" icon={faFileDownload} />
      </span>
      <span className="btn-wrapper--label d-inline-block">Download</span>
    </Button>
  );
}

export default DownloadButton;

DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
