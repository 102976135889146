export class FileReader {
  readAsArrayBuffer(blob) {
    const promise = this._initializeFileReader();
    promise.reader.readAsArrayBuffer(blob);
    return promise;
  }

  readAsDataURL(blob) {
    const promise = this._initializeFileReader();
    promise.reader.readAsDataURL(blob);
    return promise;
  }

  readAsText(blob) {
    const promise = this._initializeFileReader();
    promise.reader.readAsText(blob);
    return promise;
  }

  abort(promise) {
    promise.reader.abort();
  }

  _initializeFileReader() {
    const reader = new global.FileReader();

    const promise = new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });

    promise.reader = reader;

    return promise;
  }
}
