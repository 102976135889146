import React, { Fragment } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AddIcon from "../../assets/icons/AddIcon";
import { useHistory } from "react-router";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";

function NewPaymentDropdown() {
  const history = useHistory();
  const isWireToEFTMode = useIsWireToEFTMode();

  return (
    <Fragment>
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle
          className="custom-dropdown-button btn-primary font-weight-600 ml-0"
          style={{ marginRight: "12px" }}
          data-toggle="dropdown"
        >
          <AddIcon /> Payment
        </DropdownToggle>
        <DropdownMenu className="custom-dropdown-menu dropdown-menu-md">
          {!isWireToEFTMode && (
            <DropdownItem
              onClick={() =>
                history.push("/new-payment/debit/frequency", {
                  currStep: 1,
                  steps: 8,
                })
              }
            >
              <span className="font-weight-600">Receive</span>
            </DropdownItem>
          )}
          <hr />
          <DropdownItem
            onClick={() =>
              history.push("/new-payment/credit/payment-type", {
                currStep: 1,
                steps: 7,
              })
            }
          >
            <span className="font-weight-600">Send</span>
          </DropdownItem>
          <hr />
          {!isWireToEFTMode && (
            <DropdownItem
              onClick={() =>
                history.push("/new-payment/internal/details", {
                  currStep: 1,
                  steps: 4,
                })
              }
            >
              <span className="font-weight-600">Transfer</span>
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>
  );
}

export default NewPaymentDropdown;
