import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { formatDate } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import CardInfoRow from "./cardElements/CardInfoRow";
import { formatMonthYear } from "modules/Formatters";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

function MonthlyStatementsCard(props) {
  const history = useHistory();

  return (
    <Fragment>
      <Card
        className="t-card shadow-sm border p-3"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=${OVERLAY_TYPES.MONTHLY_STATEMENT}&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row">
          <h5 className="text-primary font-weight-600">
            {formatMonthYear(`${props.item.year}-${props.item.month}`)}
          </h5>
        </div>
        <CardInfoRow
          label={"Opening Date"}
          value={formatDate(props.item.openingDate)}
        />
        <CardInfoRow
          label={"Closing Date"}
          value={formatDate(props.item.closingDate)}
        />
        <CardInfoRow
          label={"Number of Batches"}
          value={props.item.numberOfBatches}
        />
        <CardInfoRow
          label={"Opening Balance"}
          value={formattedDollarValue(props.item.openingBalance)}
        />
        <CardInfoRow
          label={"Closing Balance"}
          value={formattedDollarValue(props.item.closingBalance)}
        />
      </Card>
    </Fragment>
  );
}

export default MonthlyStatementsCard;

MonthlyStatementsCard.propTypes = {
  item: PropTypes.object,
};
