import React from "react";
import {
  BaseBulkCreditDetailsPage,
  COLUMN_IDS,
} from "../components/BaseBulkCreditDetailsPage";

export const BulkCreditDetailsWireToEFTModeMode = () => {
  const sampleImportData = [
    [
      "name (required.max 64 characters)",
      "amount (min $10 & max $600000)",
      "statement (max 30 characters)",
      "note (max 200 characters)",
      "institution number (3 digits)",
      "transit number (5 digits)",
      "account number (7-12 digits)",
    ],
    [
      "Kevin Smith",
      "388.81",
      "INV120",
      "Dishwasher repair",
      "777",
      "77777",
      "7777777",
    ],
    ["Amy Johnson", "1000.5", "INV121", "Furniture", "777", "77777", "7777777"],
    [
      "Peter Williams",
      "5000",
      "INV122",
      "Cleaning services",
      "777",
      "77777",
      "7777777",
    ],
    ["Sarah Brown", "10000", "INV123", "Services", "777", "77777", "7777777"],
    ["John Garcia", "3000", "INV124", "Services", "777", "77777", "7777777"],
  ];

  const payableWireToEFTModeFormat = {
    // contact fields
    name: {
      value: new RegExp(".+"),
      required: true,
    },
    // transaction data
    amount: {
      value: new RegExp("^\\$?\\d+(\\.\\d{2})?$"),
      required: true,
    },
    statement: {
      value: new RegExp(".+"),
      required: true,
    },
    note: {
      value: new RegExp(".+"),
      required: false,
    },
    // to bank account data
    institutionNumber: {
      value: new RegExp("^\\d{3}$"),
      required: true,
    },
    transitNumber: {
      value: new RegExp("^\\d{5}$"),
      required: true,
    },
    accountNumber: {
      value: new RegExp("^\\d{7,12}$"),
      required: true,
    },
  };

  const obfuscateAccountNumber = (accountNumber) => {
    const numCharsToShow = 3;
    if (accountNumber.length <= numCharsToShow) {
      return accountNumber;
    }
    const plainChars = accountNumber.slice(-numCharsToShow);
    const obfuscatedChars = "*".repeat(accountNumber.length - numCharsToShow);
    return obfuscatedChars + plainChars;
  };

  const jsonToTransactions = async (json) => {
    let transactions = [];
    transactions = json.map((transactionData) => {
      const label = obfuscateAccountNumber(transactionData["account number"]);
      const transactionObject = {
        toBankAccountLabel: label,
        amount: transactionData.amount.toString().replace("$", ""),
        statement: transactionData.statement,
        note: transactionData.note,
        contact: {
          name: transactionData.name,
          tags: [],
        },
        toBankAccountRoutingInfo: {
          accountNumber: transactionData["account number"],
          transitNumber: transactionData["transit number"],
          institutionNumber: transactionData["institution number"],
        },
      };
      return transactionObject;
    });
    return transactions;
  };

  return (
    <BaseBulkCreditDetailsPage
      sampleImportData={sampleImportData}
      csvImportFormat={payableWireToEFTModeFormat}
      jsonToTransactions={jsonToTransactions}
      hiddenColumns={[COLUMN_IDS.SELECTION]}
      disableEdit={true}
      disableNewPaymentButton={true}
    />
  );
};
