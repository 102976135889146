import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allMonthlyStatements(input, output, hooks) {
  const query = buildQuery(input, output, "allMonthlyStatements", false);
  return callQuery(query, hooks);
}

allMonthlyStatements.propTypes = {
  input: PropTypes.shape({}),
  output: PropTypes.element.isRequired,
};

export function monthlyStatement(input, output, hooks) {
  const query = buildQuery(input, output, "monthlyStatement", false);
  return callQuery(query, hooks);
}

monthlyStatement.propTypes = {
  input: PropTypes.shape({ identifier: PropTypes.string.isRequired }),
  output: PropTypes.element.isRequired,
};

export function batchCharges(input, output, hooks) {
  const query = buildQuery(input, output, "batchCharges", false);
  return callQuery(query, hooks);
}

batchCharges.propTypes = {
  input: PropTypes.shape({
    transactions: PropTypes.arrayOf(
      PropTypes.shape({ amount: PropTypes.string.isRequired })
    ).isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function balanceWithOperations(input, output, hooks) {
  const query = buildQuery(input, output, "balanceWithOperations");
  return callQuery(query, hooks);
}

balanceWithOperations.propTypes = {
  input: PropTypes.shape({}),
  output: PropTypes.element.isRequired,
};
