import React, { Fragment, useState } from "react";
import { formattedDollarValue } from "modules/Formatters";
import { Button, Col, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import useGQL from "../../../../../api_client/UseGQL";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { createInternal } from "../../../../../api_client/mutations/transaction";
import BankDetail from "../../../../../components/generic_details/BankDetail";
import InitiateDateInfo, {
  INITIATE,
} from "../../../../../components/generic_details/InitiateDateInfo";
import DepositDateInfo from "../../../../../components/generic_details/DepositDateInfo";

function InternalReviewStep() {
  const location = useLocation();
  const history = useHistory();
  const gqlHooks = useGQL();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const [tooltipInitiatedIsOpen, setTooltipInitiatedIsOpen] = useState(false);
  const [tooltipDepositIsOpen, setTooltipDepositIsOpen] = useState(false);

  async function submit() {
    setLoading(true);
    let input = {
      amount: state.amount,
      fromBankAccountId: state.fromBankAccount.value,
      toBankAccountId: state.toBankAccount.value,
      statement: state.statement,
    };
    let output = {
      transaction: {
        identifier: true,
      },
    };
    createInternal(input, output, gqlHooks).then((response) => {
      setLoading(false);
      if (response) {
        gqlHooks.context.setAlert(null);
        if (response.transaction) {
          history.push("success", { ...location.state, currStep: 3 });
        }
      }
    });
  }

  return (
    <Fragment>
      <h2 className="text-center">Review internal transfer</h2>
      <div className="new-payment-inner-content">
        <h5 className="mb-2">Transfer from</h5>
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3>{formattedDollarValue(state.amount)}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <BankDetail
                description="Your bank account"
                institution={state.fromBankAccount.institution}
                label={state.fromBankAccount.label}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Payment description</span>
              <h5>{state.statement}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Frequency</span>
              <h5>One time</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <InitiateDateInfo
              type={INITIATE}
              isOpen={tooltipInitiatedIsOpen}
              setOpen={setTooltipInitiatedIsOpen}
            />
            <DepositDateInfo
              isOpen={tooltipDepositIsOpen}
              setOpen={setTooltipDepositIsOpen}
            />
          </Row>
        </div>
        <h5 className="mt-4 mb-2">Transfer to</h5>
        <div className="review-payment">
          <BankDetail
            description="Your bank account"
            institution={state.toBankAccount.institution}
            label={state.toBankAccount.label}
          />
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          className="btn-success mt-4"
        >
          Confirm transfer
        </Button>
      </div>
    </Fragment>
  );
}

export default InternalReviewStep;

InternalReviewStep.propTypes = {};
