import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import useGQL from "api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { CustomLoader } from "components/loaders/CustomLoader";
import { monthlyStatement } from "api_client/queries/wireToEft";
import BatchesTable, {
  BATCH_TABLE_COLUMN_IDS,
} from "components/tables/BatchesTable";
import MonthlyStatementPrintContent from "./MonthlyStatementPrintContent";
import BrandedPrintablePageLayout from "components/layouts/BrandedPrintablePageLayout";
import ThankYouMessage from "components/text/ThankYouMessage";
import DetailsCloseButton from "components/buttons/DetailsCloseButton";

function MonthlyStatement(props) {
  const [monthlyStatementDetails, setMonthlyStatementDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();
  const [filters, setFilters] = useState({});

  useEffect(async () => {
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);

      const response = await getMonthlyStatement();

      if (response) {
        setMonthlyStatementDetails(response.data);
        setFilters({
          dateGte: response.data.openingDate,
          dateLte: response.data.closingDate,
        });
        setLoading(false);
        props.setBackdropClick(false);
      }
    } else {
      history.push({ search: "" });
      addToast("Could not load monthly statement", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, []);

  const getMonthlyStatement = async () => {
    let input = {
      identifier: props.id,
    };
    let output = {
      identifier: true,
      openingBalance: true,
      monthlyFees: true,
      numberOfBatches: true,
      batchFees: true,
      numberOfTransactions: true,
      transactionFees: true,
      numberOfFailedTransactions: true,
      failedTransactionFees: true,
      totalDisbursement: true,
      totalReturn: true,
      closingBalance: true,
      year: true,
      month: true,
      openingDate: true,
      closingDate: true,
    };
    return await monthlyStatement(input, output, gqlHooks);
  };

  if (loading) {
    return (
      <div className="content">
        <CustomLoader message="Gathering data... thank you for your patience." />
      </div>
    );
  }
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-end">
        <DetailsCloseButton disabled={loading} onClick={props.toggle} />
      </div>
      <BrandedPrintablePageLayout>
        <MonthlyStatementPrintContent
          monthlyStatementDetails={monthlyStatementDetails}
        />
      </BrandedPrintablePageLayout>
      <h4 className="mt-3">Statement Period Transactions Review</h4>
      <BatchesTable
        filters={filters}
        hiddenColumns={[BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_FEE]}
      />
      <Row>
        <Col lg="6" sm="12" className="text-center text-md-left mb-3">
          <ThankYouMessage />
        </Col>
      </Row>
    </Fragment>
  );
}

MonthlyStatement.propTypes = {
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  setBackdropClick: PropTypes.func,
};

export default MonthlyStatement;
