const OperationTypes = {
  BATCH: { value: "Batch", name: "BATCH" },
  WIRE: { value: "Wire", name: "WIRE" },
  FAILED_TRANSACTIONS: {
    value: "Failed Transactions",
    name: "FAILED_TRANSACTIONS",
  },
};

export default OperationTypes;
