import React from "react";
import { appName } from "modules/Helpers";
import { domains } from "modules/Helpers";

function ThankYouMessage() {
  return (
    <p className="text-black-50" style={{ fontSize: "13px" }}>
      Thank you for using{" "}
      <a
        href={`https://${domains.PRIMARY}/`}
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-500"
      >
        {appName}
      </a>
      . <br />
    </p>
  );
}

export default ThankYouMessage;
