import MonthlyStatementsTable from "components/tables/MonthlyStatementsTable";
import React, { Fragment } from "react";

function MonthlyStatements(props) {
  return (
    <Fragment>
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <div>
            <h2 className="mb-3 font-weight-600 page-title">
              Monthly Statements{" "}
            </h2>
          </div>
        </div>
        <MonthlyStatementsTable {...props} />
      </div>
    </Fragment>
  );
}
export default MonthlyStatements;
