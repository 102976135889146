import React, { Fragment, useEffect, useState } from "react";
// nodejs library that concatenates classes
// reactstrap components
import { Button, Collapse, Nav, Navbar, NavItem } from "reactstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ColorLogo from "../../assets/img/logo-colour.png";
import CompanyDropdown from "../dropdowns/CompanyDropdown";
import FloatingActionButton from "../dropdowns/FloatingActionButton";
import MobileNavOverlay from "../overlays/MobileNavOverlay";
import SandwhichMenu from "../../assets/icons/SandwhichMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { unreadNotifications } from "../../api_client/queries/notifications";
import { CustomTooltip, appName } from "../../modules/Helpers";
import useGQL from "../../api_client/UseGQL";
import NewPaymentDropdown from "components/dropdowns/NewPaymentDropdown";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

function AdminNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const gqlHooks = useGQL();
  const [unreadCompanyNotifications, setUnreadCompanyNotifications] =
    useState();
  const isWireToEFTMode = useIsWireToEFTMode();

  useEffect(() => {
    unreadNotifications({}, { number: true }, gqlHooks).then((response) => {
      if (response) {
        setUnreadCompanyNotifications(response.data.number);
      }
    });
  }, [location]);

  return (
    <Fragment>
      <Navbar
        color="white"
        expand="md"
        className="admin-navbar navbar-expand sticky-top"
      >
        <MobileNavOverlay isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />
        <img
          className="navbar-logo"
          src={ColorLogo}
          alt={`${appName} Logo`}
          style={{ cursor: "pointer" }}
          width={25}
          height={25}
          onClick={() => history.push("/admin/payments")}
        />
        <div className="mobile-nav-title">
          <span className="text-xl font-weight-bold">
            {location.pathname.includes("payments") && "Payments"}
            {location.pathname.includes("plans") && "Plans"}
            {location.pathname.includes("contacts") && "Contacts"}
            {location.pathname.includes("settings") && "Settings"}
          </span>
        </div>
        <Collapse navbar>
          <Nav className="desktop-nav" navbar>
            <NavItem active>
              <NavLink to="/admin/payments">Payments</NavLink>
            </NavItem>
            {isWireToEFTMode ? (
              <>
                <NavItem>
                  <NavLink to="/admin/batches">Batches</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/monthly-statements">
                    Monthly Statments
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem>
                  <NavLink to="/admin/plans">Plans</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/contacts">Contacts</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/settings">Settings</NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
        <div className="new-payment-btn">
          <NewPaymentDropdown />
        </div>
        <CustomTooltip title="Help & Learn" placement="bottom" arrow>
          <Button
            className="btn-link navbar-btn"
            onClick={() =>
              history.push({
                search: `overlay=true&type=${OVERLAY_TYPES.INFORMATION}`,
              })
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Button>
        </CustomTooltip>
        <CustomTooltip
          title={`Notifications (${unreadCompanyNotifications})`}
          placement="bottom"
          arrow
        >
          <Button
            className="btn-link navbar-btn notification-btn"
            onClick={() =>
              history.push({
                search: `overlay=true&type=${OVERLAY_TYPES.NOTIFICATIONS}`,
              })
            }
          >
            <FontAwesomeIcon icon={faBell} />
            {unreadCompanyNotifications > 0 && (
              <div className="notification-count" color="warning">
                {unreadCompanyNotifications > 99
                  ? "99+"
                  : unreadCompanyNotifications}
              </div>
            )}
          </Button>
        </CustomTooltip>
        <CompanyDropdown />
        <div
          style={{ marginLeft: "12px" }}
          className="mobile-collapse-toggler mt-1"
        >
          <div onClick={() => setIsOpen(!isOpen)}>
            <SandwhichMenu width={"20px"} strokewidth={0.5} />
          </div>
          <FloatingActionButton />
        </div>
      </Navbar>
    </Fragment>
  );
}

export default AdminNavbar;
AdminNavbar.propTypes = {};
