/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { convertToDate } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import useGQL from "../../api_client/UseGQL";
import ReactTable from "./ReactTable";
import TransactionCardSkeleton from "../skeleton/TransactionCardSkeleton";
import { balanceWithOperations } from "api_client/queries/wireToEft";
import BatchesCard from "components/cards/BatchesCard";
import { DateCell } from "components/table_cells/DateCell";
import { DollarValueCell } from "components/table_cells/DollarValueCell";

export const BATCH_TABLE_COLUMN_IDS = {
  DATE: "date",
  ID: "id",
  TYPE: "type",
  IS_ACTIVATED: "isActivated",
  TRANSACTIONS_NUMBER: "transactionsNumber",
  TRANSACTIONS_AMOUNT: "transactionsAmount",
  TRANSACTIONS_FEE: "transactionsFee",
  BATCH_FEE: "batchFee",
  MONTHLY_FEE: "monthlyFee",
  AMOUNT: "amount",
  BALANCE: "balance",
};

function BatchesTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let gqlHooks = useGQL();
  const hiddenColumns = props.hiddenColumns;
  const getData = useCallback(async () => {
    setLoading(true);
    const params = {
      ...props.filters,
    };
    let output = {
      operationBalances: {
        operation: {
          date: true,
          id: true,
          operationType: true,
          isActivated: true,
          transactionsNumber: true,
          transactionsAmount: true,
          transactionsFee: true,
          batchFee: true,
          monthlyFee: true,
          amount: true,
        },
        balance: true,
      },
      currentBalance: true,
    };
    const response = await balanceWithOperations(params, output, gqlHooks);
    if (response) {
      setData(response.data["operationBalances"]);
      setLoading(false);
    }
  }, [props.filters]);

  const getColumns = () => {
    return [
      {
        Header: "Date",
        accessor: "operation.date",
        id: BATCH_TABLE_COLUMN_IDS.DATE,
        Cell: DateCell,
      },
      {
        Header: "ID",
        accessor: "operation.id",
        id: BATCH_TABLE_COLUMN_IDS.ID,
        Cell: ({ cell }) => <div className="text-muted">{cell.value}</div>,
      },
      {
        Header: "Type",
        accessor: "operation.operationType",
        id: BATCH_TABLE_COLUMN_IDS.TYPE,
        Cell: ({ cell }) => <div className="text-muted">{cell.value}</div>,
      },
      {
        Header: "Initiated",
        accessor: "operation.isActivated",
        id: BATCH_TABLE_COLUMN_IDS.IS_ACTIVATED,
        Cell: ({ cell }) => (
          <>
            <div className="text-muted">
              {typeof cell.value !== "boolean"
                ? null
                : cell.value
                ? "yes"
                : "no"}
            </div>
          </>
        ),
      },
      {
        Header: "Number of Transactions",
        accessor: "operation.transactionsNumber",
        id: BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_NUMBER,
        Cell: ({ cell }) => <div className="text-muted">{cell.value}</div>,
      },
      {
        Header: "Disbursed Amount",
        accessor: "operation.transactionsAmount",
        id: BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_AMOUNT,
        Cell: DollarValueCell,
      },
      {
        Header: "Transactions Fee",
        accessor: "operation.transactionsFee",
        id: BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_FEE,
        Cell: DollarValueCell,
      },
      {
        Header: "Batch Fee",
        accessor: "operation.batchFee",
        id: BATCH_TABLE_COLUMN_IDS.BATCH_FEE,
        Cell: DollarValueCell,
      },
      {
        Header: "Monthly Fee",
        accessor: "operation.monthlyFee",
        id: BATCH_TABLE_COLUMN_IDS.MONTHLY_FEE,
        Cell: DollarValueCell,
      },
      {
        Header: "Total",
        accessor: "operation.amount",
        id: BATCH_TABLE_COLUMN_IDS.AMOUNT,
        Cell: DollarValueCell,
      },
      {
        Header: "Balance",
        accessor: "balance",
        id: BATCH_TABLE_COLUMN_IDS.BALANCE,
        Cell: ({ cell }) => (
          <div className="text-right">
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {formattedDollarValue(cell.value)}
            </div>
          </div>
        ),
      },
    ];
  };

  let columns = useMemo(() => getColumns(), []);

  return (
    <>
      <ReactTable
        hidePagination={true}
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        hiddenColumns={hiddenColumns}
        styles="transaction-table"
        cards={props.cards}
        MobileCard={BatchesCard}
        MobileSkeleton={TransactionCardSkeleton}
        numTd={11}
      />
    </>
  );
}

export default BatchesTable;

BatchesTable.propTypes = {
  filters: PropTypes.object,
  cards: PropTypes.bool,
};

BatchesTable.defaultProps = {
  cards: false,
};
