import { Button, Col, Row } from "reactstrap";
import { pluralize } from "../../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import React, { Fragment, useState } from "react";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { reviewCredit } from "../../../../../../api_client/mutations/transaction";
import useGQL from "../../../../../../api_client/UseGQL";
import { useHistory } from "react-router-dom";
import { PulseLoader } from "react-spinners";

export const calculateTotalAmount = (transactions) => {
  const amounts = transactions.map(({ amount }) => Number(amount));
  return amounts.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
};

export const ReviewBulkApproval = () => {
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const totalAmount = calculateTotalAmount(state.approvedDraftCredits || []);
  const numTransactions = state.approvedDraftCredits?.length || 0;
  const many = numTransactions > 1;

  async function submit() {
    const creditIds = state.approvedDraftCredits.map(
      (transaction) => transaction.identifier
    );
    setLoading(true);
    const input = {
      creditIds: creditIds,
    };
    let output = {
      failedCredits: {
        identifier: true,
        message: true,
      },
      validationErrors: {
        identifier: true,
        message: true,
      },
      totalAmountSent: true,
      approvedTransactions: {
        identifier: true,
      },
    };
    const response = await reviewCredit(input, output, gqlHooks);
    setLoading(false);
    if (response?.validationErrors?.length || response?.failedCredits?.length) {
      setState({
        ...state,
        creditErrors: [
          ...(response?.validationErrors || []),
          ...(response?.failedCredits || []),
        ],
        totalAmountSent: response?.totalAmountSent,
        approvedTransactions: response?.approvedTransactions,
      });
      history.push("/bulk-payment/credit/approve/failed-payments");
      return;
    }
    if (response) {
      history.push("/bulk-payment/credit/approve/complete");
    }
  }

  return (
    <Fragment>
      <h2 className="text-center mt-5">Review {pluralize("payment", many)}</h2>
      <div className="text-center">
        You&apos;re sending{" "}
        <b>
          {numTransactions} {pluralize("payment", many)}
        </b>{" "}
        totalling <b>{formattedDollarValue(totalAmount)}</b>.
      </div>

      <div className="new-payment-inner-content">
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3> {formattedDollarValue(totalAmount)}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Number of payments</span>
              <h5>{state.approvedDraftCredits?.length || 0}</h5>
            </Col>
          </Row>
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          type="button"
          className="btn-success mt-4"
        >
          {loading ? (
            <PulseLoader color="white" size={10} />
          ) : (
            `Send ${pluralize("payment", many)}`
          )}
        </Button>
      </div>
    </Fragment>
  );
};
