export const OVERLAY_TYPES = {
  PAYMENT: "payment",
  RECURRING: "recurring",
  CONTACT: "contact",
  BILL: "bill",
  MONTHLY_STATEMENT: "monthly-statement",
  BANK: "bank",
  USER: "user",
  NOTIFICATIONS: "notifications",
  INFORMATION: "information",
};
