import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { formattedDollarValue } from "modules/Formatters";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import BankAccountSelection from "../../../../../components/dropdowns/BankAccountSelection";
import AddIcon from "../../../../../assets/icons/AddIcon";
import AddBankAccountModal from "../../../../../components/modals/AddBankAccountModal";
import CreditWithdrawalNewAccountButton from "components/buttons/CreditWithdrawalNewAccountButton";

function CreditAccountStep() {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [account, setAccount] = useState();
  const [newAccountModal, setNewAccountModal] = useState(false);

  useEffect(() => {
    if (state.fromBankAccount) {
      setAccount(state.fromBankAccount);
    }
  }, []);

  return (
    <Fragment>
      <AddBankAccountModal
        toggle={() => setNewAccountModal(!newAccountModal)}
        isOpen={newAccountModal}
        title={"Add bank account"}
      />
      <h2 className="text-center">Select an account to pay from</h2>
      <span className="text-center mb-2">
        You&apos;re paying <b>{formattedDollarValue(state.amount)}</b> to{" "}
        <b>
          {state.contactAccount.name
            ? state.contactAccount.name
            : state.contactAccount.email}
        </b>
        .
      </span>
      <div className="new-payment-inner-content">
        <Label>Withdrawal account</Label>
        <Row className="new-payment-account-wrapper">
          <CreditWithdrawalNewAccountButton setModal={setNewAccountModal} />
          <Col>
            <FormGroup>
              <BankAccountSelection
                bankAccount={account}
                setBankAccount={setAccount}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          onClick={() => {
            setState({ ...state, fromBankAccount: account });
            history.push("deposit-account", {
              ...history.location.state,
              currStep: 4,
            });
          }}
          disabled={
            !account || account.value === "" || !account.variableDebitPad
          }
          block
          color="primary"
          type="button"
        >
          Proceed
        </Button>
      </div>
    </Fragment>
  );
}

export default CreditAccountStep;

CreditAccountStep.propTypes = {};
