import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { convertToDate, CREDIT, DEBIT } from "../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { Button } from "reactstrap";
import ReactTable from "../../../../../components/tables/ReactTable";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import PopOutOverlay from "../../../../../components/overlays/PopOutOverlay";
import SupportForm from "../../../../../components/forms/SupportForm";

const ContactSupportModal = () => {
  const history = useHistory();
  const search = QueryString.parse(location.search);
  return (
    <PopOutOverlay
      fullscreen={false}
      isOpen={search.ticket}
      toggle={() => {
        history.goBack();
      }}
    >
      <SupportForm
        isOpen={search.ticket}
        toggle={() => history.push({ search: "" })}
      />
    </PopOutOverlay>
  );
};

export const FailedPaymentsTable = ({
  failedPaymentsData,
  numSuccessTransactions,
  numFailedTransactions,
  totalAmount,
  onBack,
  onBackText,
  showDateCreate = true,
}) => {
  const history = useHistory();
  /* eslint react/prop-types: 0 */
  let columns = useMemo(
    () => [
      {
        id: "statusIcon",
        Header: "",
        Cell: ({ cell }) => {
          if (cell.row.original.success) {
            return (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className={"text-success"}
              />
            );
          } else {
            return (
              <FontAwesomeIcon icon={faCircleXmark} className={"text-danger"} />
            );
          }
        },
      },
      {
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value.name ? cell.value.name : ""}
            </div>
            <div className="text-muted text-sm mb-1">
              {cell.value ? cell.value.email : "Pending Payment Acceptance"}
            </div>
          </>
        ),
      },
      {
        Header: "Statement",
        accessor: "statement",
      },
      ...(showDateCreate
        ? [
            {
              Header: "Date Created",
              accessor: "createdAt",
              // eslint-disable-next-line react/prop-types,react/display-name
              Cell: ({ cell }) => (
                <>
                  <div className="text-muted">{convertToDate(cell.value)}</div>
                </>
              ),
            },
          ]
        : []),
      {
        Header: "Amount",
        accessor: "amount",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div>
            <div>
              {cell.row.original.direction === DEBIT ? (
                <div>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(cell.row.original.amount)}
                  </div>
                  <div className="text-muted text-sm">Receivable</div>
                </div>
              ) : cell.row.original.direction === CREDIT ? (
                <div>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    ({formattedDollarValue(cell.row.original.amount)})
                  </div>
                  <div className="text-muted text-sm">Payable</div>
                </div>
              ) : (
                <div>
                  <div
                    className="font-weight-bold mt-1"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(cell.row.original.amount)}
                  </div>
                  <div className="text-muted text-sm">Internal</div>
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        Header: "Status",
        Cell: ({ cell }) => {
          if (cell.row.original.success) {
            return (
              <div className={"has-error text-right text-success"}>
                {cell.row.original.status}
              </div>
            );
          } else {
            return (
              <div className={"has-error text-right text-danger"}>
                {cell.row.original.status}
              </div>
            );
          }
        },
      },
    ],
    []
  );

  return (
    <div className="content m-5">
      <ContactSupportModal />
      <div className="">
        <h2 className="mb-3 font-weight-600 page-title">
          Some payments failed to send
        </h2>
        <h5>
          {numSuccessTransactions} payment(s) sent for a total of{" "}
          {formattedDollarValue(totalAmount)} and {numFailedTransactions}{" "}
          payment(s) failed to send.
        </h5>
        <div className={"d-flex flex-row justify-content-end mb-3"}>
          <Button
            onClick={() => history.push({ search: "ticket=true" })}
            type="button"
            className="btn-simple btn-primary mr-2"
          >
            Get help
          </Button>
          <Button type="button" className="btn-primary" onClick={onBack}>
            {onBackText}
          </Button>
        </div>
      </div>
      <ReactTable
        hidePagination={true}
        data={failedPaymentsData}
        columns={columns}
        styles="transaction-table"
        numTd={7}
      />
    </div>
  );
};
