import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { enumValue, statuses } from "../../modules/Helpers";
import TagsSelect from "../custom_select/TagsSelect";
import DatePickerModal from "./DatePickerModal";
import { dateToIsoString, endOfDay } from "../../modules/Helpers";

function TransactionFilters(props) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [estDepositDateRange, setEstDepositDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [status, setStatus] = useState();
  const [tags, setTags] = useState();
  const [option, setOption] = useState();
  const options = [
    { value: 1, label: "EFT" },
    { value: 2, label: "Credit" },
  ];
  useEffect(() => {
    let states = [];
    status?.map((val) =>
      val.value.map((state) => {
        states.push(enumValue(state));
      })
    );
    props.setFilters({
      ...props.filters,
      dateGte:
        dateRange["startDate"] == null
          ? undefined
          : dateToIsoString(dateRange["startDate"]),
      dateLte:
        dateRange["endDate"] == null
          ? undefined
          : dateToIsoString(endOfDay(dateRange["endDate"])),
      estDepositDateGte:
        estDepositDateRange["startDate"] == null
          ? undefined
          : dateToIsoString(estDepositDateRange["startDate"]),
      estDepositDateLte:
        estDepositDateRange["endDate"] == null
          ? undefined
          : dateToIsoString(endOfDay(estDepositDateRange["endDate"])),
      states: states.length > 0 ? states : undefined,
      contactTag: tags?.label || undefined,
      transactionType: option?.label,
    });
  }, [dateRange, estDepositDateRange, status, tags, option]);

  return (
    <Fragment>
      <Row>
        <Col md={3}>
          <Label>Date Created</Label>
          <FormGroup>
            <DatePickerModal
              onChange={(value) => setDateRange(value)}
              isClearable
              clear={() => setDateRange(undefined)}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <Label>Estimated Deposit</Label>
          <FormGroup>
            <DatePickerModal
              onChange={(value) => setEstDepositDateRange(value)}
              isClearable
              clear={() => setEstDepositDateRange(undefined)}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <Label>Status</Label>
          <FormGroup>
            <Select
              isMulti
              className="react-select default"
              classNamePrefix="react-select"
              onChange={(value) => setStatus(value)}
              value={status}
              options={statuses}
              isClearable
              clear={() => setStatus(undefined)}
            />
          </FormGroup>
        </Col>
        {props.customer ? (
          ""
        ) : (
          <Col md={3}>
            <Label>Tags</Label>
            <FormGroup>
              <TagsSelect
                isMulti={false}
                activeTags={tags}
                onChangeCallback={(value) => setTags(value)}
                isClearable
                clear={() => setTags(undefined)}
              />
            </FormGroup>
          </Col>
        )}
        {props.filters.direction ? (
          <></>
        ) : (
          <Col md={3}>
            <Label>Type</Label>
            <Select
              isMulti={false}
              className="react-select default"
              classNamePrefix="react-select"
              onChange={(value) => setOption(value)}
              value={option}
              options={options}
              isClearable
              clear={() => setOption(undefined)}
            />{" "}
          </Col>
        )}
      </Row>
    </Fragment>
  );
}

export default TransactionFilters;

TransactionFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

TransactionFilters.defaultProps = {};
