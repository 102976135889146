import React from "react";
import { Button } from "reactstrap";
import { supportPhoneNumber } from "variables/CompanyInfo";
import { formattedPhoneNumber } from "modules/Formatters";

function HelpContactButton() {
  return (
    <Button
      type="button"
      href={
        "tel:" +
        supportPhoneNumber.countryCode +
        supportPhoneNumber.areaCode +
        supportPhoneNumber.number
      }
      className="btn btn-link font-weight-500 btn-primary mt-2"
      style={{ paddingTop: "13px" }}
    >
      {formattedPhoneNumber(
        supportPhoneNumber.countryCode,
        supportPhoneNumber.areaCode,
        supportPhoneNumber.number
      )}
    </Button>
  );
}

export default HelpContactButton;

HelpContactButton.propTypes = {};
