export const BULK_CREDIT_BASE_PATH = "/bulk-payment/credit/create/";

export const STEPS = {
  SELECT_ACCOUNT: "selectAccount",
  DETAILS: "details",
  REVIEW: "review",
  FAILED: "failed-payments",
  COMPLETE: "complete",
};

export const defaultFlow = [
  BULK_CREDIT_BASE_PATH + STEPS.SELECT_ACCOUNT,
  BULK_CREDIT_BASE_PATH + STEPS.DETAILS,
  BULK_CREDIT_BASE_PATH + STEPS.REVIEW,
  BULK_CREDIT_BASE_PATH + STEPS.COMPLETE,
];

export const wireToEFTModeFlow = [
  BULK_CREDIT_BASE_PATH + STEPS.DETAILS,
  BULK_CREDIT_BASE_PATH + STEPS.REVIEW,
  BULK_CREDIT_BASE_PATH + STEPS.COMPLETE,
];

export const getInitialStep = (flow) => flow[0];

export const getStepNumber = (stepPath, flow) => {
  if (stepPath.includes(STEPS.FAILED)) {
    return flow.length;
  }

  const index = flow.indexOf(stepPath);
  return index === -1 ? null : index + 1;
};

export const getTotalSteps = (flow) => {
  return flow.length;
};
