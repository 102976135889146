import React, { Fragment, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import { createDebit } from "../../../../../api_client/mutations/transaction";
import { BUSINESS, enumValue } from "../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import useGQL from "../../../../../api_client/UseGQL";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { isMoment } from "moment";
import { createRecurringPlan } from "../../../../../api_client/mutations/recurringPlans";
import dangerAlert from "../../../../../components/sweet_alert/DangerAlert";
import BankDetail from "../../../../../components/generic_details/BankDetail";
import { useAppValue } from "../../../../../contexts/Context";
import NotesToContact from "components/text/NoteToContact";

function DebitReviewStep() {
  const gqlHooks = useGQL();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const [context] = useAppValue();

  const notesAlias = context.user.company.settings.notesAlias;

  async function submitOneTime() {
    setLoading(true);
    let tags = state.contactAccount?.tags.split(",");
    let output = {
      transaction: {
        identifier: true,
        link: true,
        amount: true,
        contact: {
          name: true,
          email: true,
        },
      },
    };
    const response = await createDebit(
      {
        amount: state.amount,
        toBankAccountId: state.toBankAccount.value,
        statement: state.statement,
        note: state.note,
        contactEmail: state.contactAccount.email,
        contactName: state.contactAccount.name || "Name",
        contactTags: tags,
        padType: enumValue(BUSINESS),
        sendEmail: state.deliveryMethod !== "Link",
      },
      output,
      gqlHooks
    );
    if (response) {
      if (response.transaction) {
        setState({ ...state, ...response.transaction });
        history.push("success", {
          ...location.state,
          currStep: 7,
        });
      } else {
        setLoading(false);
      }
    }
  }

  async function submitRecurring() {
    setLoading(true);
    let input;
    if (state) {
      input = {
        amount: state.amount,
        toBankAccountId: state.toBankAccount.value,
        statement: state.statement,
        note: state.note,
        startEpoch: state.startDate.unix(),
        endEpoch: state.endDate ? state.endDate.unix() : undefined,
        padType: enumValue(BUSINESS),
        frequency: enumValue(state.frequency.value),
        contactEmail: state.contactAccount.email,
        contactName: state.contactAccount.name || "Name",
        contactTags: state.contactAccount.tags.split(","),
      };
    } else {
      input = {
        amount: state.amount,
        toBankAccountId: state.toBankAccount.value,
        statement: state.statement,
        note: state.note,
        startEpoch: state.startDate.unix(),
        endEpoch: state.endDate ? state.endDate.unix() : undefined,
        padType: enumValue(BUSINESS),
        frequency: enumValue(state.frequency.value),
        contactEmail: state.contactAccount.email,
      };
    }

    let output = {
      recurringPlan: {
        identifier: true,
      },
    };
    const response = await createRecurringPlan(input, output, gqlHooks);
    if (response) {
      gqlHooks.context.setAlert(null);
      if (response.recurringPlan.identifier) {
        setState({ ...state, ...response.transaction });
        history.push("success", {
          ...location.state,
          currStep: 7,
        });
      } else {
        dangerAlert(
          () => gqlHooks.context.setAlert(null),
          gqlHooks.context.setAlert,
          "Please contact support",
          "Failed to send recurring request"
        );
      }
    }
  }

  return (
    <Fragment>
      <h2 className="text-center">Review payment request</h2>
      <div className="new-payment-inner-content">
        <h5 className="mb-2">Request from</h5>
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3>{formattedDollarValue(state.amount)}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Contact</span>
              <h5>
                {state.contactAccount.name
                  ? state.contactAccount.name
                  : state.contactAccount.email}
              </h5>
            </Col>
            <Col>
              <span>Delivery method</span>
              <h5>
                {state.deliveryMethod === "Email" ||
                (typeof state.frequency === "object" &&
                  state.frequency !== null)
                  ? state.contactAccount.email
                  : "Link"}
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Payment description</span>
              <h5>{state.statement}</h5>
            </Col>
          </Row>
          <hr />

          {typeof state.frequency === "object" ? (
            <Fragment>
              <Row>
                <Col>
                  <span>Frequency</span>
                  <h5>{state.frequency.value}</h5>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <span>Start date</span>
                  <h5>
                    {isMoment(state.startDate)
                      ? state.startDate.format("MMM DD, YYYY")
                      : ""}
                  </h5>
                </Col>
                <Col>
                  <span>End date</span>
                  <h5>
                    {isMoment(state.endDate)
                      ? state.endDate.format("MMM DD, YYYY")
                      : "Continue until cancelled"}
                  </h5>
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Row>
              <Col>
                <span>Frequency</span>
                <h5>{state.frequency}</h5>
              </Col>
            </Row>
          )}

          {state.note && (
            <Fragment>
              <hr />
              <Row>
                <Col>
                  <span>
                    <NotesToContact alias={notesAlias} />
                  </span>
                  <h5>{state.note}</h5>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
        <h5 className="mb-2 mt-4">Pay to</h5>
        <div className="review-payment">
          <BankDetail
            description="Your bank account"
            institution={state.toBankAccount.institution}
            label={state.toBankAccount.label}
          />
        </div>
        <Button
          disabled={loading}
          onClick={
            typeof state.frequency === "object"
              ? submitRecurring
              : submitOneTime
          }
          block
          className="mt-4 btn-primary"
        >
          {state.deliveryMethod === "Email" ||
          (typeof state.frequency === "object" && state.frequency !== null)
            ? "Send request"
            : "Create request link"}
        </Button>
      </div>
    </Fragment>
  );
}

export default DebitReviewStep;
