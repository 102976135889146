/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { formatDate, FREQUENCY_OPTIONS } from "../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";
import { useToasts } from "react-toast-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import { recurringStatus } from "../../../../components/badge/StatusBadgeRecurring";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import useGQL from "../../../../api_client/UseGQL";
import { getRecurringPlan } from "../../../../api_client/queries/recurringPlans";
import { cancelRecurringPlan } from "../../../../api_client/mutations/recurringPlans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import RecurringPlanInfoCard from "../../../../components/cards/RecurringPlanInfoCard";
import RecurringPlanPaidFromCard from "../../../../components/cards/RecurringPlanPaidFromCard";
import RecurringPlanPaidToCard from "../../../../components/cards/RecurringPlanPaidToCard";
import TransactionTable from "../../../../components/tables/TransactionTable";
import RecurringPlanActionsMobileDropdown from "../../../../components/dropdowns/RecurringPlanActionsMobileDropdown";
import RecurringPlanActionsDesktop from "./RecurringPlanActionsDesktop";

function RecurringPlanDetails(props) {
  const [recurringPlan, setRecurringPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);
      const identifier = props.id;

      let input = {
        recurringPlanId: identifier,
      };

      let output = {
        createdAt: true,
        updatedAt: true,
        identifier: true,
        active: true,
        enabled: true,
        start: true,
        end: true,
        nextRun: true,
        amount: true,
        toBankAccount: {
          identifier: true,
          title: true,
          institution: true,
          accountNumber: true,
        },
        statement: true,
        frequency: true,
        description: true,
        issuedBy: {
          email: true,
          identifier: true,
        },
        company: {
          name: true,
        },
        padType: true,
        contact: {
          email: true,
          name: true,
          identifier: true,
        },
        fromBankAccount: {
          createdAt: true,
          identifier: true,
          title: true,
          institution: true,
          accountNumber: true,
        },
        transactions: {
          identifier: true,
        },
        customerCreditCard: {
          brand: true,
          last4: true,
          createdAt: true,
        },
      };

      getRecurringPlan(input, output, gqlHooks).then((response) => {
        if (response && response.data.identifier) {
          setRecurringPlan(response.data);
          setFilters({ recurringPlanId: response.data.identifier });
          setLoading(false);
          props.setBackdropClick(false);
        } else {
          history.push({ search: "" });
          addToast("Could not load recurring plan", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      });
    } else {
      history.push("/admin/plans");
    }
  }, [recurringPlan.active]);

  if (loading) {
    return (
      <div className="content">
        <CustomLoader size={30} general={true} />
      </div>
    );
  }

  const hideAlert = () => {
    setAlertState(null);
  };

  const cancelPlan = () => {
    setAlertState(
      <SweetAlert
        warning
        title="Are you sure you want to cancel this recurring plan?"
        onConfirm={confirmCancelPlan}
        onCancel={() => hideAlert()}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Yes"
        cancelBtnText="No"
      />
    );
  };

  const confirmCancelPlan = async () => {
    setLoading(true);
    props.setBackdropClick(true);
    let output = {
      ok: true,
    };
    cancelRecurringPlan(
      { recurringPlanId: recurringPlan.identifier },
      output,
      gqlHooks
    ).then((response) => {
      if (response) {
        if (response.ok) {
          addToast("Recurring plan cancelled", {
            appearance: "success",
            autoDismiss: true,
          });
          history.push("/admin/plans?update=true");
        } else {
          addToast("Could not cancel recurring plan", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    });
    setLoading(false);
    props.setBackdropClick(false);
    setAlertState(null);
  };
  const copyIdToClipboard = () => {
    addToast("Identifier copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const copyToClipboard = () => {
    addToast(
      "Copied to clipboard, include in an invoice or email you send to your customer",
      { appearance: "success", autoDismiss: true }
    );
  };

  function ticket() {
    history.push({
      search: `ticket=true&recurringPlanId=${recurringPlan.identifier}`,
    });
  }

  return (
    <>
      {alertState}
      <div className="content">
        <Row>
          <Col>
            <p className="text-muted mb-0 mt-2">Recurring receivable</p>
          </Col>
          <Col>
            <Button
              className="btn-link details-close-btn"
              disabled={loading}
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <div className="details-desktop-actions">
              <RecurringPlanActionsDesktop
                recurringPlan={recurringPlan}
                copyPlanLink={copyToClipboard}
                cancelPlan={cancelPlan}
                ticket={ticket}
              />
            </div>
            <div className="details-mobile-actions">
              <RecurringPlanActionsMobileDropdown
                recurringPlan={recurringPlan}
                copyPlanLink={copyToClipboard}
                cancelPlan={cancelPlan}
                ticket={ticket}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex">
              <h3 className="font-weight-600 mb-2">
                {formattedDollarValue(recurringPlan.amount)}{" "}
                <span className="text-muted font-weight-400 text-sm">
                  /{" "}
                  {FREQUENCY_OPTIONS.find((frequency) => {
                    return frequency.value === recurringPlan.frequency;
                  })
                    .label.slice(0, -2)
                    .toLowerCase()}
                </span>
              </h3>
              <div className="ml-2">{recurringStatus(recurringPlan)}</div>
            </div>
          </Col>
        </Row>
        {recurringPlan.active && (
          <Row>
            <Col>
              <h5 className="text-muted mb-0">Next payment</h5>
              <p className="font-weight-500 text-success mb-0">
                {" "}
                {formatDate(recurringPlan.nextRun)}
              </p>
            </Col>
          </Row>
        )}
        <RecurringPlanInfoCard
          recurringPlan={recurringPlan}
          copyIdToClipboard={copyIdToClipboard}
        />
        <RecurringPlanPaidFromCard recurringPlan={recurringPlan} />
        <RecurringPlanPaidToCard recurringPlan={recurringPlan} />
        {recurringPlan.transactions.length !== 0 && (
          <>
            <Row>
              <Col>
                <h4 className="mt-2 mb-2">Payments</h4>
              </Col>
            </Row>
            <TransactionTable cards={true} {...props} filters={filters} />
          </>
        )}
      </div>
    </>
  );
}

export default RecurringPlanDetails;
RecurringPlanDetails.propTypes = {
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  setBackdropClick: PropTypes.func,
};
