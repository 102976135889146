import { Route, Switch, useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { useEffect, useState } from "react";
import { BulkCreditDetails } from "./BulkCreditDetails";
import { BulkCreditSelectFromAccount } from "./BulkCreditSelectFromAccount";
import { BulkCreditReview } from "./BulkCreditReview";
import { BulkCreditComplete } from "./BulkCreditComplete";
import { BulkCreditFailedPayments } from "./BulkCreditFailedPayments";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";
import { BulkCreditDetailsWireToEFTModeMode } from "./wire_to_eft_mode/BulkCreditDetailsWireToEFTMode";
import {
  wireToEFTModeFlow,
  defaultFlow,
  getInitialStep,
  getTotalSteps,
  STEPS,
  BULK_CREDIT_BASE_PATH,
} from "./BulkCreditFlows";
import { BulkCreditReviewWireToEFTMode } from "./wire_to_eft_mode/BulkCreditReviewWireToEFTMode";
import { useAppValue } from "contexts/Context";

export const BulkCredit = () => {
  const [context] = useAppValue();
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [loading, setLoading] = useState(true);
  const isWireToEFTMode = useIsWireToEFTMode();
  const flow = isWireToEFTMode ? wireToEFTModeFlow : defaultFlow;

  useEffect(() => {
    if (!state) {
      setState({
        flow: flow,
        requiresApproval: isWireToEFTMode || !context.user?.isOwnerOrAdmin,
      });

      history.push(getInitialStep(flow), {
        steps: getTotalSteps(flow) + 1,
        currStep: 1,
      });
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  } else {
    return (
      <>
        <Switch>
          <Route
            path={BULK_CREDIT_BASE_PATH + STEPS.SELECT_ACCOUNT}
            render={() => <BulkCreditSelectFromAccount />}
          />
          <Route
            path={BULK_CREDIT_BASE_PATH + STEPS.DETAILS}
            render={() =>
              isWireToEFTMode ? (
                <BulkCreditDetailsWireToEFTModeMode />
              ) : (
                <BulkCreditDetails />
              )
            }
          />
          <Route
            path={BULK_CREDIT_BASE_PATH + STEPS.REVIEW}
            render={() =>
              isWireToEFTMode ? (
                <BulkCreditReviewWireToEFTMode />
              ) : (
                <BulkCreditReview />
              )
            }
          />
          <Route
            path={BULK_CREDIT_BASE_PATH + STEPS.FAILED}
            render={() => <BulkCreditFailedPayments />}
          />
          <Route
            path={BULK_CREDIT_BASE_PATH + STEPS.COMPLETE}
            render={() => (
              <BulkCreditComplete
                disableAdditionalPaymentsDropdown={isWireToEFTMode}
              />
            )}
          />
        </Switch>
      </>
    );
  }
};
