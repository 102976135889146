import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row } from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { appName } from "../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { recurringStatus } from "../../../components/badge/StatusBadgeRecurring";
import RecurringPaymentInfo from "./RecurringPaymentInfo";
import { NavLink, useLocation } from "react-router-dom";
import RecurringHistory from "./RecurringHistory";
import useGQL from "../../../api_client/UseGQL";
import { cancelRecurringPlan } from "../../../api_client/mutations/recurringPlans";
import SweetAlert from "react-bootstrap-sweetalert";
import { CustomLoader } from "../../../components/loaders/CustomLoader";
import { trackEvent } from "../../../modules/analytics";
import useExitPrompt from "../../../hooks/useExitPrompt";

function RecurringTrackStep() {
  const [state] = useSimpleContext();
  const [active, setActive] = useState("#details");
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState(null);
  const { addToast } = useToasts();
  const gqlHooks = useGQL();
  const identifier = state.recurringPlan.identifier;
  const location = useLocation();
  const [, setShowExitPrompt] = useExitPrompt(true);

  useEffect(() => {
    setShowExitPrompt(false);
    trackEvent(
      "recurringTrack",
      {
        recurringId: state.recurringPlan.identifier,
        companyId: state.recurringPlan.company.identifier,
        contactId: state.recurringPlan.contact.identifier,
      },
      gqlHooks
    );
  }, []);

  useEffect(() => {
    if (location.state) {
      setActive(location.state.active);
    }
  }, []);

  const activeRoute = (name) => {
    return active === name ? "active" : "";
  };

  const cancelPlan = () => {
    setLoading(true);
    let output = {
      ok: true,
    };
    cancelRecurringPlan({ recurringPlanId: identifier }, output, gqlHooks).then(
      (response) => {
        if (response?.ok) {
          addToast("Recurring plan cancelled", {
            appearance: "success",
            autoDismiss: true,
          });
          state.recurringPlan.active = false;
        } else {
          addToast("Could not cancel recurring plan", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    );
    setLoading(false);
    setAlertState(null);
  };

  const confirmCancelPlan = () => {
    setAlertState(
      <SweetAlert
        warning
        title="Are you sure you want to cancel this recurring plan?"
        onConfirm={cancelPlan}
        onCancel={() => hideAlert()}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Yes"
        cancelBtnText="No"
      />
    );
  };

  const hideAlert = () => {
    setAlertState(null);
  };

  const onCopyId = () => {
    addToast("Link copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  if (loading) {
    return (
      <div className="content">
        <CustomLoader size={30} general={true} />
      </div>
    );
  }

  return (
    <Fragment>
      {alertState}
      {/*Title used for PDF receipt name*/}
      <title>
        {appName}_Recurring_Payment_{state.recurringPlan.identifier}
      </title>
      <div className="payment-details-header tracking-gradient">
        <div
          style={{
            backgroundImage: `url(${state.recurringPlan.company?.displayImage})`,
          }}
          className={`${
            !state.recurringPlan.company?.displayImage && "d-none"
          } payment-details-header-logo`}
        />
        <h4 className="ml-3 mb-0 font-weight-600">
          {state.recurringPlan.company.fullName ||
            state.recurringPlan.company.name}
        </h4>
        <h4 className="ml-auto font-weight-400 mb-0 desktop">
          Recurring Payment Request
        </h4>
      </div>
      <div className="d-flex flex-row justify-content-between flex-grow-1 bottom-border">
        <Nav className="table-headers d-flex hide-on-print pt-1">
          <li className={activeRoute("#details") + " nav-link"}>
            <NavLink
              to=""
              onClick={(e) => {
                e.preventDefault();
                setActive("#details");
              }}
            >
              Details
            </NavLink>
          </li>
          <li className={activeRoute("#payments") + " nav-link"}>
            <NavLink
              to=""
              onClick={(e) => {
                e.preventDefault();
                setActive("#payments");
              }}
            >
              Payments
            </NavLink>
          </li>
        </Nav>
      </div>
      {active === "#details" && (
        <>
          <Container>
            <div className="checkout-spacer">
              <Row>
                <Col className="mt-3 mb-4">
                  <div className="d-flex align-items-center">
                    <h3 className="font-weight-600 mb-0">
                      {formattedDollarValue(state.recurringPlan.amount)}
                      <span className="text-muted font-weight-400 text-sm">
                        /
                        {state.recurringPlan.frequency
                          .slice(0, -2)
                          .toLowerCase()}
                      </span>
                    </h3>
                    <div className="ml-2 hide-on-print">
                      {recurringStatus(state.recurringPlan)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <RecurringPaymentInfo />
          </Container>
        </>
      )}
      {active === "#payments" && (
        <RecurringHistory recurringPlan={state.recurringPlan} />
      )}
      <Container className="payment-secondary-action bg-white hide-on-print">
        <div className="checkout-spacer">
          <span className="text-muted">Print or share this page:</span>
          <Row>
            <Col
              md={state.recurringPlan.active ? "4" : "6"}
              className="order-md-3"
            >
              <Button
                className="btn-lg btn-primary mb-0 mt-2"
                onClick={() => window.print()}
                block
              >
                Download receipt
              </Button>
            </Col>
            <Col
              md={state.recurringPlan.active ? "4" : "6"}
              className="order-md-2"
            >
              <Button className="btn-lg btn-primary mb-0 mt-2" block>
                <CopyToClipboard
                  onCopy={() => onCopyId()}
                  text={window.location.href}
                >
                  <span id={"identifier"} className="cursor-pointer">
                    Share this page
                  </span>
                </CopyToClipboard>
              </Button>
            </Col>
            {state.recurringPlan.active && (
              <Col
                md={state.recurringPlan.active ? "4" : "6"}
                className="order-md-1"
              >
                <Button
                  className="btn-lg btn-warning btn-link mb-0 mt-2"
                  onClick={confirmCancelPlan}
                  block
                >
                  Cancel plan
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default RecurringTrackStep;

RecurringTrackStep.propTypes = {};
