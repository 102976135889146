import React from "react";
import PropTypes from "prop-types";

function CardInfoRow({ label, value }) {
  return (
    <div className="t-card-row justify-content-between">
      <div className="flex-column text-muted">
        <div>{label}</div>
      </div>
      <div className="flex-column font-weight-bold text-right">
        <div>{value}</div>
      </div>
    </div>
  );
}

export default CardInfoRow;

CardInfoRow.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  className: PropTypes.string,
};
