import useForm from "../../../../../hooks/UseForms";
import { AddBankAccountValidation } from "../../../../../modules/form_validation/AddBankAccountValidation";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Form } from "reactstrap";
import NewManualBankForm from "./NewManualBankForm";
import confirmRoutingAlert from "../../../../../components/modals/ConfirmRouting";
import loadingAlert from "../../../../../components/sweet_alert/LoadingAlert";
import useGQL from "../../../../../api_client/UseGQL";
import {
  assignBankAccountToCompany,
  createBankAccountManual,
} from "../../../../../api_client/mutations/bankAccount";
import { allBankAccounts } from "../../../../../api_client/queries/bankAccounts";
import Cheque from "../../../../../assets/img/Cheque.png";
import PropTypes from "prop-types";
import MicrodepositAlert from "../../../../../components/sweet_alert/MicrodepositAlert";
import { trackEvent } from "../../../../../modules/analytics";
import { useToasts } from "react-toast-notifications";
import VariableSporadicPADModal from "../../../../../components/modals/VariableSporadicPADModal";
import { formatPostalCode } from "modules/Formatters";
import { withApiValue } from "../../../../../modules/WithApiValue";

const config = {
  company: {
    kyc: {
      companyAttestation: true,
      submitted: true,
    },
    validated: true,
  },
  kyc: { submitted: true, validated: true },
};

function NewManualBankAccount(props) {
  const { values, setValues, handleChange, handleSubmit, errors, setErrors } =
    useForm(confirm, AddBankAccountValidation);
  const [confirmModal, setConfirmModal] = useState(null);
  const [hideAccountNumber, setHideAccountNumber] = useState(false);
  const gqlHooks = useGQL();
  const [bankAccountsCount, setBankAccountsCount] = useState(-1);
  const { addToast } = useToasts();
  const [variablePad, setVariablePad] = useState(false);
  const [bankAccount, setBankAccount] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (!props.company.kyc.submitted || !props.kyc.submitted) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, []);

  const refs = {
    institutionNumber: useRef(null),
    transitNumber: useRef(null),
    accountNumber: useRef(null),
    title: useRef(null),
    institution: useRef(null),
    holderName: useRef(null),
    holderEmail: useRef(null),
    holderAddress: useRef(null),
    holderAddressPostalCode: useRef(null),
    holderAddressCity: useRef(null),
  };

  let history = useHistory();

  useEffect(() => {
    allBankAccounts({}, { identifier: true }, gqlHooks).then((response) => {
      if (response) {
        setBankAccountsCount(response.count);
      }
    });
  }, []);

  async function submit() {
    loadingAlert(setConfirmModal, "Authorizing...");
    const data = { ...values, holderAddressCountry: "CA" };
    data.holderAddressPostalCode = formatPostalCode(
      data.holderAddressPostalCode
    );
    let output = {
      bankAccount: {
        identifier: true,
      },
    };
    const response = await createBankAccountManual(
      { bankAccount: data },
      output,
      gqlHooks
    );
    if (response.bankAccount) {
      let output = {
        bankAccount: {
          identifier: true,
          missingKyc: true,
          state: true,
          institutionNumber: true,
          transitNumber: true,
          accountNumber: true,
          institution: true,
          title: true,
          accountType: true,
          holderName: true,
          holderAddress: true,
          holderAddressPostalCode: true,
          holderAddressCity: true,
          holderAddressCountry: true,
          holderEmail: true,
        },
        message: true,
        company: {
          name: true,
        },
      };
      const response2 = await assignBankAccountToCompany(
        { bankAccountId: response.bankAccount.identifier },
        output,
        gqlHooks
      );
      if (response2 && response2.bankAccount) {
        setBankAccount(response2.bankAccount);
        setVariablePad(true);
      }
    }
    setHideAccountNumber(false);
    setConfirmModal(null);
  }

  function finish() {
    MicrodepositAlert(
      () => gqlHooks.context.setAlert(null),
      gqlHooks.context.setAlert
    );
    props.toggle();
    if (props.setBankAccount) {
      props.setBankAccount(bankAccount);
    } else if (bankAccountsCount === 0) {
      history.push("/admin/banks/add-billing", {
        account: bankAccount,
      });
    } else {
      history.push({ search: "update=true" });
    }
    addToast("Bank account added successfully", {
      appearance: "success",
      autoDismiss: true,
    });
    trackEvent("addBankComplete", {}, gqlHooks);
  }

  const ChangeConfirmModal = (modal = null, hide = false) => {
    setHideAccountNumber(hide);
    setConfirmModal(modal);
  };

  function confirm() {
    let errs = {};
    Object.keys(refs)
      .reverse()
      .forEach(function (key) {
        if (!values[key] || values[key] === "") {
          errs[key] = "This field is required";
          refs[key].current.focus();
        }
      });
    setErrors(errs);
    if (Object.entries(errs).length === 0) {
      confirmRoutingAlert(
        ChangeConfirmModal,
        submit,
        values.accountNumber,
        setErrors,
        refs.accountNumber
      );
    }
  }

  return (
    <Fragment>
      {confirmModal}
      {variablePad && (
        <VariableSporadicPADModal
          isOpen={variablePad}
          toggle={() => finish()}
          bankAccountIdentifier={bankAccount.identifier}
        />
      )}
      {isDisabled ? (
        <div className="pt-4">
          <Alert color="white">
            <h4 className="text-center">
              Please submit business information and verify your identity before
              adding a bank manually
            </h4>
          </Alert>
        </div>
      ) : (
        <div className="manual-bank-form-wrapper">
          <img src={Cheque} alt="Cheque image" className="manual-bank-cheque" />
          <Form>
            <NewManualBankForm
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              errors={errors}
              refs={refs}
              hideAccountNumber={hideAccountNumber}
            />
            <div className="manual-bank-form-button-wrapper">
              <Button block color="primary" type="button" onClick={confirm}>
                Add Account
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Fragment>
  );
}

export default withApiValue(NewManualBankAccount, config, () => null);

NewManualBankAccount.propTypes = {
  toggle: PropTypes.func,
  setBankAccount: PropTypes.func,
  company: PropTypes.object,
  profile: PropTypes.object,
  kyc: PropTypes.object,
  email: PropTypes.string,
};
