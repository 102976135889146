import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { useHistory } from "react-router";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 0,
    left: "auto",
    position: "fixed",
  },
  fab: {
    height: 60,
    width: 60,
    backgroundColor: "#33CC7A",
    "&:hover": {
      backgroundColor: "#33CC7A",
    },
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function SpeedDialTooltipOpen() {
  const history = useHistory();
  const isWireToEFTMode = useIsWireToEFTMode();
  const visibleActions = isWireToEFTMode
    ? ["Send"]
    : ["Transfer", "Send", "Receive"];

  function isVisible(actionName) {
    return visibleActions.includes(actionName);
  }

  const actions = [
    {
      icon: <AccountBalanceIcon />,
      name: "Transfer",
      onClick: () =>
        history.push("/new-payment/internal/details", {
          currStep: 1,
          steps: 4,
        }),
    },
    {
      icon: <ArrowForwardIcon />,
      name: "Send",
      onClick: () =>
        history.push("/new-payment/credit/details", {
          currStep: 1,
          steps: 6,
        }),
    },
    {
      icon: <ReceiptIcon />,
      name: "Receive",
      onClick: () =>
        history.push("/new-payment/debit/frequency", {
          currStep: 1,
          steps: 8,
        }),
    },
  ];
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Button onClick={handleVisibility} />
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        classes={{ fab: classes.fab }}
        hidden={hidden}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        color="primary"
        open={open}
      >
        {actions
          .filter((action) => isVisible(action.name))
          .map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={action.onClick}
            />
          ))}
      </SpeedDial>
    </div>
  );
}
