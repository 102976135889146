/* eslint-disable react/prop-types */
import RBC from "../assets/img/banks/rbc.svg";
import BMO from "../assets/img/banks/bmo.svg";
import ATB from "../assets/img/banks/atb.svg";
import NationalBank from "../assets/img/banks/bank_of_canada.svg";
import CIBC from "../assets/img/banks/cibc.svg";
import CoastCapital from "../assets/img/banks/coastal_capital.svg";
import Desjardin from "../assets/img/banks/desjardins.svg";
import EQ from "../assets/img/banks/eqbank.svg";
import HSBC from "../assets/img/banks/hsbc.svg";
import Laurentian from "../assets/img/banks/laurentian_bank_of_canada.svg";
import Meridian from "../assets/img/banks/meridian.svg";
import Scotiabank from "../assets/img/banks/scotiabank.svg";
import Simplii from "../assets/img/banks/simplii.svg";
import Tangerine from "../assets/img/banks/tangerine.svg";
import TD from "../assets/img/banks/td.svg";
import VanCity from "../assets/img/banks/vancity.svg";
import moment from "moment-timezone";
import momentBus from "moment-business-days";
import Flinks from "../assets/img/banks/flinks.png";
import Bank from "../assets/img/banks/bank.svg";
import Quickbooks from "../assets/img/accounting/Quickbooks-Logo.png";
import Xero from "../assets/img/accounting/Xero-Logo.png";
import Freshbooks from "../assets/img/accounting/FreshBooks-Logo.png";
import React, { Fragment } from "react";
import { components } from "react-select";
import ContactTagBadge from "../components/badge/ContactTagBadge";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import TransactionStates from "../variables/TransactionStates";
import IndicatorCircle from "assets/icons/IndicatorCircle";
import { formattedDollarValue } from "./Formatters";

export function contactTagMapping(tag, defaultTags) {
  return defaultTags[tag];
}

// custom options to show tags and maintain search functionality
export const Option = (props) => {
  let tags = props.data.tags.split(",");
  return (
    <Fragment>
      <components.Option {...props}>
        <div className="font-weight-600">{props.data.name}</div>
        <div>{props.data.email}</div>
        <div>{ContactTagBadge(tags, props.data.value)}</div>
      </components.Option>
    </Fragment>
  );
};

export const SingleValue = (props) => {
  let identifier = props.data.value;
  if (identifier) {
    identifier = identifier.replace(/-/g, "");
    identifier = identifier.replace(/[0-9]/g, "");
  } else {
    identifier = "placeholderTextForTag";
  }
  if (props.data.value !== "") {
    let tags = [];
    if (
      props.data.tags !== "" &&
      props.data.tags !== undefined &&
      props.data.tags !== null
    ) {
      tags = props.data.tags.split(",");
    }
    return (
      <Fragment>
        <components.SingleValue {...props}>
          <div id={identifier} className="font-weight-600">
            {props.data.name} - {props.data.email}
          </div>
          <div>{ContactTagBadge(tags, props.data.value, true)}</div>
        </components.SingleValue>
      </Fragment>
    );
  } else {
    return <components.SingleValue {...props} />;
  }
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

// DO NOT TOUCH EVER IT WORKS
export function customFilter(option, searchText) {
  searchText = escapeRegExp(searchText);

  let searchable = [option.data.name, option.data.email]
    .toString()
    .toLowerCase();
  let regexable = [option.data.name, option.data.email, option.data.tags]
    .toString()
    .toLowerCase();

  if (
    (searchable &&
      option.data.name &&
      option.data.name?.toLowerCase().includes(searchable)) ||
    option.data.email?.toLowerCase().includes(searchable)
  ) {
    return true;
  } else {
    let pattern = searchText
      .toLowerCase()
      .split(" ")
      .map((x) => {
        return `(?=.*${x})`;
      })
      .join("");
    let regex = new RegExp(`${pattern}`, "g");
    return regexable.match(regex);
  }
}

export const stateOutput = {
  kyc: {
    approved: true,
    submitted: true,
    validated: true,
    updateRequired: true,
    resubmitted: true,
  },
  email: true,
  company: {
    name: true,
    displayImage: true,
    billingAccount: {
      identifier: true,
    },
    validated: true,
    isActive: true,
    companyType: true,
    kyc: {
      approved: true,
      industry: true,
      purpose: true,
      companyAttestation: true,
      name: true,
      address: true,
      incorporationNumber: true,
      incorporationDocument: true,
      incorporationDocumentType: true,
      structure: true,
      // owners: true,
      ownersSubmitted: true,
      ownersList: true,
      refreshFields: true,
      submitted: true,
      validated: true,
      updateRequired: true,
      resubmitted: true,
    },
    settings: {
      securityQuestion: true,
      securityAnswer: true,
      useSecurityQuestion: true,
    },
    contactTagsMapping: true,
  },
  identifier: true,
  profile: {
    firstName: true,
    lastName: true,
    phone: true,
  },
  emails: {
    identifier: true,
    address: true,
    verified: true,
    canResendVerificationAt: true,
    isPrimary: true,
  },
};

export const getStatusColor = (status) => {
  switch (status) {
    // deprecated
    case 0:
      return "primary";
    case 1:
      return "success";
    case 2:
      return "danger";
    case 3:
      return "primary";
    case 4:
      return "primary";
    case 5:
      return "primary";
    case 6:
      return "warning";
    case 7:
      return "info";
    case 8:
      return "danger";
    case 9:
      return "primary";
    default:
      return "info";
  }
};

export const getProgressBarColor = (status) => {
  switch (status) {
    case 0: // Processing (deprecated)
      return "#8125D2";
    case 1: // Deposited
      return "#33cc7a";
    case 2: // Failed
      return "#ee6352";
    case 3: // Initiated
      return "#8125D2";
    case 4: // Withdrawn
      return "#8125D2";
    case 5: // In transit
      return "#8125D2";
    case 6: // On hold
      return "#f0b820";
    case 7: // Requested
      return "#344675";
    case 8: // Cancelled
      return "#ee6352";
    case 9: // Accepted
      return "#8125D2";
    default:
      return "#8125D2";
  }
};

export const getProgressBarBackgroundColor = (status) => {
  switch (status) {
    case 0: // Processing (deprecated)
      return "#cecdff";
    case 1: // Deposited
      return "#d4ede0";
    case 2: // Failed
      return "#fce0dc";
    case 3: // Initiated
      return "#cecdff";
    case 4: // Withdrawn
      return "#cecdff";
    case 5: // In transit
      return "#cecdff";
    case 6: // On hold
      return "#fff1b0";
    case 7: // Requested
      return "#d6dae3";
    case 8: // Cancelled
      return "#fce0dc";
    case 9: // Accepted
      return "#cecdff";
    default:
      return "#cecdff";
  }
};
export const getEmailStatus = (status, dir) => {
  // Sendgrid email events -  https://sendgrid.com/blog/the-nine-events-of-email/
  switch (status) {
    case "initalized": // API spelling error "initalized"
      return {
        value: "Email sent", // Displayed as "Email sent" to minimize user confusion)
        description:
          dir === DEBIT
            ? "Request email sent. Waiting for payment from your contact."
            : "Request email sent. Waiting for your contact to accept payment.",
      };
    case "processed":
      return {
        value: "Email sent", // Displayed as "Email sent" to minimize user confusion)
        description:
          dir === DEBIT
            ? "Request email sent. Waiting for payment from your contact."
            : "Request email sent. Waiting for your contact to accept payment.",
      };
    case "open":
      return {
        value: "Email opened",
        description:
          dir === DEBIT
            ? "Request email opened. Waiting for payment from your contact."
            : "Request email opened. Waiting for your contact to accept payment.",
      };
    case "click":
      return {
        value: "Email opened", // Displayed as "Email opened" to minimize user confusion)
        description:
          dir === DEBIT
            ? "Request email opened. Waiting for payment from your contact."
            : "Request email opened. Waiting for your contact to accept payment.",
      };
    case "dropped":
      return {
        value: "Email error", // Displayed as "Email error" to minimize user confusion)
        description:
          "Request email error. Please copy the payment link and re-send to your contact in a separate email.",
      };
    case "delivered":
      return {
        value: "Email delivered",
        description:
          dir === DEBIT
            ? "Request email delivered. Waiting for payment from your contact."
            : "Request email delivered. Waiting for your contact to accept payment.",
      };
    case "deferred":
      return {
        value: "Email pending", // Displayed as "Email pending" to minimize user confusion)
        description:
          "Request email pending. Please refresh the page or check back later for an update.",
      };
    case "bounce":
      return {
        value: "Email error", // Displayed as "Email error" to minimize user confusion)
        description:
          "Request email error. Please copy the payment link and re-send to your contact in a separate email.",
      };
    case "blocked":
      return {
        value: "Email blocked",
        description: `Request email blocked by your contact. Please ask your contact to add ${emailAccounts.NOTIFY} to their email contacts.`,
      };
    case "spamreport":
      return {
        value: "Marked as spam",
        description:
          "Request email was marked as spam. Please notify your contact to check their email spam folder.",
      };
    default:
      return null;
  }
};

export function downloadJsonToFile(data, fileName, fileType) {
  let fileContent = "";

  data.forEach(function (array) {
    let row = array.join(",");
    fileContent += row + "\r\n";
  });

  const blob = new Blob([fileContent], {
    type: "text/" + fileType.toLowerCase(),
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");

  link.href = url;
  link.download = fileName + "." + fileType.toLowerCase();
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
}

export const getStatus = (status, dir, failedDir) => {
  switch (status) {
    case TransactionStates.PROCESSING:
      return {
        value: "Processing",
        description: "Transaction is being processed",
      };
    case TransactionStates.COMPLETED:
      return {
        value: "Deposited",
        description:
          dir === DEBIT
            ? "Payment has been deposited into your account."
            : dir === CREDIT
            ? "Payment has been deposited into your contact's account."
            : dir === INTERNAL
            ? "Payment has been deposited into your destination account."
            : "Payment has been deposited into your destination account.",
      };
    case TransactionStates.FAILED:
      return {
        value: "Failed",
        description: {
          CREDIT: {
            PULL: "Payment has failed to withdraw from your account.",
            PUSH: "Payment has failed to deposit into your contact's account.",
          },
          DEBIT: {
            PULL: "Payment has failed to withdraw from your contact's account.",
            PUSH: "Payment has failed to deposit into your account.",
          },
          INTERNAL: {
            PULL: "Payment has failed to withdraw from your source account.",
            PUSH: "Payment has failed to deposit into your destination account.",
          },
        }[dir || CREDIT][failedDir || PULL],
      };
    case TransactionStates.INITIATED:
      return {
        value: "Initiated",
        description:
          dir === DEBIT
            ? "Payment has been initiated by the bank."
            : "Payment has been initiated by the bank.",
      };
    case TransactionStates.PULLED:
      return {
        value: "Withdrawn",
        description:
          dir === DEBIT
            ? "Payment has been withdrawn from your contact's account for processing."
            : dir === CREDIT
            ? "Payment has been withdrawn from your account for processing."
            : dir === INTERNAL
            ? "Payment has been withdrawn from your source account."
            : "Payment has been withdrawn from your source account.",
      };
    case TransactionStates.PUSHED:
      return {
        value: "In transit",
        description:
          dir === DEBIT
            ? "Payment is being sent to your account."
            : dir === CREDIT
            ? "Payment is being sent to your contact's account."
            : dir === INTERNAL
            ? "Payment is being sent to your destination account."
            : "Payment is being sent to your destination account.",
      };
    case TransactionStates.ON_HOLD:
      return {
        value: "On hold",
        description:
          "Payment is being held by the bank, support will contact you shortly.",
      };
    case TransactionStates.REQUESTED:
      return {
        value: "Email sent",
        description:
          dir === DEBIT
            ? "Request email sent. Waiting for payment from your contact."
            : "Request email sent. Waiting for your contact to accept payment.",
      };
    case TransactionStates.CANCELLED:
      return {
        value: "Cancelled",
        description: "Payment request has been cancelled.",
      };
    case TransactionStates.PENDING:
      return {
        value: "Accepted",
        description:
          dir === DEBIT
            ? "Your contact has accepted the payment request. Waiting for bank to begin processing."
            : dir === CREDIT
            ? "Payment has been accepted by your contact. Waiting for bank to begin processing."
            : dir === INTERNAL
            ? "Waiting for bank to initiate transfer of payment."
            : "Waiting for bank to initiate transfer of payment.",
      };
    case TransactionStates.RETURNED:
      return {
        value: "Returned",
        description:
          "Payment has been deposited back into the original account.",
      };
    case TransactionStates.RETURNING:
      return {
        value: "Returning",
        description: "Returning payment to the original account.",
      };
    case TransactionStates.REROUTED:
      return {
        value: "Rerouted",
        description: "Rerouting payment to updated destination account.",
      };
    case TransactionStates.RETRYING:
      return {
        value: "Retrying",
        description: "Retrying payment with updated source account.",
      };
    case TransactionStates.WAITING_APPROVAL:
      return {
        value: "Pending approval",
        description: "Waiting for additional approval.",
      };
    case TransactionStates.DRAFT:
      return {
        value: "Draft",
        description: "Waiting for payment order approval.",
      };
    case TransactionStates.EMAIL_ERROR:
      return {
        value: "Email error",
        description: "Email is invalid or could not be sent.",
      };
    case TransactionStates.UNAUTHORIZED:
      return {
        value: "Hold",
        description: "Pending account authorization.",
      };
    case TransactionStates.PAID:
      return {
        value: "Charged",
        description:
          "Your contact has accepted the payment request via Credit Card. Waiting for bank to begin processing.",
      };
    case TransactionStates.RESUMED:
      return {
        value: "Resumed",
        description: "Payment has been resumed.",
      };
    default:
      return { value: "Status", description: "(No description)" };
  }
};
export const getStatusPublic = (status, dir) => {
  switch (status) {
    case TransactionStates.PROCESSING:
      return {
        value: "Processing",
        description: "Transaction is being processed",
      };
    case TransactionStates.COMPLETED:
      return {
        value: "Deposited",
        description:
          dir === DEBIT
            ? "Payment has been deposited into the receiver's account."
            : "Payment has been deposited into your account.",
      };
    case TransactionStates.FAILED:
      return {
        value: "Failed",
        description:
          dir === DEBIT
            ? "Payment failed to be withdrawn from your account."
            : "Payment has failed to be withdrawn from your contact.",
      };
    case TransactionStates.INITIATED:
      return {
        value: "Initiated",
        description:
          dir === DEBIT
            ? "Payment has been initiated by the bank."
            : "Payment has been initiated by the bank.",
      };
    case TransactionStates.PULLED:
      return {
        value: "Withdrawn",
        description:
          dir === DEBIT
            ? "Payment has been withdrawn from your account for processing."
            : "Payment has been withdrawn from the source account for processing.",
      };
    case TransactionStates.PUSHED:
      return {
        value: "In transit",
        description:
          dir === DEBIT
            ? "Payment is being sent to the deposit account."
            : "Payment is being sent to your account.",
      };
    case TransactionStates.ON_HOLD:
      return {
        value: "On hold",
        description:
          "Payment is being held by the bank, support will contact you shortly.",
      };
    case TransactionStates.REQUESTED:
      return {
        value: "Unpaid",
        description:
          dir === DEBIT
            ? "Waiting for you to accept payment request."
            : "Waiting for your contact to accept payment.",
      };
    case TransactionStates.CANCELLED:
      return {
        value: "Cancelled",
        description: "Payment request has been cancelled.",
      };
    case TransactionStates.PENDING:
      return {
        value: "Accepted",
        description:
          dir === DEBIT
            ? "You accepted the payment request. Waiting for bank to begin processing."
            : "Payment has been accepted. Waiting for bank to begin processing.",
      };
    case TransactionStates.RETURNED:
      return {
        value: "Returned",
        description:
          "Payment has been deposited back into the original account.",
      };
    case TransactionStates.RETURNING:
      return {
        value: "Returning",
        description: "Returning payment to the original account.",
      };
    case TransactionStates.REROUTED:
      return {
        value: "Rerouted",
        description: "Rerouting payment to updated destination account.",
      };
    case TransactionStates.RETRYING:
      return {
        value: "Retrying",
        description: "Retrying payment with updated source account.",
      };
    case TransactionStates.WAITING_APPROVAL:
      return {
        value: "Pending approval",
        description: "Waiting for additional approval.",
      };
    case TransactionStates.PAID:
      return {
        value: "Charged",
        description:
          "You accepted the payment request via Credit Card. Waiting for bank to begin processing.",
      };
    case TransactionStates.RESUMED:
      return {
        value: "Resumed",
        description: "Payment has been resumed.",
      };
    default:
      return null;
  }
};
export const PASSWORD_LENGTH = 8,
  PHONE_ERROR =
    "Please use a 10 digit canadian number with no dashes or space eg: 2505551111",
  MAX_LENGTH_SHORT_NAME = 15,
  MAX_LENGTH_LONG_NAME = 30,
  MAX_LENGTH_FULL_NAME = 60,
  MAX_LENGTH_DESCRIPTION = 200,
  MAX_LENGTH_NOTE = 200,
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
  INDIVIDUAL = "INDIVIDUAL",
  ORGANIZATION = "ORGANIZATION",
  FREQUENCY_OPTIONS = [
    { value: WEEKLY, label: "Weekly" },
    { value: MONTHLY, label: "Monthly" },
    { value: QUARTERLY, label: "Quarterly" },
    { value: YEARLY, label: "Yearly" },
  ],
  PAD_TYPE_OPTIONS = [
    { value: BUSINESS, label: "Business" },
    { value: PERSONAL, label: "Personal" },
  ],
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  INTERNAL = "INTERNAL",
  PUSH = "PUSH",
  PULL = "PULL";
export function updateUser(
  context,
  count = null,
  notesAlias = undefined,
  notesRequired = null
) {
  let updatedUser = {
    ...context.user,
    company: {
      ...context.user.company,
      settings: {
        ...context.user.company.settings,
      },
    },
  };
  if (count) {
    updatedUser["company"]["settings"]["requiredPaymentApprovals"] = count;
  }
  if (notesAlias != undefined) {
    updatedUser["company"]["settings"]["notesAlias"] = notesAlias;
  }
  if (notesRequired !== null) {
    updatedUser["company"]["settings"]["notesRequired"] = notesRequired;
  }
  return updatedUser;
}
export const loadingMessages = [
  "",
  "The banks are shaking hands",
  "The connection is in progress",
  "Taking too long? Consider canceling, then enter your account details manually",
  "Connection Failed",
];

export const generalMessages = [
  "",
  "Loading...",
  "",
  "Taking too long? Try refreshing the page",
  "Connection Failed",
];

export const loadingMessagesNoBlank = [
  "",
  "The banks are shaking hands",
  "The connection is in progress",
  "Taking too long? Consider canceling, then enter your account details manually",
  "Connection Failed",
];

export const loadingURLS = [
  "https://tinyurl.com/4y4e95vd",
  "https://flinks.com/",
];

const AllowedChars = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "=",
  "_",
  "$",
  ".",
  "&",
  "*",
  ",",
  " ",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "Y",
  "X",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const getBankIcon = (bank) => {
  switch (bank) {
    case "RBC":
      return RBC;
    case "BMO":
      return BMO;
    case "ATB":
      return ATB;
    case "CIBC":
      return CIBC;
    case "CoastCapital":
      return CoastCapital;
    case "Desjardins":
      return Desjardin;
    case "Meridian":
      return Meridian;
    case "National":
      return NationalBank;
    case "Laurentienne":
      return Laurentian;
    case "Simplii":
      return Simplii;
    case "Scotia":
      return Scotiabank;
    case "Scotiabank":
      return Scotiabank;
    case "TD":
      return TD;
    case "Vancity":
      return VanCity;
    case "HSBC":
      return HSBC;
    case "EQBank":
      return EQ;
    case "Tangerine":
      return Tangerine;
    case "FlinksCapital":
      return Flinks;
    default:
      return Bank;
  }
};

export const industries = [
  {
    label: "Accommodation and Food Services",
    value: "Accommodation and Food Services",
  },
  {
    label:
      "Administrative and Support, Waste Management and Remediation Services",
    value:
      "Administrative and Support, Waste Management and Remediation Services",
  },
  {
    label: "Agriculture, Forestry, Fishing and Hunting",
    value: "Agriculture, Forestry, Fishing and Hunting",
  },
  {
    label: "Arts, Entertainment and Recreation",
    value: "Arts, Entertainment and Recreation",
  },
  {
    label: "Construction",
    value: "Construction",
  },
  {
    label: "Educational Services",
    value: "Educational Services",
  },
  {
    label: "Finance and Insurance",
    value: "Finance and Insurance",
  },
  {
    label: "Health Care and Social Assistance",
    value: "Health Care and Social Assistance",
  },
  {
    label: "Information and Cultural Industries",
    value: "Information and Cultural Industries",
  },
  {
    label: "Management of Companies and Enterprises",
    value: "Management of Companies and Enterprises",
  },
  {
    label: "Manufacturing",
    value: "Manufacturing",
  },
  {
    label: "Mining, Quarrying, and Oil and Gas Extraction",
    value: "Mining, Quarrying, and Oil and Gas Extraction",
  },
  {
    label: "Other Services (except Public Administration)",
    value: "Other Services (except Public Administration)",
  },
  {
    label: "Professional, Scientific and Technical Services",
    value: "Professional, Scientific and Technical Services",
  },
  {
    label: "Public Administration",
    value: "Public Administration",
  },
  {
    label: "Real Estate and Rental and Leasing",
    value: "Real Estate and Rental and Leasing",
  },
  {
    label: "Retail Trade",
    value: "Retail Trade",
  },
  {
    label: "Transportation and Warehousing",
    value: "Transportation and Warehousing",
  },
  {
    label: "Utilities",
    value: "Utilities",
  },
  {
    label: "Wholesale Trade",
    value: "Wholesale Trade",
  },
];

export const purposes = [
  {
    value: "Bill clients",
    label: "Bill clients",
  },
  {
    value: "Send money to suppliers/vendors",
    label: "Send money to suppliers/vendors",
  },
  {
    value: "Move funds between your own accounts at different institutions",
    label: "Move funds between your own accounts at different institutions",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const structures = [
  {
    value: "Corporation",
    label: "Corporation",
  },
  {
    value: "Non-profit",
    label: "Non-profit",
  },
  {
    value: "Partnership",
    label: "Partnership",
  },
  {
    value: "Trust",
    label: "Trust",
  },
];

export const documentTypesCorp = [
  {
    value: "Certificate of Incorporation",
    label: "Certificate of Incorporation",
  },
  {
    value: "Certificate of Active Corporate Status",
    label: "Certificate of Active Corporate Status",
  },
  {
    value: "CRA Document",
    label: "CRA Document",
  },
  {
    value: "Annual Record",
    label: "Annual Record",
  },
  {
    value: "Notice of Assessment",
    label: "Notice of Assessment",
  },
];

export const documentTypeOther = [
  {
    value: "Partnership Agreement",
    label: "Partnership Agreement",
  },
  {
    value: "Articles of Association",
    label: "Articles of Association",
  },
  {
    value: "Evidence to confirm existence of entity",
    label: "Evidence to confirm existence of entity",
  },
  {
    value: "Notice of Assessment",
    label: "Notice of Assessment",
  },
];

export const fileTypes = {
  CSV: "text/csv",
  TXT: "text/plain",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const DEFAULT_TAGS = { CUSTOMER: "customer", SUPPLIER: "supplier" };

export const getActiveRoute = (routes) => {
  let activeRoute = "";
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].views);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      if (
        window.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
  }
  return activeRoute;
};

export const getCurrentState = (states) => {
  const reversedStates = states.slice().reverse();
  const lastHoldIndex = reversedStates.findLastIndex(
    (s) => s.state === TransactionStates.ON_HOLD
  );
  const lastResumeIndex = reversedStates.findLastIndex(
    (s) => s.state === TransactionStates.RESUMED
  );

  if (lastHoldIndex > lastResumeIndex) {
    return reversedStates[lastHoldIndex];
  }
  return states[0];
};

export const cancellableTransactionStates = [
  TransactionStates.REQUESTED,
  TransactionStates.DRAFT,
  TransactionStates.EMAIL_ERROR,
  TransactionStates.CANCELLED,
  TransactionStates.WAITING_APPROVAL,
];

export const verifyStatementNames = (value) => {
  let values = value.split("");
  let verify = false;
  values.forEach((value) => {
    if (!AllowedChars.includes(value)) {
      verify = true;
    }
  });
  return verify;
};

export const cleanCompanyName = (value) => {
  let values = value.split("");
  values.forEach(function (item, index, object) {
    if (!AllowedChars.includes(item)) {
      object.splice(index, 1);
    }
  });
  if (values.length > 15) {
    values = values.splice(0, 15);
  }
  return values.join("");
};

export const verifyLength = (value, length) => {
  return value.length >= length;
};

export const emailRex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const verifyEmail = (value) => {
  return emailRex.test(value);
};

export const verifyMaxTwoDecimal = (value) => {
  return /^[0-9]*\.?[0-9]{0,2}$/.test(value);
};
export const verifyPhone = (value) => {
  const phoneRegex = /^\(?([0-9]{10})$/;
  return phoneRegex.test(value);
};

export const convertToDate = (date, skipHolidays = false) => {
  const formattedDate = new Date(date);
  const convertedDate = skipHolidays
    ? getNextBusinessDay(formattedDate)
    : moment(formattedDate);

  return convertedDate.format("MMM DD, YYYY");
};

/**
 *
 * @param {Date} date
 */
export const getNextBusinessDay = (date) => {
  const momentumBusinessDaysInstance = getMomentBusinessDaysInstance(date);

  return !momentumBusinessDaysInstance.isBusinessDay()
    ? momentumBusinessDaysInstance.nextBusinessDay()
    : momentumBusinessDaysInstance;
};

// todo: move to Formatters.jsx
export const formatDate = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

// todo: move to Formatters.jsx
export const formatDateTime = (date) => {
  return moment(date).format("MMM DD, h:mm A");
};

// todo: move to Formatters.jsx
export const formatDateYear = (date) => {
  return moment(date).format("MMMM D, YYYY");
};

export const endOfDay = (date) => {
  return moment(date).endOf("day").toDate();
};

export const dateToIsoString = (date) => {
  const dateObject = new Date(date);
  return dateObject.toISOString();
};

export const validateStartDate = (currentDate, endDate) => {
  let future = moment() < currentDate;
  if (currentDate.date() > 28) {
    return false;
  }
  if (endDate) {
    return future && endDate > currentDate;
  }
  return future;
};

export const validateEndDate = (currentDate, startDate, endDate) => {
  if (startDate) {
    return startDate < currentDate;
  }
  return validateStartDate(currentDate, endDate);
};

export const getBankAccountsSelection = (bankAccounts) => {
  if (bankAccounts) {
    return bankAccounts.map((account) => ({
      key: account.identifier,
      value: account.identifier,
      variableDebitPad: account.variableDebitPad,
      institution: account.institution,
      label: (
        <>
          {`${account.title} - ${
            account.institution
          } - ${account.accountNumber.slice(-3)} `}
          <span style={{ paddingRight: "5px" }}></span>
          {account?.convergeMerchantAccount?.merchantId && (
            <IndicatorCircle id="ConvergeIndicator" />
          )}
        </>
      ),
    }));
  }
};

export const getBankAccountOptionById = (
  bankAccounts,
  id,
  defaultValue = null
) => {
  if (!id) {
    return {
      value: defaultValue,
      label: "Let recipient decide",
    };
  }
  const bankAccount = bankAccounts?.find(
    (account) => account.identifier === id
  );
  if (bankAccount) {
    return {
      key: bankAccount.identifier,
      value: bankAccount.identifier,
      label: `${bankAccount.institution} - ${
        bankAccount.title
      } - ${bankAccount.accountNumber.slice(-3)}`,
    };
  }
};

export const getContactBankAccountsSelection = (
  bankAccounts,
  defaultValue = null
) => {
  if (bankAccounts) {
    return [
      ...bankAccounts
        .filter((account) => account.state !== "PROCESSING")
        .map((account) => ({
          key: account.identifier,
          value: account.identifier,
          label: `${account.institution} - ${
            account.title
          } - ${account.accountNumber.slice(-3)}`,
        })),
      {
        value: defaultValue,
        label: "Let recipient decide",
      },
    ];
  }
};

export const defaultToContact = {
  value: "",
  label: "Search name, email, or tag",
};

export function enumValue(value) {
  if (value) {
    return "<" + value + ">";
  } else {
    return undefined;
  }
}

export const valueOrNA = (value) => {
  if (value == null || value == undefined) {
    return "N/A";
  }

  return value;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const PRODUCTION = "PRODUCTION";
export const DEVELOPMENT = "DEVELOPMENT";
export const STAGING = "STAGING";
export const LOCAL = "LOCAL";
export const GRAPHQL = "GRAPHQL";
export const DEMO = "DEMO";

export const ENV = (() => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
    case "prod":
      return PRODUCTION;
    case "develop":
    case "development":
      return DEVELOPMENT;
    case "stage":
    case "staging":
      return STAGING;
    case "graphql":
    case "feature":
      return GRAPHQL;
    case "demo":
      return DEMO;
    default:
      return LOCAL;
  }
})();

export const baseUrl = {
  PRODUCTION: "https://jetpay.baselinepayments.com",
  DEVELOPMENT: "https://dev.jetpay.baselinepayments.com",
  STAGING: "https://app.staging.mazumago.com", //TODO: set up Baseline staging
  GRAPHQL: "https://app.dev2.mazumago.com",
  DEMO: "https://demo.jetpay.baselinepayments.com",
  LOCAL: "http://localhost:3000",
}[ENV];

export const stripeKey = (() => {
  switch (ENV) {
    case PRODUCTION:
      return "pk_live_51Lp0elEivRH7PY8iN1La8FgmYG5IdEOqM837OXaH73gYAA25xuChpT1ivnYCvIAQMM5vRgZ6Hsu9apbzhkJttLdD008X0hbXWI";
    case DEMO:
    case DEVELOPMENT:
    default:
      return "pk_test_51Lp0elEivRH7PY8i65nyEByWHCcox0gwrXjESUUNjQzv3k3SBtiqdq1LyavgEAGYKkOsTaeoonmFUt56XOxQYD1X00wSVb7v2p";
  }
})();

export const convergeLightboxHostLib = (() => {
  switch (ENV) {
    case PRODUCTION:
      return "https://api.convergepay.com/hosted-payments/PayWithConverge.js";
    default:
      return "https://demo.convergepay.com/hosted-payments/PayWithConverge.js";
  }
})();

export const appName = "Jetpay";
export const orgName = "Baseline";

export const domains = {
  PRIMARY: "www.jetpayit.com",
  HELP: "help.jetpay.baselinepayments.com",
  MEETINGS: "meetings.jetpay.baselinepayments.com",
  FLINKS_PRIV: "baselinepayments-iframe.private.fin.ag",
};

export const emailAccounts = {
  SUPPORT: "support@baselinepayments.com",
  NOTIFY: "notification@baselinepayments.com",
};

// https://payments.ca/system-closure-schedule
const holidays = [
  "12-26-2022",
  "12-27-2022",
  "01-02-2023",
  "04-07-2023",
  "05-22-2023",
  "07-03-2023",
  "09-04-2023",
  "10-02-2023",
  "10-09-2023",
  "11-13-2023",
  "12-25-2023",
  "12-26-2023",
];

// TEST IDs
// export const GREENFOOT_IDs = [
//   "4ef87264-d37e-4ede-922d-93ae5b6acd9e",
//   "4663d9f4-7c97-4a43-a3e4-51182dfd34cc",
// ];

// PROD GREENFOOT IDs
export const GREENFOOT_IDs = [];
// export const GREENFOOT_IDs = [
//   "7dd73886-8edf-4fe9-83f9-337c3c7733fb",
//   "4d790fd5-4c06-4c02-be85-2f9c2371529b",
// ];

export const BASELINE_IDs = [
  "11d85d8c-b9fc-4fcf-8dae-158c715a508d",
  "5f3cb0a2-79c3-4bcf-9337-818316943fd9",
];

export const getMomentBusinessDaysInstance = (date) => {
  momentBus.updateLocale("us", {
    holidays: holidays,
    holidayFormat: "MM-DD-YYYY",
  });

  return momentBus(date);
};

export const estDepositDay = () => {
  momentBus.updateLocale("us", {
    holidays: holidays,
    holidayFormat: "MM-DD-YYYY",
  });
  let momentformattedDate = momentBus();
  let timeZoneMoment = moment().tz("America/Toronto");
  // Vopay funding cutoff time
  let timeZoneCutOff = moment("15:00", "hh:mm").tz("America/Toronto");

  if (momentformattedDate.isBusinessDay()) {
    if (timeZoneMoment.isBefore(timeZoneCutOff)) {
      momentformattedDate = momentformattedDate.businessAdd(2)._d;
    } else {
      momentformattedDate = momentformattedDate.businessAdd(3)._d;
    }
  } else {
    momentformattedDate = momentformattedDate.businessAdd(2)._d;
  }
  momentformattedDate = moment(momentformattedDate);
  return momentformattedDate.format("MMM DD, YYYY");
};

export const estReRouteDay = () => {
  momentBus.updateLocale("us", {
    holidays: holidays,
    holidayFormat: "MM-DD-YYYY",
  });
  let momentformattedDate = momentBus();
  let timeZoneMoment = moment().tz("America/Toronto");
  let timeZoneCutOff = moment("15:45", "hh:mm").tz("America/Toronto");

  if (momentformattedDate.isBusinessDay()) {
    if (timeZoneMoment.isBefore(timeZoneCutOff)) {
      momentformattedDate = momentformattedDate.businessAdd(2)._d;
    } else {
      momentformattedDate = momentformattedDate.businessAdd(3)._d;
    }
  } else {
    momentformattedDate = momentformattedDate.businessAdd(2)._d;
  }
  momentformattedDate = moment(momentformattedDate);
  return momentformattedDate.format("MMM DD, YYYY");
};

export const initiatedDay = () => {
  momentBus.updateLocale("us", {
    holidays: holidays,
    holidayFormat: "MM-DD-YYYY",
  });
  let momentformattedDate = momentBus();
  let timeZoneMoment = moment().tz("America/Toronto");
  let timeZoneCutOff = moment("15:45", "hh:mm").tz("America/Toronto");
  if (momentformattedDate.isBusinessDay()) {
    if (!timeZoneMoment.isBefore(timeZoneCutOff)) {
      momentformattedDate = momentformattedDate.businessAdd(1)._d;
    }
  } else {
    momentformattedDate = momentformattedDate.nextBusinessDay()._d;
  }
  momentformattedDate = moment(momentformattedDate);
  return momentformattedDate.format("MMM DD, YYYY");
};

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2f2f2f",
    color: "#ffffff",
    maxWidth: 285,
    fontSize: theme.typography.pxToRem(13),
    padding: 15,
  },
  arrow: {
    color: "#2f2f2f",
  },
}))(Tooltip);

export const statuses = [
  {
    value: ["COMPLETED"],
    label: "Deposited",
  },
  {
    value: ["REQUESTED"],
    label: "Link created or Email sent", // Email sent or Link created (Just a user-facing label and is different than the "INITIATED state)
  },
  {
    value: [
      "INITIATED", // Different than Email sent or Link created
      "PULLED",
      "PUSHED",
      "PENDING",
      "RETURNING",
      "REROUTED",
      "RETRYING",
      "PAID",
    ],
    label: "Processing",
  },
  {
    value: ["RESUMED"],
    label: "Resumed",
  },
  {
    value: ["ON_HOLD"],
    label: "On hold",
  },
  {
    value: ["CANCELLED"],
    label: "Cancelled",
  },
  {
    value: ["FAILED"],
    label: "Failed",
  },
];

export const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const directions = [
  { value: DEBIT, label: "Receivable" },
  { value: CREDIT, label: "Payable" },
  { value: INTERNAL, label: "Transfer" },
];

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const CREDIT_CARD_FEE_RATE = 0.035;

export function camelToSentenceCase(str) {
  return str
    .split(/([A-Z]|\d)/)
    .map((v, i, arr) => {
      // If first block then capitalise 1st letter regardless
      if (!i) return v.charAt(0).toUpperCase() + v.slice(1);
      // Skip empty blocks
      if (!v) return v;
      // Underscore substitution
      if (v === "_") return " ";
      // We have a capital or number
      if (v.length === 1 && v === v.toUpperCase()) {
        const previousCapital = !arr[i - 1] || arr[i - 1] === "_";
        const nextWord = i + 1 < arr.length && arr[i + 1] && arr[i + 1] !== "_";
        const nextTwoCapitalsOrEndOfString =
          i + 3 > arr.length || (!arr[i + 1] && !arr[i + 3]);
        // Insert space
        if (!previousCapital || nextWord) v = " " + v;
        // Start of word or single letter word
        if (nextWord || (!previousCapital && !nextTwoCapitalsOrEndOfString))
          v = v.toLowerCase();
      }
      return v;
    })
    .join("");
}

export const RailzIntegrations = {
  quickbooks: "Quickbooks",
  xero: "Xero",
  freshbooks: "Freshbooks",
  sandbox: "Sandbox",
};

export const calcBillsPaymentTotal = (bills) => {
  let billTotalAmounts = bills
    .map((b) => {
      if (!b.selection.value) {
        return 0;
      }
      return b.amount?.value || 0;
    })
    .map(Number);
  let paymentAmount = billTotalAmounts.reduce(function (a, b) {
    return a + b;
  }, 0);
  return formattedDollarValue(paymentAmount);
};

export const getAccountingServiceLogo = (serviceName) => {
  switch (serviceName) {
    case "quickbooks":
      return Quickbooks;
    case "xero":
      return Xero;
    case "freshbooks":
      return Freshbooks;
    default:
      return null;
  }
};

// https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const amountPattern =
  /\s?(?<gt>>=?)?(?<lt><=?)?\$?\b(?<value>\d[\d,]*(?:\.\d?\d?)?)\b/g;

export const amountParser = (search) => {
  let amounts = search.matchAll(amountPattern);
  if (amounts) {
    amounts = [...amounts];
    let sorted = amounts.map((amount) => ({
      val: parseFloat(amount.groups.value.replace(/,/g, "")),
      lt: amount.groups.lt,
      gt: amount.groups.gt,
    }));
    sorted.sort((a, b) => a.val - b.val);

    if (sorted.length === 0) {
      return { gte: undefined, lte: undefined };
    } else if (sorted.length === 1) {
      let amount = sorted[0];
      if (amount.gt) {
        let gte = (
          amount.gt.includes("=") ? amount.val : amount.val + 0.01
        ).toFixed(2);
        return { gte, lte: undefined };
      } else if (amount.lt) {
        let lte = (
          amount.lt.includes("=") ? amount.val : amount.val - 0.01
        ).toFixed(2);
        return { lte, gte: undefined };
      } else {
        let floor = Math.floor(amount.val);
        let plus99 = (floor + 0.99).toFixed(2);
        return { gte: floor.toFixed(2), lte: plus99 };
      }
    } else {
      if (sorted.length > 2) {
        sorted.splice(1, sorted.length - 2);
      }
      return { gte: sorted[0].val.toFixed(2), lte: sorted[1].val.toFixed(2) };
    }
  }
  return { gts: undefined, lte: undefined };
};

export const pluralize = (
  text,
  condition = true,
  pluralizedText = undefined
) => {
  if (!condition) return text;

  if (pluralizedText) {
    return pluralizedText;
  }

  return `${text}s`;
};

export const nextOnboardingPage = (currentOnboardingPage, flow) => {
  const nextPageIndex = flow.indexOf(currentOnboardingPage) + 1;
  return flow[nextPageIndex];
};

const getOnboardingPage = (page) => {
  const pages = {
    "business-type": "Business Type",
    profile: "Profile",
    "organization-legal": "Legal Information",
    "individual-legal": "Legal Information",
    owners: "Owners",
    identity: "Identity",
  };

  return pages[page];
};

export const getKYCFlow = (data, updateStatus) => {
  let flow;
  if (data.isOwner) {
    if (!updateStatus) {
      if (data.company.companyType === ORGANIZATION) {
        flow = onboardOwnerOrg;
      } else {
        flow = onboardOwnerSoleProp;
      }
    } else {
      if (data.company.kyc.updateRequired || data.company.kyc.resubmitted) {
        if (data.company.companyType === ORGANIZATION) {
          flow = refreshOwnerOrg;
        } else {
          flow = refreshOwnerSoleProp;
        }
      } else {
        flow = onboardOrRefreshUser;
      }
    }
  } else {
    flow = onboardOrRefreshUser;
  }

  if (data.company.kyc.refreshFields) {
    let refreshFieldsPages = Object.keys(
      JSON.parse(data.company.kyc.refreshFields)
    );
    flow = flow.filter(
      (flowPage) =>
        refreshFieldsPages.includes(getOnboardingPage(flowPage)) ||
        flowPage === "complete"
    );
  }

  return flow;
};

export const getRefreshText = (refreshFields, page) => {
  refreshFields = JSON.parse(refreshFields);
  page = getOnboardingPage(page);
  let result = "Please update the following fields on this page:";
  for (const field of refreshFields[page]) {
    result += "\n- " + field;
  }
  return result;
};

export const businessId = [
  {
    id: 1,
    value: "Retail",
    label: "Retail",
  },
  {
    id: 2,
    value: "Digital Products",
    label: "Digital Products",
  },
  {
    id: 3,
    value: "Food and drink",
    label: "Food and drink",
  },
  {
    id: 4,
    value: "Professional services",
    label: "Professional services",
  },
  {
    id: 5,
    value: "Membership organizations",
    label: "Membership organizations",
  },
  {
    id: 6,
    value: "Personal services",
    label: "Personal services",
  },
  {
    id: 7,
    value: "Transportation",
    label: "Transportation",
  },
  {
    id: 8,
    value: "Travel and lodging",
    label: "Travel and lodging",
  },
  {
    id: 9,
    value: "Medical services",
    label: "Medical services",
  },
  {
    id: 10,
    value: "Education",
    label: "Education",
  },
  {
    id: 11,
    value: "Entertainment and recreation",
    label: "Entertainment and recreation",
  },
  {
    id: 12,
    value: "Building services",
    label: "Building services",
  },
  {
    id: 13,
    value: "Financial services",
    label: "Financial services",
  },
  {
    id: 14,
    value: "Regulated and age restricted products",
    label: "Regulated and age restricted products",
  },
];

export const businessCategoryId = [
  {
    business_id: 1,
    category_id: 1,
    value: "Software",
    label: "Software",
  },
  {
    business_id: 1,
    category_id: 2,
    value: "Clothing and accessories",
    label: "Clothing and accessories",
  },
  {
    business_id: 1,
    category_id: 3,
    value: "Convenience stores",
    label: "Convenience stores",
  },
  {
    business_id: 1,
    category_id: 4,
    value: "Beauty products",
    label: "Beauty products",
  },
  {
    business_id: 1,
    category_id: 5,
    value: "Home goods & furniture",
    label: "Home goods & furniture",
  },
  {
    business_id: 1,
    category_id: 6,
    value: "Home electronics",
    label: "Home electronics",
  },
  {
    business_id: 1,
    category_id: 7,
    value: "Auto part and accessories",
    label: "Auto part and accessories",
  },
  {
    business_id: 1,
    category_id: 8,
    value: "Jewelry stores,watches,clocks and silverware stores",
    label: "Jewelry stores,watches,clocks and silverware stores",
  },
  {
    business_id: 1,
    category_id: 9,
    value: "Precious stones and metals,watches and jewelry",
    label: "Precious stones and metals,watches and jewelry",
  },
  {
    business_id: 1,
    category_id: 10,
    value: "Other merchandise",
    label: "Other merchandise",
  },
  {
    business_id: 2,
    category_id: 11,
    value: "Software as a service",
    label: "Software as a service",
  },
  {
    business_id: 2,
    category_id: 12,
    value: "Apps",
    label: "Apps",
  },
  {
    business_id: 2,
    category_id: 13,
    value: "Books",
    label: "Books",
  },
  {
    business_id: 2,
    category_id: 14,
    value: "Music or other media",
    label: "Music or other media",
  },
  {
    business_id: 2,
    category_id: 15,
    value: "Games",
    label: "Games",
  },
  {
    business_id: 2,
    category_id: 16,
    value: "Blogs and written content",
    label: "Blogs and written content",
  },
  {
    business_id: 2,
    category_id: 17,
    value: "Other digitals goods",
    label: "Other digitals goods",
  },
  {
    business_id: 3,
    category_id: 18,
    value: "Restaurant and night life",
    label: "Restaurant and night life",
  },
  {
    business_id: 3,
    category_id: 19,
    value: "Grocery stores",
    label: "Grocery stores",
  },
  {
    business_id: 3,
    category_id: 19,
    value: "Grocery stores",
    label: "Grocery stores",
  },
  {
    business_id: 3,
    category_id: 20,
    value: "Caterers",
    label: "Caterers",
  },
  {
    business_id: 3,
    category_id: 21,
    value: "Other food and dining",
    label: "Other food and dining",
  },
  {
    business_id: 4,
    category_id: 22,
    value: "Consulting",
    label: "Consulting",
  },
  {
    business_id: 4,
    category_id: 23,
    value: "Printing and publishing",
    label: "Printing and publishing",
  },
  {
    business_id: 4,
    category_id: 24,
    value: "Attorneys and lawyers",
    label: "Attorneys and lawyers",
  },
  {
    business_id: 4,
    category_id: 25,
    value: "Bankruptcy services",
    label: "Bankruptcy services",
  },
  {
    business_id: 4,
    category_id: 26,
    value: "Bail bonds",
    label: "Bail bonds",
  },
  {
    business_id: 4,
    category_id: 27,
    value: "Accounting,auditing,or tax prep",
    label: "Accounting,auditing,or tax prep",
  },
  {
    business_id: 4,
    category_id: 28,
    value: "Computer repair",
    label: "Computer repair",
  },
  {
    business_id: 4,
    category_id: 29,
    value: "Testing laborotories",
    label: "Testing laborotories",
  },
  {
    business_id: 4,
    category_id: 30,
    value: "Auto services",
    label: "Auto services",
  },
  {
    business_id: 4,
    category_id: 31,
    value: "Car rentals",
    label: "Car rentals",
  },
  {
    business_id: 4,
    category_id: 32,
    value: "Car sales",
    label: "Car sales",
  },
  {
    business_id: 4,
    category_id: 33,
    value: "Lead generation",
    label: "Lead generation",
  },
  {
    business_id: 4,
    category_id: 34,
    value: "Direct marketing",
    label: "Direct marketing",
  },
  {
    business_id: 4,
    category_id: 35,
    value: "Utilities",
    label: "Utilities",
  },
  {
    business_id: 4,
    category_id: 36,
    value: "Government services",
    label: "Government services",
  },
  {
    business_id: 4,
    category_id: 37,
    value: "Telemarketing",
    label: "Telemarketing",
  },
  {
    business_id: 4,
    category_id: 38,
    value: "Credit counseling and credit repair",
    label: "Credit counseling and credit repair",
  },
  {
    business_id: 4,
    category_id: 39,
    value: "Mortgage consulting services",
    label: "Mortgage consulting services",
  },
  {
    business_id: 4,
    category_id: 40,
    value: "Debt reduction services",
    label: "Debt reduction services",
  },
  {
    business_id: 4,
    category_id: 41,
    value: "Warranty services",
    label: "Warranty services",
  },
  {
    business_id: 4,
    category_id: 42,
    value: "Other marketing services",
    label: "Other marketing services",
  },
  {
    business_id: 4,
    category_id: 43,
    value: "Other business services",
    label: "Other business services",
  },
  {
    business_id: 5,
    category_id: 44,
    value: "Civic faternal, or social association",
    label: "Civic faternal, or social association",
  },
  {
    business_id: 5,
    category_id: 45,
    value: "Charities or social service organizations",
    label: "Charities or social service organizations",
  },
  {
    business_id: 5,
    category_id: 46,
    value: "Religious organizations",
    label: "Religious organizations",
  },
  {
    business_id: 5,
    category_id: 47,
    value: "Country clubs",
    label: "Country clubs",
  },
  {
    business_id: 5,
    category_id: 48,
    value: "Political organizations",
    label: "Political organizations",
  },
  {
    business_id: 5,
    category_id: 49,
    value: "Other membership organizations",
    label: "Other membership organizations",
  },
  {
    business_id: 6,
    category_id: 50,
    value: "Photography studios",
    label: "Photography studios",
  },
  {
    business_id: 6,
    category_id: 51,
    value: "Health and beauty spas",
    label: "Health and beauty spas",
  },
  {
    business_id: 6,
    category_id: 52,
    value: "Salons and barbers",
    label: "Salons and barbers",
  },
  {
    business_id: 6,
    category_id: 53,
    value: "Landscaping services",
    label: "Landscaping services",
  },
  {
    business_id: 6,
    category_id: 54,
    value: "Massage parlors",
    label: "Massage parlors",
  },
  {
    business_id: 6,
    category_id: 55,
    value: "Counseling services",
    label: "Counseling services",
  },
  {
    business_id: 6,
    category_id: 56,
    value: "Health and wellness coaching",
    label: "CHealth and wellness coaching",
  },
  {
    business_id: 6,
    category_id: 57,
    value: "Laundry or cleaning services",
    label: "Laundry or cleaning services",
  },
  {
    business_id: 6,
    category_id: 58,
    value: "Dating services",
    label: "Dating services",
  },
  {
    business_id: 6,
    category_id: 58,
    value: "Other personal services",
    label: "Other personal services",
  },
  {
    business_id: 7,
    category_id: 60,
    value: "Ridesharing",
    label: "Ridesharing",
  },
  {
    business_id: 7,
    category_id: 61,
    value: "Taxis and limos",
    label: "Taxis and limos",
  },
  {
    business_id: 7,
    category_id: 62,
    value: "Courier services",
    label: "Courier services",
  },
  {
    business_id: 7,
    category_id: 63,
    value: "Parking lots",
    label: "Parking lots",
  },
  {
    business_id: 7,
    category_id: 64,
    value: "Travel agencies",
    label: "Travel agencies",
  },
  {
    business_id: 7,
    category_id: 65,
    value: "Freight forwarders",
    label: "Freight forwarders",
  },
  {
    business_id: 7,
    category_id: 66,
    value: "Shipping or forwarding",
    label: "Shipping or forwarding",
  },
  {
    business_id: 7,
    category_id: 67,
    value: "Commuter transportation",
    label: "Commuter transportation",
  },
  {
    business_id: 7,
    category_id: 68,
    value: "Cruise lines",
    label: "Cruise lines",
  },
  {
    business_id: 7,
    category_id: 69,
    value: "Airlines and air carriers",
    label: "Airlines and air carriers",
  },
  {
    business_id: 7,
    category_id: 70,
    value: "Other transportation services",
    label: "Other transportation services",
  },
  {
    business_id: 8,
    category_id: 71,
    value: "Property rentals",
    label: "Property rentals",
  },
  {
    business_id: 8,
    category_id: 72,
    value: "Hotels,inns, or motels",
    label: "Hotels,inns, or motels",
  },
  {
    business_id: 8,
    category_id: 73,
    value: "Timeshares",
    label: "Timeshares",
  },
  {
    business_id: 8,
    category_id: 74,
    value: "Other travel and lodging",
    label: "Other travel and lodging",
  },
  {
    business_id: 9,
    category_id: 75,
    value: "Medical devices",
    label: "Medical devices",
  },
  {
    business_id: 9,
    category_id: 76,
    value: "Doctors and physcians",
    label: "Doctors and physcians",
  },
  {
    business_id: 9,
    category_id: 77,
    value: "Opticians and eyeglasses",
    label: "Opticians and eyeglasses",
  },
  {
    business_id: 9,
    category_id: 78,
    value: "Dentists and orthodontists",
    label: "Dentists and orthodontists",
  },
  {
    business_id: 9,
    category_id: 79,
    value: "Chiropractors",
    label: "Chiropractors",
  },
  {
    business_id: 9,
    category_id: 80,
    value: "Nursing or personalcare facilities",
    label: "Nursing or personalcare facilities",
  },
  {
    business_id: 9,
    category_id: 81,
    value: "Hospitals",
    label: "Hospitals",
  },
  {
    business_id: 9,
    category_id: 82,
    value: "Personal fundraising and crowdfunding",
    label: "Personal fundraising and crowdfunding",
  },
  {
    business_id: 9,
    category_id: 83,
    value: "Mental health services",
    label: "Mental health services",
  },
  {
    business_id: 9,
    category_id: 84,
    value: "Assisted living",
    label: "Assisted living",
  },
  {
    business_id: 9,
    category_id: 85,
    value: "Veterinary services",
    label: "Veterinary services",
  },
  {
    business_id: 9,
    category_id: 86,
    value: "Medical organizations",
    label: "Medical organizations",
  },
  {
    business_id: 9,
    category_id: 87,
    value: "Telemedicine and telehealth",
    label: "Telemedicine and telehealth",
  },
  {
    business_id: 9,
    category_id: 88,
    value: "Other medical services",
    label: "Other medical services",
  },
  {
    business_id: 10,
    category_id: 89,
    value: "Child care services",
    label: "Child care services",
  },
  {
    business_id: 10,
    category_id: 90,
    value: "Colleges or universities",
    label: "Colleges or universities",
  },
  {
    business_id: 10,
    category_id: 91,
    value: "Vocational schools or trade schools",
    label: "Vocational schools or trade schools",
  },
  {
    business_id: 10,
    category_id: 92,
    value: "Elementary or Secondary schools",
    label: "Elementary or Secondary schools",
  },
  {
    business_id: 10,
    category_id: 93,
    value: "Other educational services",
    label: "Other educational services",
  },
  {
    business_id: 11,
    category_id: 94,
    value: "Event ticketing",
    label: "Event ticketing",
  },
  {
    business_id: 11,
    category_id: 95,
    value: "Tourist attractions",
    label: "Tourist attractions",
  },
  {
    business_id: 11,
    category_id: 96,
    value: "Recreational camps",
    label: "Recreational camps",
  },
  {
    business_id: 11,
    category_id: 97,
    value: "Musicians,bands,or orchestras",
    label: "Musicians,bands,or orchestras",
  },
  {
    business_id: 11,
    category_id: 98,
    value: "Amusement parks,carnivals, or circuses",
    label: "Amusement parks,carnivals, or circuses",
  },
  {
    business_id: 11,
    category_id: 99,
    value: "Fortune tellers",
    label: "Fortune tellers",
  },
  {
    business_id: 11,
    category_id: 100,
    value: "Movie theatres",
    label: "Movie theatres",
  },
  {
    business_id: 11,
    category_id: 101,
    value: "Betting or fantasy sports",
    label: "Betting or fantasy sports",
  },
  {
    business_id: 11,
    category_id: 102,
    value: "Lotteries",
    label: "Lotteries",
  },
  {
    business_id: 11,
    category_id: 103,
    value: "Sports forecasting or prediction services",
    label: "Sports forecasting or prediction services",
  },
  {
    business_id: 11,
    category_id: 104,
    value: "Online gambling",
    label: "Online gambling",
  },
  {
    business_id: 11,
    category_id: 105,
    value: "Other entertainment and recreation",
    label: "Other entertainment and recreation",
  },
  {
    business_id: 12,
    category_id: 106,
    value: "General contractors",
    label: "General contractors",
  },
  {
    business_id: 12,
    category_id: 107,
    value: "Electrical contractors",
    label: "Electrical contractors",
  },
  {
    business_id: 12,
    category_id: 108,
    value: "Carpentry contractors",
    label: "Carpentry contractors",
  },
  {
    business_id: 12,
    category_id: 109,
    value: "Special trade contractors",
    label: "Special trade contractors",
  },
  {
    business_id: 12,
    category_id: 110,
    value: "Telecom services",
    label: "Telecom services",
  },
  {
    business_id: 12,
    category_id: 111,
    value: "Telecom equipment",
    label: "Telecom equipment",
  },
  {
    business_id: 12,
    category_id: 112,
    value: "A/C and heating contractors",
    label: "A/C and heating contractors",
  },
  {
    business_id: 12,
    category_id: 113,
    value: "Other building services",
    label: "Other building services",
  },
  {
    business_id: 13,
    category_id: 114,
    value: "Insuarance",
    label: "Insuarance",
  },
  {
    business_id: 13,
    category_id: 115,
    value: "Security brokers or dealers",
    label: "Security brokers or dealers",
  },
  {
    business_id: 13,
    category_id: 116,
    value: "Money orders",
    label: "Money orders",
  },
  {
    business_id: 13,
    category_id: 117,
    value: "Currency exchanges",
    label: "Currency exchanges",
  },
  {
    business_id: 13,
    category_id: 118,
    value: "Wire transfers",
    label: "Wire transfers",
  },
  {
    business_id: 13,
    category_id: 119,
    value: "Check cashing",
    label: "Check cashing",
  },
  {
    business_id: 13,
    category_id: 120,
    value: "Loans or lending",
    label: "Loans or lending",
  },
  {
    business_id: 13,
    category_id: 121,
    value: "Collection agencies",
    label: "Collection agencies",
  },
  {
    business_id: 13,
    category_id: 122,
    value: "Money services or transmission",
    label: "Money services or transmission",
  },
  {
    business_id: 13,
    category_id: 123,
    value: "Investment services",
    label: "Investment services",
  },
  {
    business_id: 13,
    category_id: 124,
    value: "Virtual currencies",
    label: "Virtual currencies",
  },
  {
    business_id: 13,
    category_id: 125,
    value: "Digital wallets",
    label: "Digital wallets",
  },
  {
    business_id: 13,
    category_id: 126,
    value: "Cryptocurrencies",
    label: "Cryptocurrencies",
  },
  {
    business_id: 13,
    category_id: 127,
    value: "Other financial institutions",
    label: "Other financial institutions",
  },
  {
    business_id: 13,
    category_id: 128,
    value: "Financial information and research",
    label: "Financial information and research",
  },
  {
    business_id: 14,
    category_id: 129,
    value: "Pharmacies or pharmaceuticals",
    label: "Pharmacies or pharmaceuticals",
  },
  {
    business_id: 14,
    category_id: 130,
    value: "Tobacco or cigars",
    label: "Tobacco or cigars",
  },
  {
    business_id: 14,
    category_id: 131,
    value: "Adult content or services",
    label: "Adult content or services",
  },
  {
    business_id: 14,
    category_id: 132,
    value: "Vapes, e-cigarettes, e-juices or related products",
    label: "Vapes, e-cigarettes, e-juices or related products",
  },
  {
    business_id: 14,
    category_id: 133,
    value: "Weapons or munitions",
    label: "Weapons or munitions",
  },
  {
    business_id: 14,
    category_id: 134,
    value: "Supplements or nutraceuticals",
    label: "Supplements or nutraceuticals",
  },
  {
    business_id: 14,
    category_id: 135,
    value: "Marijuana dispensaries",
    label: "Marijuana dispensaries",
  },
  {
    business_id: 14,
    category_id: 136,
    value: "Marijuana related products",
    label: "Marijuana related products",
  },
  {
    business_id: 14,
    category_id: 137,
    value: "Accessories for tobacco and marijuana",
    label: "Accessories for tobacco and marijuana",
  },
  {
    business_id: 14,
    category_id: 138,
    value: "Alcohol",
    label: "Alcohol",
  },
];

export const addressProvinceOptions = [
  {
    value: "AB",
    label: "Alberta",
  },
  {
    value: "BC",
    label: "British Columbia",
  },
  {
    value: "MB",
    label: "Manitoba",
  },
  {
    value: "NB",
    label: "New Brunswick",
  },
  {
    value: "NL",
    label: "Newfoundland and Labrador",
  },
  {
    value: "NT",
    label: "Northwest Territories",
  },
  {
    value: "NS",
    label: "Nova Scotia",
  },
  {
    value: "NU",
    label: "Nunavut",
  },
  {
    value: "ON",
    label: "Ontario",
  },
  {
    value: "PE",
    label: "Prince Edward Island",
  },
  {
    value: "QC",
    label: "Quebec",
  },
  {
    value: "SK",
    label: "Saskatchewan",
  },
  {
    value: "YT",
    label: "Yukon",
  },
];
export const addressProvinceCode = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];
export const onboardOwnerOrg = [
  "business-type",
  "profile",
  "organization-legal",
  "owners",
  "identity",
  "add-bank",
  "security",
  "complete",
];
export const onboardOwnerSoleProp = [
  "business-type",
  "profile",
  "individual-legal",
  "owners",
  "identity",
  "add-bank",
  "security",
  "complete",
];
export const refreshOwnerOrg = [
  "business-type",
  "profile",
  "organization-legal",
  "owners",
  "identity",
  "complete",
];
export const refreshOwnerSoleProp = [
  "business-type",
  "profile",
  "individual-legal",
  "owners",
  "identity",
  "complete",
];
export const onboardOrRefreshUser = ["profile", "identity", "complete"];
// CURRENTLY KEY CREATED FROM JETPAYDEV ACCOUNT
export const personaTemplateKey = (() => {
  switch (ENV) {
    case PRODUCTION:
      return "itmpl_wZX139fjHM7FydUTAWcPgjWv";
    default:
      return "itmpl_wZX139fjHM7FydUTAWcPgjWv";
  }
})();
