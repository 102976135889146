import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { BusinessInformationValidation } from "../../../modules/form_validation/BusinessInformationValidation";
import { submitCompanyKyc } from "../../../api_client/mutations/kyc";
import useGQL from "../../../api_client/UseGQL";
import { PulseLoader } from "react-spinners";
import { trackEvent } from "../../../modules/analytics";
import { useToasts } from "react-toast-notifications";
import useImageUpload from "../../../hooks/UseImageUpload";
import defaultImage from "../../../assets/img/DocumentPlaceholder.png";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import PropTypes from "prop-types";
import success from "../../../assets/img/onboarding/AddLegalInfoCompleteUpdated.svg";
import {
  documentTypeOther,
  documentTypesCorp,
  domains,
  industries,
  purposes,
  structures,
  addressProvinceOptions,
  addressProvinceCode,
  nextOnboardingPage,
} from "../../../modules/Helpers";
import { formatPostalCode } from "modules/Formatters";
import { useHistory } from "react-router";
import { withApiValue } from "../../../modules/WithApiValue";
import successImage from "../../../assets/img/VerifiedCheckmark.png";
import OnboardingDocuments from "../../../components/forms/OnboardingDocuments";

const config = {
  company: {
    kyc: {
      name: true,
      address: true,
      addressCity: true,
      addressPostalCode: true,
      addressProvince: true,
      structure: true,
      incorporationNumber: true,
      purpose: true,
      industry: true,
      incorporationDocumentType: true,
      incorporationDocument: true,
      submitted: true,
      websiteUrl: true,
      articleOfIncorporation: true,
      amendmentsDocument: true,
      customerInvoice: true,
      bankStatements1: true,
      bankStatements2: true,
      bankStatements3: true,
      dateOfIncorporation: true,
      customerSupportContactPhone: true,
      refreshFields: true,
      updateRequired: true,
    },
  },
  email: true,
};

function OnboardingOrganizationLegal(props) {
  const { values, setValues, errors, handleChange, handleSubmit } = useForm(
    submit,
    BusinessInformationValidation
  );
  const [documentTypes, setDocumentTypes] = useState(documentTypesCorp);
  const [updateOrganizationLegalInfo, setUpdateOrganizationLegalInfo] =
    useState(true);
  const [industry, setIndustry] = useState("");
  const [loading, setLoading] = useState(false);
  const [structure, setStructure] = useState("");
  const [incorporationDocumentType, setIncorporationDocumentType] =
    useState("");
  const [purpose, setPurpose] = useState([]);
  const [otherSelected, setOtherSelected] = useState(false);
  const [other, setOther] = useState("");
  const fileInputRef = useRef(null);
  const [tooltipNameIsOpen, setTooltipNameIsOpen] = useState(false);
  const [tooltipTaxIsOpen, setTooltipTaxIsOpen] = useState(false);
  const [tooltipPurposeIsOpen, setTooltipPurposeIsOpen] = useState(false);
  const [tooltipDocIsOpen, setTooltipDocIsOpen] = useState(false);
  const { addToast } = useToasts();
  const {
    handleImageChange,
    handleImageSubmit,
    handleClick,
    imageState,
    setImageState,
  } = useImageUpload(fileInputRef, defaultImage, "DOCUMENT");
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const customerInvoice = useImageUpload(
    fileInputRef1,
    defaultImage,
    "INVOICE"
  );
  const bankStatements1 = useImageUpload(
    fileInputRef2,
    defaultImage,
    "STATEMENTS",
    "bankStatements1"
  );
  const bankStatements2 = useImageUpload(
    fileInputRef3,
    defaultImage,
    "STATEMENTS",
    "bankStatements2"
  );
  const bankStatements3 = useImageUpload(
    fileInputRef4,
    defaultImage,
    "STATEMENTS",
    "bankStatements3"
  );
  const articleOfIncorporation = useImageUpload(
    fileInputRef5,
    defaultImage,
    "ARTICLE"
  );
  const amendmentsDocuments = useImageUpload(
    fileInputRef6,
    defaultImage,
    "AMENDMENTS"
  );
  const [addressProvince, setAddressProvince] = useState("");

  const history = useHistory();
  const refs = {
    name: useRef(),
    address: useRef(),
    addressCity: useRef(),
    addressProvince: useRef(),
    addressPostalCode: useRef(),
    structure: useRef(),
    incorporationNumber: useRef(),
    industry: useRef(),
    purpose: useRef(),
    incorporationDocumentType: useRef(),
    websiteUrl: useRef(),
    dateOfIncorporation: useRef(),
    customerSupportContactPhone: useRef(),
  };
  let gqlHooks = useGQL();
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2f2f2f",
      color: "#ffffff",
      maxWidth: 270,
      fontSize: theme.typography.pxToRem(13),
      padding: 15,
    },
    arrow: {
      color: "#2f2f2f",
    },
  }))(Tooltip);
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    if (Boolean(localStorage.getItem("legalInformation")) === true) {
      setSubmitClicked(true);
    }
    if (props.company) {
      if (
        props.company.kyc.incorporationDocument !== "" &&
        props.company.kyc.customerInvoice !== "" &&
        props.company.kyc.bankStatements1 !== "" &&
        props.company.kyc.bankStatements2 !== "" &&
        props.company.kyc.bankStatements3 !== ""
      ) {
        let updateValues = props.company.kyc.refreshFields
          ? JSON.parse(props.company.kyc.refreshFields)["Legal Information"]
          : [];
        if (
          updateValues &&
          localStorage.getItem("legalInformationUpdated") === "false"
        ) {
          for (const updateValue of updateValues) {
            switch (updateValue) {
              case "Business legal name":
                props.company.kyc.name = null;
                break;
              case "Business website":
                props.company.kyc.websiteUrl = null;
                break;
              case "Business address":
                props.company.kyc.address = null;
                break;
              case "Business address city":
                props.company.kyc.addressCity = null;
                break;
              case "Business address province":
                props.company.kyc.addressProvince = null;
                break;
              case "Business postal code":
                props.company.kyc.addressPostalCode = null;
                break;
              case "Date of Incorporation":
                props.company.kyc.dateOfIncorporation = null;
                break;
              case "Customer Support Contact Phone":
                props.company.kyc.customerSupportContactPhone = null;
                break;
              case "Business Structure":
                props.company.kyc.structure = null;
                break;
              case "Tax identification number":
                props.company.kyc.incorporationNumber = null;
                break;
              case "Industry":
                props.company.kyc.industry = null;
                break;
              case "Purpose of transactions":
                props.company.kyc.purpose = "";
                break;
              case "Proof of incorporation document":
                props.company.kyc.incorporationDocument = "";
                break;
              case "Recent customer invoice":
                props.company.kyc.customerInvoice = "";
                break;
              case "3 most recent bank statements":
                props.company.kyc.bankStatements1 = "";
                props.company.kyc.bankStatements2 = "";
                props.company.kyc.bankStatements3 = "";
                break;
              case "Articles of Incorporation":
                props.company.kyc.articleOfIncorporation = "";
                break;
              case "Amendments Document":
                props.company.kyc.amendmentsDocument = "";
                break;
              default:
                break;
            }
          }
        }

        setValues({ ...props.company.kyc });
        if (props.company.kyc.addressProvince) {
          const filter = addressProvinceOptions.filter(
            (val) => val.value === props.company.kyc.addressProvince
          );
          if (filter.length > 0) {
            setAddressProvince(filter[0]);
          } else {
            setAddressProvince(filter);
          }
        }
        setPurpose(
          purposes.filter(({ value }) =>
            props.company.kyc.purpose
              ?.split(", ")
              .some((n) => value.includes(n))
          )
        );
        setIndustry({
          value: props.company.kyc.industry,
          label: props.company.kyc.industry,
        });
        setStructure({
          value: props.company.kyc.structure,
          label: props.company.kyc.structure,
        });
        setIncorporationDocumentType({
          value: props.company.kyc.incorporationDocumentType,
          label: props.company.kyc.incorporationDocumentType,
        });
        if (props.company?.kyc.incorporationDocument !== "") {
          setImageState({
            file: { name: props.company.kyc.incorporationDocument },
          });
        }
        if (props.company?.kyc.articleOfIncorporation !== "") {
          articleOfIncorporation.setImageState({
            file: { name: props.company.kyc.articleOfIncorporation },
          });
        }
        if (props.company?.kyc.amendmentsDocument !== "") {
          amendmentsDocuments.setImageState({
            file: { name: props.company.kyc.amendmentsDocument },
          });
        }
        if (props.company?.kyc.customerInvoice !== "") {
          customerInvoice.setImageState({
            file: { name: props.company.kyc.customerInvoice },
          });
        }
        if (props.company?.kyc.bankStatements1 !== "") {
          bankStatements1.setImageState({
            file: { name: props.company.kyc.bankStatements1 },
          });
        }
        if (props.company?.kyc.bankStatements2 !== "") {
          bankStatements2.setImageState({
            file: { name: props.company.kyc.bankStatements2 },
          });
        }
        if (props.company?.kyc.bankStatements3 !== "") {
          bankStatements3.setImageState({
            file: { name: props.company.kyc.bankStatements3 },
          });
        }
      }
    }
    trackEvent("onboardingLegalInfoOrganization", {}, gqlHooks);
  }, [props.company]);

  useEffect(() => {
    if (structure.label === "Corporation") {
      setDocumentTypes(documentTypesCorp);
    } else {
      setDocumentTypes(documentTypeOther);
    }
  }, [structure]);

  const provinceCodeValidation = () => {
    return addressProvinceCode.includes(addressProvince.value);
  };

  async function submit() {
    localStorage.setItem("legalInformationUpdated", "true");
    if (
      !imageState.file ||
      !articleOfIncorporation.imageState.file ||
      imageState.file.name === "" ||
      articleOfIncorporation.imageState.file.name === "" ||
      incorporationDocumentType.value === "" ||
      incorporationDocumentType.value == null
    ) {
      addToast("Please upload company details before continuing", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    if (!provinceCodeValidation()) {
      addToast("Invalid value of the provinces", {
        appearance: "warning",
        autoDismiss: true,
      });
      setLoading(false);
      return;
    }
    setLoading(true);

    let input = {
      address: values.address,
      addressCity: values.addressCity,
      addressProvince: addressProvince.value,
      addressPostalCode: formatPostalCode(values.addressPostalCode),
      name: values.name,
      structure: structure.value,
      incorporationNumber: values.incorporationNumber,
      purpose: purpose
        .map((p) => {
          if (p.value === "Other") {
            return other;
          } else {
            return p.value;
          }
        })
        .join(", "),
      industry: industry.value,
      incorporationDocumentType: incorporationDocumentType.value,
      websiteUrl: values.websiteUrl,
      dateOfIncorporation: new Date(values.dateOfIncorporation).toISOString(),
      customerSupportContactPhone: values.customerSupportContactPhone,
    };
    let output = {
      kyc: {
        name: true,
      },
    };
    if (imageState.file?.size < 1000000) {
      await handleImageSubmit();
    }
    if (articleOfIncorporation.imageState.file?.size < 1000000) {
      await articleOfIncorporation.handleImageSubmit();
    }
    if (amendmentsDocuments.imageState.file?.size < 1000000) {
      await amendmentsDocuments.handleImageSubmit();
    }
    if (bankStatements1.imageState.file?.size < 1000000) {
      await bankStatements1.handleImageSubmit();
    }
    if (bankStatements2.imageState.file?.size < 1000000) {
      await bankStatements2.handleImageSubmit();
    }
    if (bankStatements3.imageState.file?.size < 1000000) {
      await bankStatements3.handleImageSubmit();
    }
    if (customerInvoice.imageState.file?.size < 1000000) {
      await customerInvoice.handleImageSubmit();
    }
    const response = await submitCompanyKyc(input, output, gqlHooks);
    if (response && response.kyc) {
      setUpdateOrganizationLegalInfo(false);
      trackEvent("legalInformationSubmitted", {}, gqlHooks);
      response.company = { kyc: response.kyc };
      delete response.kyc;
      props.update(response);
    }
    setLoading(false);
    setSubmitClicked(true);
    localStorage.setItem("legalInformation", true);
  }

  function onSelectPurpose(value) {
    let otherSelect = false;
    if (value === null) {
      value = [];
    }
    value.forEach((p) => {
      if (p.value === "Other") {
        otherSelect = true;
      }
    });
    if (!otherSelect) {
      setOther("");
    }
    setOtherSelected(otherSelect);
    setPurpose(value);
  }

  function toggleUpdateLegalInfo() {
    setUpdateOrganizationLegalInfo(true);
    trackEvent("editLegalInformation", {}, gqlHooks);
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 600 }}>
          <Fade>
            {updateOrganizationLegalInfo ? (
              <Fragment>
                <h2 className="text-center mb-2 font-weight-600">
                  Add legal information
                </h2>
                <p className="text-muted text-center mb-3">
                  Learn why we need this information{" "}
                  <a
                    href={`https://${domains.HELP}/why-does-jetpay-need-this-information/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
                <Form className="v-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md>
                      <Label>Business legal name</Label>
                      <CustomTooltip
                        title="The legal name of your business as shown on company documents"
                        placement="top"
                        arrow
                        open={tooltipNameIsOpen}
                        onOpen={() => setTooltipNameIsOpen(true)}
                        onClose={() => setTooltipNameIsOpen(false)}
                        leaveTouchDelay={10000}
                      >
                        <Button
                          onClick={() =>
                            setTooltipNameIsOpen(!tooltipNameIsOpen)
                          }
                          variant="contained"
                          className="btn-link tooltip-button"
                        >
                          <i className="fas fa-info-circle" />
                        </Button>
                      </CustomTooltip>
                      <FormGroup>
                        <Input
                          name="name"
                          type="text"
                          innerRef={refs.name}
                          value={values.name || ""}
                          onChange={handleChange}
                          invalid={!!errors.name}
                          required
                        />
                        <FormFeedback>{errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md>
                      <Label>Business website</Label>
                      <FormGroup>
                        <Input
                          name="websiteUrl"
                          type="text"
                          innerRef={refs.websiteUrl}
                          value={values.websiteUrl || ""}
                          onChange={handleChange}
                          invalid={!!errors.websiteUrl}
                          required
                        />
                        <FormFeedback>{errors.websiteUrl}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md>
                      <FormGroup>
                        <Label>Business address</Label>
                        <Input
                          name="address"
                          type="text"
                          innerRef={refs.address}
                          value={values.address || ""}
                          onChange={handleChange}
                          invalid={!!errors.address}
                          required
                        />
                        <FormFeedback>{errors.address}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md>
                      <FormGroup>
                        <Label>Business address city</Label>
                        <Input
                          name="addressCity"
                          type="text"
                          innerRef={refs.addressCity}
                          value={values.addressCity || ""}
                          onChange={handleChange}
                          invalid={!!errors.addressCity}
                          required
                        />
                        <FormFeedback>{errors.addressCity}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md>
                      <Label>Business address province</Label>
                      <FormGroup>
                        <Select
                          onChange={setAddressProvince}
                          className="react-select default"
                          classNamePrefix="react-select"
                          name="addressProvince"
                          value={addressProvince}
                          options={addressProvinceOptions}
                          placeholder="Select..."
                          ref={refs.addressProvince}
                        />
                        <Input
                          className="p-0 m-0"
                          name="addressProvince"
                          style={{ opacity: 0, height: 0 }}
                          value={addressProvince}
                          readOnly
                          required
                        />
                        <FormFeedback>{errors.industry}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Business postal code</Label>
                        <Input
                          name="addressPostalCode"
                          type="text"
                          innerRef={refs.addressPostalCode}
                          value={values.addressPostalCode || ""}
                          onChange={handleChange}
                          invalid={!!errors.addressPostalCode}
                          required
                        />
                        <FormFeedback>{errors.addressPostalCode}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md>
                      <FormGroup>
                        <Label>Date of Incorporation</Label>
                        <Input
                          name="dateOfIncorporation"
                          type="date"
                          innerRef={refs.dateOfIncorporation}
                          value={values.dateOfIncorporation || ""}
                          onChange={handleChange}
                          invalid={!!errors.dateOfIncorporation}
                          min="0001-01-01"
                          max="9999-12-31"
                          required
                        />
                        <FormFeedback>
                          {errors.dateOfIncorporation}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Customer Support Contact Phone</Label>
                        <Input
                          name="customerSupportContactPhone"
                          type="text"
                          innerRef={refs.customerSupportContactPhone}
                          value={values.customerSupportContactPhone || ""}
                          onChange={handleChange}
                          invalid={!!errors.customerSupportContactPhone}
                          required
                        />
                        <FormFeedback>
                          {errors.customerSupportContactPhone}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <Label>Business Structure</Label>
                      <FormGroup>
                        <Select
                          onChange={setStructure}
                          className="react-select default"
                          classNamePrefix="react-select"
                          name="structure"
                          value={structure}
                          options={structures}
                          placeholder="Select type..."
                          ref={refs.structure}
                        />
                        <Input
                          className="p-0 m-0"
                          name="structure"
                          style={{ opacity: 0, height: 0 }}
                          value={structure}
                          readOnly
                          required
                        />
                        <FormFeedback>{errors.structure}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <Label>Tax identification number</Label>
                      <CustomTooltip
                        title="The number used when filing annual taxes with the CRA. We collect this number to confirm the existence of your corporation. We do not submit reports of your transaction activity to the CRA"
                        placement="top"
                        arrow
                        open={tooltipTaxIsOpen}
                        onOpen={() => setTooltipTaxIsOpen(true)}
                        onClose={() => setTooltipTaxIsOpen(false)}
                        leaveTouchDelay={10000}
                      >
                        <Button
                          onClick={() => setTooltipTaxIsOpen(!tooltipTaxIsOpen)}
                          variant="contained"
                          className="btn-link tooltip-button"
                        >
                          <i className="fas fa-info-circle" />
                        </Button>
                      </CustomTooltip>
                      <FormGroup>
                        <Input
                          name="incorporationNumber"
                          type="text"
                          innerRef={refs.incorporationNumber}
                          value={values.incorporationNumber || ""}
                          onChange={handleChange}
                          invalid={!!errors.incorporationNumber}
                          required
                        />
                        <FormFeedback>
                          {errors.incorporationNumber}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Industry</Label>
                      <FormGroup>
                        <Select
                          onChange={setIndustry}
                          className="react-select default"
                          classNamePrefix="react-select"
                          name="industry"
                          value={industry}
                          options={industries}
                          placeholder="Select..."
                          ref={refs.industry}
                        />
                        <Input
                          className="p-0 m-0"
                          name="industry"
                          style={{ opacity: 0, height: 0 }}
                          value={industry}
                          readOnly
                          required
                        />
                        <FormFeedback>{errors.industry}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Purpose of transactions</Label>
                      <CustomTooltip
                        title="State the nature of the transactions your business will be conducting."
                        placement="top"
                        arrow
                        open={tooltipPurposeIsOpen}
                        onOpen={() => setTooltipPurposeIsOpen(true)}
                        onClose={() => setTooltipPurposeIsOpen(false)}
                        leaveTouchDelay={10000}
                      >
                        <Button
                          onClick={() =>
                            setTooltipPurposeIsOpen(!tooltipPurposeIsOpen)
                          }
                          variant="contained"
                          className="btn-link tooltip-button"
                        >
                          <i className="fas fa-info-circle" />
                        </Button>
                      </CustomTooltip>
                      <FormGroup>
                        <Select
                          onChange={onSelectPurpose}
                          className="react-select default"
                          classNamePrefix="react-select"
                          name="purpose"
                          value={purpose}
                          options={purposes}
                          placeholder="Select all that apply..."
                          ref={refs.purpose}
                          isMulti={true}
                          closeMenuOnSelect={false}
                        />
                        <Input
                          className="p-0 m-0"
                          name="purpose"
                          style={{ opacity: 0, height: 0 }}
                          value={purpose}
                          readOnly
                          required
                        />
                        <FormFeedback>{errors.purpose}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  {otherSelected && (
                    <Row>
                      <Col>
                        <Label>Other purpose</Label>
                        <FormGroup>
                          <Input
                            name="other"
                            value={other}
                            onChange={(event) => setOther(event.target.value)}
                            placeholder="Please describe other purpose"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm="6">
                      <Label>Proof of incorporation document</Label>
                      <CustomTooltip
                        title="E.g. CRA documents, incorporation documents, partnership agreement, etc."
                        placement="top"
                        arrow
                        open={tooltipDocIsOpen}
                        onOpen={() => setTooltipDocIsOpen(true)}
                        onClose={() => setTooltipDocIsOpen(false)}
                        leaveTouchDelay={10000}
                      >
                        <Button
                          onClick={() => setTooltipDocIsOpen(!tooltipDocIsOpen)}
                          variant="contained"
                          className="btn-link tooltip-button"
                        >
                          <i className="fas fa-info-circle" />
                        </Button>
                      </CustomTooltip>
                      <FormGroup>
                        <Select
                          onChange={setIncorporationDocumentType}
                          className="react-select default"
                          classNamePrefix="react-select"
                          name="incorporationDocumentType"
                          value={incorporationDocumentType}
                          options={documentTypes}
                          placeholder="Select document type..."
                          ref={refs.incorporationDocumentType}
                        />
                        <Input
                          className="p-0 m-0"
                          name="incorporationDocumentType"
                          style={{ opacity: 0, height: 0 }}
                          value={incorporationDocumentType}
                          readOnly
                          required
                        />
                        <FormFeedback>
                          {errors.incorporationDocumentType}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <div className="mt-4">
                        <input
                          type="file"
                          onChange={handleImageChange}
                          ref={fileInputRef}
                          accept="image/*,application/pdf"
                          className="d-none"
                        />
                        <Button
                          onClick={handleClick}
                          type="button"
                          className="btn-simple btn-primary btn-sm"
                        >
                          + Upload Document
                        </Button>{" "}
                        <img
                          height={30}
                          src={
                            imageState.file
                              ? successImage
                              : imageState.imagePreviewUrl
                          }
                          alt="..."
                        />
                        <span className="text-xs text-primary font-weight-bold  ">
                          <br />
                          {imageState?.file?.name}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <OnboardingDocuments
                      customerInvoice={customerInvoice}
                      bankStatements1={bankStatements1}
                      bankStatements2={bankStatements2}
                      bankStatements3={bankStatements3}
                      articleOfIncorporation={articleOfIncorporation}
                      amendmentsDocuments={amendmentsDocuments}
                      isOrganization={true}
                      fileInputRef1={fileInputRef1}
                      fileInputRef2={fileInputRef2}
                      fileInputRef3={fileInputRef3}
                      fileInputRef4={fileInputRef4}
                      fileInputRef5={fileInputRef5}
                      fileInputRef6={fileInputRef6}
                    />
                  </div>
                  <Button
                    type="submit"
                    block
                    disabled={loading}
                    color="primary"
                    className="mt-4"
                  >
                    {loading ? (
                      <PulseLoader color="white" size={10} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form>
              </Fragment>
            ) : (
              <Fragment>
                <div className="text-center">
                  <img
                    src={success}
                    alt="Legal Information Submitted"
                    width="100"
                    height="100"
                    className="m-auto d-block"
                  />
                  <h2 className="text-center mt-3 mb-4 font-weight-600">
                    Legal information submitted!
                    <i
                      style={{ fontSize: "14px" }}
                      className="ml-2 cursor-pointer text-primary"
                      onClick={toggleUpdateLegalInfo}
                    >
                      Update
                    </i>
                  </h2>
                  <Button
                    disabled={!submitClicked}
                    onClick={() =>
                      history.push(
                        "/onboarding/" +
                          nextOnboardingPage(
                            "organization-legal",
                            props.kycFlow
                          )
                      )
                    }
                    className="m-auto d-block mt-4"
                    color={"primary"}
                  >
                    Proceed
                  </Button>
                </div>
              </Fragment>
            )}
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(OnboardingOrganizationLegal, config);

OnboardingOrganizationLegal.propTypes = {
  company: PropTypes.object,
  email: PropTypes.string,
  kycFlow: PropTypes.array,
  update: PropTypes.func,
};
