import React, { Fragment, useEffect, useState } from "react";
import TransactionTable from "../../../components/tables/TransactionTable";
import ExportTransactionsModal from "../../../components/modals/ExportTransactionsModal";
import TransactionFilters from "../../../components/modals/TransactionFilters";
import { Button, UncontrolledCollapse } from "reactstrap";
import ExportIcon from "../../../assets/icons/ExportIcon";
import CustomTableSearch from "../../../components/forms/CustomTableSearch";
import { useAppValue } from "../../../contexts/Context";
import FilterIcon from "../../../assets/icons/FilterIcon";
import BasicTabs from "../../../components/tabs/CustomTabs";
import { CREDIT, DEBIT, enumValue, INTERNAL } from "../../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";

function Transactions(props) {
  //todo implement other filters
  const [filters, setFilters] = useState({
    amountLte: undefined,
    amountGte: undefined,
    direction: undefined,
    search: undefined,
    states: undefined,
    billId: undefined,
    contactId: undefined,
    recurringPlanId: undefined,
    toBankAccountId: undefined,
    fromBankAccountId: undefined,
    contactTag: undefined,
    dateGte: undefined,
    dateLte: undefined,
    estDepositDateGte: undefined,
    estDepositDateLte: undefined,
    importSource: undefined,
    transactionType: undefined,
  });
  const history = useHistory();
  const [exportTransactionsModal, setExportTransactionsModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [context] = useAppValue();
  const [direction, setDirection] = useState(undefined);
  const isWireToEFTMode = useIsWireToEFTMode();
  const visibleTabs = getVisibleTabs();
  const defaultTab = getDefaultTab();

  useEffect(() => {
    let count = 0;
    if (filters.states) {
      count++;
    }
    if (filters.contactTag) {
      count++;
    }
    if (filters.importSource) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  function toggleExportTransactionsModal() {
    setExportTransactionsModal(!exportTransactionsModal);
  }

  useEffect(() => {
    if (direction) {
      let directionFilter = DEBIT;
      if (direction === "Payables") {
        directionFilter = CREDIT;
      }
      if (direction === "Transfers") {
        directionFilter = INTERNAL;
      }
      setFilters({
        ...filters,
        direction: enumValue(directionFilter),
      });
    } else {
      setFilters({
        ...filters,
        direction: undefined,
      });
    }
  }, [direction]);

  function getVisibleTabs() {
    return isWireToEFTMode
      ? ["Payables"]
      : ["All", "Receivables", "Payables", "Transfers"];
  }

  function getDefaultTab() {
    return isWireToEFTMode ? "Payables" : "All";
  }

  return (
    <Fragment>
      <ExportTransactionsModal
        toggle={toggleExportTransactionsModal}
        isOpen={exportTransactionsModal}
        filters={filters}
      />
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between">
            <h2 className="mb-3 font-weight-600 page-title">
              Payments{" "}
              <span className="font-weight-500 text-muted text-xl">
                ({context.transactionCount})
              </span>
            </h2>
            {!isWireToEFTMode && !!context.draftCount && (
              <Button
                className={"btn-primary d-flex row justify-content-center"}
                onClick={() => history.push("/bulk-payment/credit/approve")}
              >
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  style={{ marginBottom: 0, marginRight: 2, fontSize: "18px" }}
                />{" "}
                <span className="icon-btn-label">Approve payments</span>
              </Button>
            )}
          </div>

          <div className="desktop">
            <BasicTabs
              defaultTab={defaultTab}
              pages={visibleTabs}
              changePage={setDirection}
            />
          </div>
          <div className="d-flex flex-row flex-grow-1">
            <CustomTableSearch setFilters={setFilters} filters={filters} />
            <div className="ml-3">
              <Button
                className="btn-simple btn-primary sp-button-simple"
                id="buttonToggler"
              >
                <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                <span className="filter-btn-label">Filter</span>
                {filterCount > 0 && (
                  <Fragment>
                    {" "}
                    <span className="text-warning">{filterCount}</span>
                  </Fragment>
                )}
              </Button>
            </div>
            <div className="desktop">
              <Button
                className="btn-primary sp-button"
                onClick={toggleExportTransactionsModal}
              >
                <ExportIcon height={13} width={13} /> <span>Export</span>
              </Button>
            </div>
          </div>
          <UncontrolledCollapse toggler="#buttonToggler">
            <TransactionFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <TransactionTable {...props} filters={filters} />
      </div>
    </Fragment>
  );
}

export default Transactions;
