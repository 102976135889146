import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LogoWithText from "assets/img/LogoWithText.png";
import useGQL from "api_client/UseGQL";
import { appName } from "modules/Helpers";
import { trackEvent } from "modules/analytics";
import HelpContactButton from "components/buttons/HelpContactNumberButton";
import DownloadButton from "components/buttons/DownloadButton";

function BrandedPrintablePageLayout({ children, printEventName }) {
  let gqlHooks = useGQL();

  function printPage() {
    window.print();
    if (printEventName) {
      trackEvent(printEventName, {}, gqlHooks);
    }
  }

  return (
    <Fragment>
      <div className="section-to-print">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between border-bottom border-background">
          <div className="text-center text-md-left mb-3">
            <img
              src={LogoWithText}
              alt={`${appName} Logo`}
              width="180"
              className="d-block"
            />
          </div>
          <div className="text-center text-md-left mb-3">
            <HelpContactButton />
            <div className="d-inline-block hide-on-print">
              <DownloadButton onClick={printPage} />
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </Fragment>
  );
}

BrandedPrintablePageLayout.propTypes = {
  children: PropTypes.node,
  printEventName: PropTypes.string,
};

export default BrandedPrintablePageLayout;
