import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { convertToDate } from "../../modules/Helpers";
import ContactTagBadge from "../badge/ContactTagBadge";
import { useHistory } from "react-router";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

function ContactsCard(props) {
  const history = useHistory();

  return (
    <Fragment>
      <Card
        className="t-card"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=${OVERLAY_TYPES.CONTACT}&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="font-weight-bold">
              {props.item?.name?.substring(0, 15)}
            </div>
            <div className="text-muted">
              {props.item?.email
                ? props.item?.email?.substring(0, 15)
                : "Pending Payment Acceptance"}
            </div>
          </div>
          <div className="flex-column text-right">
            <span className="text-sm font-weight-600">Created</span>
            <br />
            <span className="text-muted text-sm">
              {convertToDate(props.item?.createdAt)}
            </span>
          </div>
        </div>
        <div className="ml-1 mt-2">
          <div className="t-card-row justify-content-between">
            <div className="flex-column">
              {props.item?.tags &&
                ContactTagBadge(props.item?.tags, props.item?.identifier)}
            </div>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default ContactsCard;

ContactsCard.propTypes = {
  item: PropTypes.object,
  detailRoute: PropTypes.string,
};
