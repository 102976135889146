import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { Fragment, useState } from "react";
import useGQL from "../../../../../../api_client/UseGQL";
import { Button, Col, Row } from "reactstrap";
import pickBy from "lodash.pickby";
import { createBatchDebit } from "../../../../../../api_client/mutations/accountingIntegration";
import BankDetail from "../../../../../../components/generic_details/BankDetail";
import { PulseLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import {
  BUSINESS,
  enumValue,
  pluralize,
} from "../../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { useAppValue } from "../../../../../../contexts/Context";
import { calculateTotalAmount } from "views/admin/payments/credit/bulk_payments/bulk_approval/ReviewBulkApproval";

export const BulkDebitReview = () => {
  const [context] = useAppValue();
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const totalAmount = calculateTotalAmount(state.bulkDebits || []);
  const numTransactions = state.bulkDebits?.length || 0;
  const requiresApproval = !context.user?.isOwnerOrAdmin;
  const many = numTransactions > 1;

  async function submit() {
    setLoading(true);
    const debitBatch = state.bulkDebits.map((rawDebit) => {
      const debit = {
        amount: rawDebit.amount,
        statement: rawDebit.statement,
        padType: enumValue(BUSINESS),
        note: rawDebit.note,
        contactName: rawDebit.contact.name,
        contactEmail: rawDebit.contact.email,
        toBankAccountId: state.toBankAccount.key,
        sendEmail: true,
      };
      // remove entries with undefined values
      return pickBy(debit, (debitProp) => debitProp);
    });

    const input = {
      debitBatch: debitBatch,
    };
    const output = {
      numDebitsCreated: true,
      numDebitsFailed: true,
      validationErrors: {
        index: true,
        message: true,
      },
      totalAmountReceived: true,
      transactions: {
        identifier: true,
      },
    };
    const response = await createBatchDebit(input, output, gqlHooks);
    setLoading(false);
    if (response?.validationErrors?.length) {
      /*
      const mockDebitErrors = [
        {
          index: "1",
          message: "Failed to post to bank",
        },
      ];
      */
      setState({
        ...state,
        debitErrors: [...response.validationErrors],
        // debitErrors: [...response.validationErrors, ...mockDebitErrors],
        totalAmountReceived: response?.totalAmountReceived,
        numDebitsFailed: response?.numDebitsFailed,
        numDebitsCreated: response?.numDebitsCreated,
        transactions: response?.transactions,
      });
      history.push("/new-payment/debit/bulk/failed-payments", {
        ...history.location.state,
        currStep: 4,
      });
      return;
    }
    if (response) {
      setState({
        ...state,
        totalAmountReceived: response.totalAmountReceived,
        transactions: response.transactions,
      });
      history.push("/new-payment/debit/bulk/complete", {
        ...history.location.state,
        currStep: 4,
      });
    }
  }

  return (
    <Fragment>
      <h2 className="text-center">
        Review {requiresApproval && `draft `} payment{" "}
        {pluralize("request", many)}
      </h2>
      {requiresApproval && (
        <div className="new-payment-inner-content text-center pt-0">
          <span className="text-center">
            You&apos;re {requiresApproval ? "creating" : "sending"}{" "}
            {numTransactions} {pluralize("payment request", many)} totalling{" "}
            <b>{formattedDollarValue(totalAmount)}</b>.{" "}
            {requiresApproval && (
              <>
                {pluralize(
                  <>
                    This is a <b>draft payment request</b>
                  </>,
                  many,
                  <>
                    These are <b>draft payment requests</b>
                  </>
                )}{" "}
                and will require additional approval.
              </>
            )}
          </span>
        </div>
      )}
      <div className="new-payment-inner-content">
        <h5 className="mb-2">Request from</h5>
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3>{formattedDollarValue(totalAmount)}</h3>
            </Col>
            <Col>
              <span>Number of payment requests</span>
              <h3>{numTransactions}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Delivery method</span>
              <h5>Email</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Frequency</span>
              <h5>One time</h5>
            </Col>
          </Row>
          {/*TODO Can change the wording of this section to make it make sense?*/}
          {/*<hr />*/}
          {/*<Row>*/}
          {/*  <InitiateDateInfo*/}
          {/*    type={INITIATE}*/}
          {/*    isOpen={tooltipInitiatedIsOpen}*/}
          {/*    setOpen={setTooltipInitiatedIsOpen}*/}
          {/*  />*/}
          {/*  <DepositDateInfo*/}
          {/*    isOpen={tooltipDepositIsOpen}*/}
          {/*    setOpen={setTooltipDepositIsOpen}*/}
          {/*  />*/}
          {/*</Row>*/}
        </div>
        <h5 className="mb-2 mt-4">Pay to</h5>
        <div className="review-payment">
          <BankDetail
            description="Your bank account"
            institution={state.toBankAccount.institution}
            label={state.toBankAccount.label}
          />
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          type="button"
          className="btn-success mt-4"
        >
          {loading ? (
            <PulseLoader color="white" size={10} />
          ) : (
            <>
              {requiresApproval
                ? `Create draft ${pluralize("payment request", many)}`
                : `Send ${pluralize("payment request", many)}`}
            </>
          )}
        </Button>
      </div>
    </Fragment>
  );
};
