import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Badge, Card } from "reactstrap";
import { withApiValue } from "../../modules/WithApiValue";
import { useHistory } from "react-router";
import { userPermissionNames } from "../../variables/UserPermissions";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

const config = {
  identifier: true,
};

function UserCard(props) {
  const history = useHistory();
  return (
    <Fragment>
      <Card
        className="t-card shadow-sm border"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=${OVERLAY_TYPES.USER}&id=${props.item.member.identifier}`,
          })
        }
      >
        <div className="t-card-row">
          <span className="text-primary text-sm font-weight-600">
            {userPermissionNames(props.item?.permission)}
          </span>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="font-weight-bold">
              {props.item?.member?.profile?.firstName}{" "}
              {props.item?.member?.profile?.lastName}
            </div>
            <div className="text-muted">
              {props.item?.member?.email
                ? props.item?.member?.email
                : "Pending Acceptance"}
            </div>
            <div className="text-muted">
              {props.item?.member?.profile?.phone
                ? props.item?.member?.profile?.phone
                : ""}
            </div>
          </div>
          <div className="flex-column text-right">
            {props.identifier === props.item?.member?.identifier && (
              <Badge color="primary">This is you</Badge>
            )}
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default withApiValue(UserCard, config);

UserCard.propTypes = {
  identifier: PropTypes.string,
  item: PropTypes.object,
};
