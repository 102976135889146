import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { useHistory } from "react-router";
import {
  convertToDate,
  CREDIT,
  CustomTooltip,
  DEBIT,
  formatDate,
  INTERNAL,
} from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

function BillTransactionCard(props) {
  const history = useHistory();

  return (
    <Fragment>
      <Card
        className="t-card shadow-sm border p-3"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=${OVERLAY_TYPES.BILL}&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row">
          <span className="text-primary text-sm font-weight-600">
            {props.item.direction === CREDIT && "Payable"}
            {props.item.direction === INTERNAL && "Internal"}
            {props.item.direction === DEBIT && "Receivable"}
          </span>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Deposit Date</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div> {formatDate(props.item.estDepositDate)}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Date Created</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>{convertToDate(props.item.createdAt)}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Statement</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>{props.item.statement}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Amount</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>{formattedDollarValue(props.item.amount)}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Payment Cost</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>
              {props.item.nsfCost === null ? (
                <>
                  <div
                    className="mt-1 text-right font-weight-600"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(props.item.cost)}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="mt-1 text-right font-weight-600"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(props.item.cost)}
                  </div>
                  <div className="text-muted text-right text-sm mb-0">
                    <CustomTooltip
                      title={`${formattedDollarValue(
                        props.item.nsfCost
                      )} Non-Sufficient Funds in bank account penalty`}
                      placement="top"
                      arrow
                    >
                      <p className="d-inline-block text-warning">
                        + {formattedDollarValue(props.item.nsfCost)} NSF Penalty
                      </p>
                    </CustomTooltip>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Contact</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>
              {" "}
              <div className="mt-1" style={{ lineHeight: "1.2" }}>
                {props.item.contact && props.item.contact.name
                  ? props.item.contact.name
                  : ""}
              </div>
              <div className="text-muted text-sm mb-1">
                {props.item.direction === INTERNAL ? (
                  "Internal Transfer"
                ) : (
                  <>
                    {props.item.contact.email
                      ? props.item.contact.email
                      : "Pending Payment Acceptance"}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default BillTransactionCard;

BillTransactionCard.propTypes = {
  item: PropTypes.object,
  detailRoute: PropTypes.string,
};
