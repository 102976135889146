import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { enumValue } from "../../modules/Helpers";
import DatePickerModal from "./DatePickerModal";
import { dateToIsoString, endOfDay } from "../../modules/Helpers";
import OperationTypes from "variables/WireToEFTModeOperationTypes";

function BatchesFilters(props) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [operationType, setOperationType] = useState();
  const operationTypeOptions = Object.values(OperationTypes).map((type) => ({
    value: type.name,
    label: type.value,
  }));

  useEffect(() => {
    props.setFilters({
      ...props.filters,
      dateGte:
        dateRange["startDate"] == null
          ? undefined
          : dateToIsoString(dateRange["startDate"]),
      dateLte:
        dateRange["endDate"] == null
          ? undefined
          : dateToIsoString(endOfDay(dateRange["endDate"])),
      operationType: enumValue(operationType?.value),
    });
  }, [dateRange, operationType]);

  return (
    <Fragment>
      <Row>
        <Col md={3}>
          <Label>Date</Label>
          <FormGroup>
            <DatePickerModal
              onChange={(value) => setDateRange(value)}
              isClearable
              clear={() => setDateRange(undefined)}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <Label>Type</Label>
          <Select
            isMulti={false}
            className="react-select default"
            classNamePrefix="react-select"
            onChange={(value) => setOperationType(value)}
            value={operationType}
            options={operationTypeOptions}
            isClearable
            clear={() => setOperationType(undefined)}
          />{" "}
        </Col>
      </Row>
    </Fragment>
  );
}

export default BatchesFilters;

BatchesFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

BatchesFilters.defaultProps = {};
