import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { formatDate } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import CardInfoRow from "./cardElements/CardInfoRow";
import { valueOrNA } from "../../modules/Helpers";
import { BATCH_TABLE_COLUMN_IDS } from "components/tables/BatchesTable";
import { formatBoolean } from "modules/Formatters";

function BatchesCard(props) {
  const hiddenInfo = props.hiddenColumns;
  const isVisible = (key) => !hiddenInfo.includes(key);

  return (
    <Fragment>
      <Card className="t-card shadow-sm border p-3">
        <div className="t-card-row">
          <h5 className="text-primary font-weight-600">
            {formatDate(props.item.operation.date)}
          </h5>
        </div>

        {isVisible(BATCH_TABLE_COLUMN_IDS.ID) && (
          <CardInfoRow label="ID" value={props.item.operation.id} />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.TYPE) && (
          <CardInfoRow
            label="Type"
            value={props.item.operation.operationType}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.IS_ACTIVATED) && (
          <CardInfoRow
            label="Initiated"
            value={formatBoolean(props.item.operation.isActivated)}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_NUMBER) && (
          <CardInfoRow
            label="Number of Transactions"
            value={valueOrNA(props.item.operation.transactionsNumber)}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_FEE) && (
          <CardInfoRow
            label="Transaction Fee"
            value={formattedDollarValue(props.item.operation.transactionsFee)}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.TRANSACTIONS_AMOUNT) && (
          <CardInfoRow
            label="Disbursed Amount"
            value={formattedDollarValue(
              props.item.operation.transactionsAmount
            )}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.BATCH_FEE) && (
          <CardInfoRow
            label="Batch Fee"
            value={formattedDollarValue(props.item.operation.batchFee)}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.MONTHLY_FEE) && (
          <CardInfoRow
            label="Monthly Fee"
            value={formattedDollarValue(props.item.operation.monthlyFee)}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.AMOUNT) && (
          <CardInfoRow
            label="Total"
            value={formattedDollarValue(props.item.operation.amount)}
          />
        )}
        {isVisible(BATCH_TABLE_COLUMN_IDS.BALANCE) && (
          <CardInfoRow
            label="Balance"
            value={formattedDollarValue(props.item.balance)}
          />
        )}
      </Card>
    </Fragment>
  );
}

export default BatchesCard;

BatchesCard.propTypes = {
  item: PropTypes.object,
};
