/* eslint-disable react/prop-types */
import React from "react";

import { PageNotAvailableError } from "../modules/error_wrappers/PageNotAvailableError";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { wireToEFTModeFlow } from "views/admin/payments/credit/bulk_payments/create_bulk_credit/BulkCreditFlows";

export function WireToEFTModeGateway(props) {
  const location = useLocation();
  const isWireToEFTMode = useIsWireToEFTMode();
  const allowedRoutes =
    [
      "/admin/payments",
      "/admin/batches",
      "/new-payment/credit/payment-type",
      "/admin/monthly-statements",
      "/lost",
      "/tos",
    ] + wireToEFTModeFlow;

  // todo: use overlayType constant
  const allowedQueryStrings = [
    /^\?overlay=true&type=payment&id=/,
    /\?overlay=true&type=information/,
    /\?overlay=true&type=notifications/,
    /\?overlay=true&type=monthly-statement/,
    /\?ticket=true/,
    /\?update=true/,
  ];

  const isAccessAllowed = () => {
    if (location.pathname.includes("auth")) {
      return true;
    }

    if (!allowedRoutes.includes(location.pathname)) {
      return false;
    }

    if (location.search) {
      return !!allowedQueryStrings.find((regex) =>
        location.search.match(regex)
      );
    }

    return true;
  };

  if (isWireToEFTMode && !isAccessAllowed()) {
    return (
      <PageNotAvailableError errorBodyText="The page you are trying to view is not available." />
    );
  }

  return props.children;
}
