import React, { Fragment, useEffect, useState } from "react";
import PaymentInfo from "../components/PaymentInfo";
import { Button, Col, Collapse, Container, Row } from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { statusPillPublic } from "../../../components/badge/StatusBadgePublic";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { appName, getCurrentState } from "../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import TransactionTimelinePublic from "../components/TransactionTimelinePublic";
import TransactionProgressBar from "../../../components/progress-bars/TransactionProgressBar";
import PaymentDetailsHeader from "../components/PaymentDetailsHeader";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";
import useExitPrompt from "../../../hooks/useExitPrompt";
import { CustomLoader } from "../../../components/loaders/CustomLoader";

function PaymentTrackStep() {
  const [state] = useSimpleContext();
  const { addToast } = useToasts();
  const [showHistory, setShowHistory] = useState(false);
  const toggleHistory = () => setShowHistory(!showHistory);
  const gqlHooks = useGQL();
  const [, setShowExitPrompt] = useExitPrompt(true);

  useEffect(() => {
    setShowExitPrompt(false);
    if (state.transaction.direction === "CREDIT") {
      trackEvent(
        "creditTrack",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
        },
        gqlHooks
      );
    } else {
      trackEvent(
        "debitTrack",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
        },
        gqlHooks
      );
    }
  }, []);

  const onCopyId = () => {
    addToast("Link copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };
  if (!state.transaction) {
    return <CustomLoader general={true} />;
  }
  return (
    <Fragment>
      {/*Title used for PDF receipt name*/}
      <title>
        {appName}_Payment_{state.transaction.identifier}
      </title>
      <PaymentDetailsHeader
        transaction={state.transaction}
        customClass="tracking-gradient"
      />
      <Container>
        <div className="checkout-spacer">
          <Row>
            <Col className="mt-3">
              <div className="d-flex align-items-center">
                <h3 className="font-weight-600 mb-0">
                  {formattedDollarValue(state.transaction.amount)}
                </h3>
                <div className="ml-2 hide-on-print">
                  {statusPillPublic(
                    getCurrentState(state.transaction.states).state,
                    state.transaction
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <TransactionProgressBar
            transaction={state.transaction}
            toggleHistory={toggleHistory}
            showHistoryState={showHistory}
          />
          <Collapse className="hide-on-print" isOpen={showHistory}>
            <TransactionTimelinePublic
              transaction={state.transaction}
              states={state.transaction.states}
            />
          </Collapse>
        </div>
        <div className="mt-4 pt-2">
          <PaymentInfo />
        </div>
      </Container>
      <Container className="payment-secondary-action bg-white hide-on-print">
        <div className="checkout-spacer">
          <span className="text-muted">Print or share this page:</span>
          <Row>
            <Col md="6">
              <Button
                className="btn-lg btn-primary mb-0 mt-2"
                onClick={() => window.print()}
                block
              >
                Download a PDF Receipt
              </Button>
            </Col>
            <Col md="6">
              <Button className="btn-lg btn-primary mb-0 mt-2" block>
                <CopyToClipboard
                  onCopy={() => onCopyId()}
                  text={window.location.href}
                >
                  <span id={"identifier"} className="cursor-pointer">
                    Share this page
                  </span>
                </CopyToClipboard>
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default PaymentTrackStep;

PaymentTrackStep.propTypes = {};
