import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { Button, Col, Row } from "reactstrap";
import { pluralize } from "../../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import CreateAdditionalPaymentDropdown from "../../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";
import { calculateTotalAmount } from "./ReviewBulkApproval";

export const BulkApprovalComplete = () => {
  const history = useHistory();
  const [state] = useSimpleContext();
  const totalAmount = calculateTotalAmount(state.approvedDraftCredits || []);
  const numTransactions = state.approvedDraftCredits?.length || 0;
  const many = numTransactions > 1;

  return (
    <Fragment>
      <SuccessIcon />
      <h2 className="text-center">{pluralize("Payment", many)} sent</h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          You sent {numTransactions} {pluralize("payment", many)} totalling{" "}
          {formattedDollarValue(totalAmount)}. The payees will receive an email
          confirmation of their payment.
        </span>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary mt-4"
        >
          All done, return to dashboard
        </Button>
        <Row className="mt-3">
          <Col xs="5" className="ml-auto pl-0">
            <hr />
          </Col>
          <Col xs="1">
            <span>or</span>
          </Col>
          <Col xs="5" className="mr-auto pr-0">
            <hr />
          </Col>
        </Row>
        <CreateAdditionalPaymentDropdown />
      </div>
    </Fragment>
  );
};
