import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import { CustomTooltip } from "modules/Helpers";
import PropTypes from "prop-types";

function MonthlyStatementDetailsSection({ title, details }) {
  return (
    <Fragment>
      <Row className="pt-4">
        <Col md="5">
          <div className="flex-row">
            <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
              {title}{" "}
            </h5>
          </div>
          {details.map(({ label, value, tooltipTitle }, index) => (
            <div className="flex-row" key={index}>
              <p className="d-inline-block text-muted mb-2">{label}</p>
              {tooltipTitle ? (
                <CustomTooltip title={tooltipTitle} placement="top" arrow>
                  <p
                    className="pull-right font-weight-600 mb-2"
                    style={{ cursor: "pointer" }}
                  >
                    {value}
                  </p>
                </CustomTooltip>
              ) : (
                <p className="pull-right font-weight-600 mb-2">{value}</p>
              )}
            </div>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
}

export default MonthlyStatementDetailsSection;

MonthlyStatementDetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      tooltipTitle: PropTypes.node.isRequired,
    })
  ),
};
