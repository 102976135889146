/* eslint-disable react-hooks/exhaustive-deps,react/display-name,react/prop-types */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  formatDateYear,
  getBankAccountOptionById,
} from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import ReactTable from "./ReactTable";
import { CardBody } from "reactstrap";
import { useSimpleContext } from "../../contexts/SimpleContext";

function ReviewBillsTable() {
  const [count] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state] = useSimpleContext();

  useEffect(() => {
    setData(state.bills);
  }, []);

  const getData = useCallback(async () => {
    setLoading(true);
    setLoading(false);
  }, []);

  let columns = useMemo(
    () => [
      {
        Header: "Due Date",
        accessor: "text",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>
            {" "}
            {formatDateYear(cell.row.original.dueDate?.value)}
          </Fragment>
        ),
      },
      {
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.row.original.contactName?.value}
            </div>
            <div className="text-muted text-sm mb-1">
              {cell.row.original.contactEmail?.value
                ? cell.row.original.contactEmail?.value
                : "No email"}
            </div>
          </>
        ),
      },
      {
        Header: "Deposit Account",
        accessor: "account",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => {
          const accountOption = getBankAccountOptionById(
            cell.row.original?.contact?.value?.bankAccounts,
            cell.row.original.toBankAccountId.value
          );
          return <Fragment>{accountOption.label}</Fragment>;
        },
      },
      {
        Header: "Statement",
        accessor: "statement",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>{cell.row.original.statement?.value}</Fragment>
        ),
      },
      {
        Header: "Note",
        accessor: "note",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>{cell.row.original.note?.value}</Fragment>
        ),
      },
      {
        Header: " Payment Amount",
        accessor: "amount",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div className="text-right">
            <div className="text-right">
              <div>
                <div className="font-weight-bold" style={{ lineHeight: "1.2" }}>
                  ({formattedDollarValue(cell.row.original.amount?.value)})
                </div>
                <div className="text-muted text-sm">Payable</div>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <CardBody className="review-bills-table">
        <ReactTable
          loading={loading}
          getData={getData}
          data={data}
          columns={columns}
          count={count}
          styles="transaction-table"
        />
      </CardBody>
    </>
  );
}

export default ReviewBillsTable;
