export const supportPhoneNumber = {
  countryCode: "+1",
  areaCode: "877",
  number: "7349206",
};

export const billingAddress = {
  street: "200 - 2288 Manitoba St",
  city: "Vancouver",
  province: "BC",
  postalCode: "V5Y 4B5",
};
