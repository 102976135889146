import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { Fragment } from "react";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import { pluralize } from "../../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { Button, Col, Row } from "reactstrap";
import CreateAdditionalPaymentDropdown from "../../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";
import { useAppValue } from "../../../../../../contexts/Context";

export const BulkCreditComplete = ({
  disableAdditionalPaymentsDropdown = false,
}) => {
  const [context] = useAppValue();
  const history = useHistory();
  const [state] = useSimpleContext();
  const totalAmount = state.totalAmountSent;
  const numTransactions = state.transactions?.length || 0;
  const many = numTransactions > 1;
  const requiresApproval = state.requiresApproval;

  return (
    <Fragment>
      <SuccessIcon />
      <h2 className="text-center">
        {requiresApproval
          ? `Draft ${pluralize("payment", many)} created`
          : `${pluralize("Payment", many)} sent`}
      </h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          You {requiresApproval ? "created" : "sent"}{" "}
          <b>
            {numTransactions} {pluralize("payment", many)}
          </b>{" "}
          totalling <b>{formattedDollarValue(totalAmount)}</b>.{" "}
          {requiresApproval
            ? `An account admin will receive a notification to approve pending payments.`
            : "The payees will receive an email confirmation of their payment."}
        </span>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary mt-4"
        >
          All done, return to dashboard
        </Button>
        {!disableAdditionalPaymentsDropdown && (
          <>
            <Row className="mt-3">
              <Col xs="5" className="ml-auto pl-0">
                <hr />
              </Col>
              <Col xs="1">
                <span>or</span>
              </Col>
              <Col xs="5" className="mr-auto pr-0">
                <hr />
              </Col>
            </Row>
            <CreateAdditionalPaymentDropdown />
          </>
        )}
      </div>
    </Fragment>
  );
};
