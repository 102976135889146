import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { Button, Col, Row } from "reactstrap";
import { formattedDollarValue } from "modules/Formatters";
import WarningIcon from "react-bootstrap-sweetalert/dist/components/WarningIcon";
import { withApiValue } from "../../../../../../modules/WithApiValue";
import PropTypes from "prop-types";

const config = {
  company: {
    railzConnection: {
      serviceName: true,
    },
  },
};

function CreditPaymentWarningSuccessStep(props) {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const sentPaymentsCount =
    state.bills.length - state.failedTransactions.length;
  const failedPaymentsCount = state.failedTransactions.length;

  return (
    <Fragment>
      <WarningIcon />
      <h2 className="text-center">Payment warning</h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          <b>
            {sentPaymentsCount} bill payment
            {sentPaymentsCount > 1 && "s"} sent
          </b>{" "}
          for a total of <b>{formattedDollarValue(state.totalAmountSent)}</b>.
          The payee
          {sentPaymentsCount > 1 && "s"} will receive an email confirmation of
          this payment.{" "}
        </span>
        <div className="mt-3">
          <span>
            <b>
              {failedPaymentsCount} bill payment{failedPaymentsCount > 1 && "s"}{" "}
              failed
            </b>{" "}
            to send. Please retry importing. If the error persists please
            contact support.
          </span>
        </div>
        <div
          style={{ background: "#fff8d2", borderLeft: "8px solid #ffe664" }}
          className="text-left p-3 mt-4"
        >
          <h5 className="mb-1">Tip</h5>
          <p>
            Don&apos;t forget to mark completed bills as{" "}
            <span className="font-italic font-weight-500">paid</span> in your{" "}
            {props.company.railzConnection.serviceName.capitalize()} account.
          </p>
        </div>
        <Button
          onClick={() => {
            history.push("import", {
              ...history.location.state,
              currStep: 2,
            });
            setState({});
          }}
          block
          className="btn-primary mt-4"
        >
          Retry importing failed bill{failedPaymentsCount > 1 && "s"}
        </Button>
        <Row className="mt-3">
          <Col xs="5" className="ml-auto pl-0">
            <hr />
          </Col>
          <Col xs="1">
            <span>or</span>
          </Col>
          <Col xs="5" className="mr-auto pr-0">
            <hr />
          </Col>
        </Row>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary btn-link mt-0 font-weight-500"
        >
          Try again later, return to dashboard
        </Button>
      </div>
    </Fragment>
  );
}

export default withApiValue(CreditPaymentWarningSuccessStep, config);

CreditPaymentWarningSuccessStep.propTypes = {
  company: PropTypes.object,
};
