import React from "react";
import { Col, Row } from "reactstrap";
import { appName, BUSINESS, orgName, PERSONAL } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import PropTypes from "prop-types";

function InternalPAD(props) {
  return (
    <>
      <div>
        <Row>
          <Col className="ml-auto mr-auto p-0">
            <blockquote className="blockquote">
              <p className="blockquote-footer">
                Type of PAD:{" "}
                {props.type === PERSONAL ? "Personal." : "Business."}
              </p>
              <p className="blockquote-footer font-weight-bold">
                YOU WAIVE ANY LEGISLATIVE OR REGULATORY REQUIREMENT FOR
                PRE-NOTIFICATION.
              </p>
              <p className="blockquote-footer">
                Transaction dates that fall on a weekend or holiday will be
                processed the next business day.
              </p>
              <p className="blockquote-footer">
                You agree to be debited a{" "}
                <u>
                  <b>
                    {props.recurringFrequency ? "recurring" : "one time"}{" "}
                    payment of {formattedDollarValue(props.amount)}/
                    {props.recurringFrequency}
                  </b>
                </u>
                .
              </p>
              <p className="blockquote-footer">
                You have certain recourse rights if any debit does not comply
                with this agreement. For example, you have the right to receive
                reimbursement for any debit that is not authorized or is not
                consistent with this PAD Agreement. To obtain more information
                on your recourse rights, contact your financial institution or
                visit <u>payments.ca</u>.
              </p>
              <p className="blockquote-footer">
                If the first attempt to debit your account fails, you agree to
                allow us to debit your account one additional time for the
                amount specified in this agreement.
              </p>
              <p className="blockquote-footer">
                <b>
                  If multiple attempts fail to debit your account you agree to
                  be charged a CA $30 NSF fee. Your {appName} account will also
                  be frozen until payment has been made.
                </b>
              </p>
              <p className="blockquote-footer">
                This PAD is being administered by{" "}
                <u>
                  <b>
                    {appName} by {orgName}
                  </b>
                </u>
                . ({appName})
              </p>
            </blockquote>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default InternalPAD;

InternalPAD.propTypes = {
  amount: PropTypes.string,
  type: PropTypes.string,
  recurringFrequency: PropTypes.string,
};

InternalPAD.defaultProps = {
  amount: "0.00",
  type: BUSINESS,
};
