/* eslint-disable react-hooks/exhaustive-deps,react/prop-types */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { formatDate, FREQUENCY_OPTIONS } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { recurringStatus } from "../badge/StatusBadgeRecurring";
import useGQL from "../../api_client/UseGQL";
import { allRecurringPlans } from "../../api_client/queries/recurringPlans";
import PropTypes from "prop-types";
import ReactTable from "./ReactTable";
import ContactTagBadge from "../badge/ContactTagBadge";
import TransactionCardSkeleton from "../skeleton/TransactionCardSkeleton";
import RecurringPlanCard from "../cards/RecurringPlanCard";
import QueryString from "query-string";
import { useHistory, useLocation } from "react-router";

function RecurringPlanTable(props) {
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let gqlHooks = useGQL();
  const [update, setUpdate] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
      history.push({ search: "" });
    }
  }, [location]);

  const getData = useCallback(
    async ({ pageSize }) => {
      setLoading(true);
      let offset = 0;
      let params = {
        ...props.filters,
        limit: pageSize,
        offset: offset,
      };
      let output = {
        identifier: true,
        createdAt: true,
        nextRun: true,
        active: true,
        start: true,
        end: true,
        amount: true,
        statement: true,
        frequency: true,
        toBankAccount: {
          institution: true,
        },
        fromBankAccount: {
          createdAt: true,
        },
        customerCreditCard: {
          brand: true,
          last4: true,
        },
        contact: {
          identifier: true,
          email: true,
          name: true,
          tags: true,
        },
      };
      const response = await allRecurringPlans(params, output, gqlHooks);
      if (response) {
        setData(response.data);
        setCount(response.count);
        props.getNumPlans(response.count);
      }
      setLoading(false);
    },
    [props.filters, update]
  );

  let columns = useMemo(
    () => [
      {
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <Fragment>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value.name ? cell.value.name : ""}
            </div>
            <div className="text-muted text-sm">
              {cell.value ? cell.value.email : "Pending Payment Acceptance"}
            </div>
          </Fragment>
        ),
      },
      {
        Header: "Tags",
        accessor: "none",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <div>
            {ContactTagBadge(
              cell.row.original.contact.tags,
              cell.row.original.contact.identifier
            )}
          </div>
        ),
      },
      {
        Header: "Statement",
        accessor: "statement",
      },
      {
        Header: "Start Date",
        accessor: "start",
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <Fragment>
            <div className="text-muted">
              {formatDate(cell.row.original.start)}
            </div>
          </Fragment>
        ),
      },
      {
        Header: "Next Payment",
        accessor: "next",
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <Fragment>
            <div className="text-muted">
              {formatDate(cell.row.original.nextRun)}
            </div>
          </Fragment>
        ),
      },
      {
        Header: "Status",
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <Fragment>{recurringStatus(cell.row.original)}</Fragment>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <Fragment>
            <div
              className="text-right font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {formattedDollarValue(cell.row.original.amount)}
            </div>
            <div className="text-muted text-right text-sm">
              {
                FREQUENCY_OPTIONS.find((frequency) => {
                  return frequency.value === cell.row.original.frequency;
                }).label
              }
            </div>
          </Fragment>
        ),
      },
    ],
    []
  );

  return (
    <Fragment>
      <ReactTable
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        count={count}
        overlayType="recurring"
        styles="transaction-table"
        MobileCard={RecurringPlanCard}
        MobileSkeleton={TransactionCardSkeleton}
        numTd={7}
      />
    </Fragment>
  );
}

export default RecurringPlanTable;

RecurringPlanTable.propTypes = {
  filters: PropTypes.object,
  search: PropTypes.string,
  getNumPlans: PropTypes.func,
};
