/* eslint-disable react/prop-types */
import { allMonthlyStatements } from "api_client/queries/wireToEft";
import React, { useCallback, useMemo, useState } from "react";
import { convertToDate } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import useGQL from "../../api_client/UseGQL";
import ReactTable from "./ReactTable";
import TransactionCardSkeleton from "../skeleton/TransactionCardSkeleton";
import PropTypes from "prop-types";
import MonthlyStatementsCard from "components/cards/MonthlyStatementsCard";
import { formatMonthYear } from "modules/Formatters";
import { OVERLAY_TYPES } from "variables/OverlayTypes";
import { DateCell } from "components/table_cells/DateCell";
import { DollarValueCell } from "components/table_cells/DollarValueCell";

function MonthlyStatementsTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let gqlHooks = useGQL();
  const getData = useCallback(async () => {
    setLoading(true);
    const params = {
      ...props.filters,
    };
    let output = {
      identifier: true,
      openingBalance: true,
      monthlyFees: true,
      numberOfBatches: true,
      batchFees: true,
      numberOfTransactions: true,
      transactionFees: true,
      numberOfFailedTransactions: true,
      failedTransactionFees: true,
      totalDisbursement: true,
      totalReturn: true,
      closingBalance: true,
      year: true,
      month: true,
      openingDate: true,
      closingDate: true,
    };
    const response = await allMonthlyStatements(params, output, gqlHooks);
    if (response) {
      setData(response.data);
      setLoading(false);
    }
  }, []);

  let columns = useMemo(
    () => [
      {
        Header: "Statement Period",
        accessor: "statementPeriod",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ row }) => {
          const statementPeriod = formatMonthYear(
            `${row.original.year}-${row.original.month}`
          );
          return <div className="text-muted">{statementPeriod}</div>;
        },
      },
      {
        Header: "Opening Date",
        accessor: "openingDate",
        Cell: DateCell,
      },
      {
        Header: "Closing Date",
        accessor: "closingDate",
        Cell: DateCell,
      },
      {
        Header: "Number of Batches",
        accessor: "numberOfBatches",
        Cell: ({ cell }) => <div className="text-muted">{cell.value}</div>,
      },
      {
        Header: "Opening Balance",
        accessor: "openingBalance",
        Cell: DollarValueCell,
      },
      {
        Header: "Closing Balance",
        accessor: "closingBalance",
        Cell: DollarValueCell,
      },
    ],
    []
  );

  return (
    <>
      <ReactTable
        hidePagination={true}
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        overlayType={OVERLAY_TYPES.MONTHLY_STATEMENT}
        cards={props.cards}
        MobileCard={MonthlyStatementsCard}
        MobileSkeleton={TransactionCardSkeleton}
        numTd={5}
      />
    </>
  );
}

export default MonthlyStatementsTable;

MonthlyStatementsTable.propTypes = {
  filters: PropTypes.object,
  cards: PropTypes.bool,
};

MonthlyStatementsTable.defaultProps = {
  cards: false,
};
