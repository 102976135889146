import React, { Fragment } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faEnvelope,
  faLink,
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  cancellableTransactionStates,
  CustomTooltip,
} from "../../../modules/Helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TransactionStates from "variables/TransactionStates";

function TransactionsActionsDesktop(props) {
  const cancelButton = cancellableTransactionStates.includes(
    props.transaction.states[0].state
  );
  return (
    <>
      <div className="details-action-wrapper">
        <CustomTooltip title="Get help" placement="bottom" arrow>
          <Button onClick={props.ticket} className="btn-link">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Button>
        </CustomTooltip>
        {props.copyPaymentLink && document.queryCommandSupported("copy") && (
          <CopyToClipboard
            text={props.transaction.link}
            onCopy={() => props.copyPaymentLink()}
          >
            <CustomTooltip title="Copy payment link" placement="bottom" arrow>
              <Button className="btn-link">
                <FontAwesomeIcon icon={faLink} />
              </Button>
            </CustomTooltip>
          </CopyToClipboard>
        )}
        {props.resendPaymentEmail &&
          props.transaction.states[0].state === TransactionStates.REQUESTED && (
            <Fragment>
              {/* This is a hack, method should be email by default for credit transactions */}
              {(props.transaction.method === "EMAIL" ||
                props.transaction.direction === "CREDIT") && (
                <CustomTooltip
                  title={"Resend payment request"}
                  placement="bottom"
                  arrow
                >
                  <Button
                    onClick={props.resendPaymentEmail}
                    className="btn-link"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Button>
                </CustomTooltip>
              )}
            </Fragment>
          )}
        {props.cancelPayment && cancelButton && (
          <>
            <CustomTooltip
              title={
                props.transaction.states[0].state === 8
                  ? "Delete payment request"
                  : "Cancel payment request"
              }
              placement="bottom"
              arrow
            >
              <Button
                onClick={props.cancelPayment}
                className="btn-link text-danger"
              >
                {props.transaction.states[0].state === 8 ? (
                  <FontAwesomeIcon icon={faTrashAlt} />
                ) : (
                  <FontAwesomeIcon icon={faBan} />
                )}
              </Button>
            </CustomTooltip>
          </>
        )}
      </div>
    </>
  );
}

export default TransactionsActionsDesktop;
TransactionsActionsDesktop.propTypes = {
  transaction: PropTypes.object,
  copyPaymentLink: PropTypes.func,
  cancelPayment: PropTypes.func,
  resendPaymentEmail: PropTypes.func,
  ticket: PropTypes.func,
};
