import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ContextProvider, initialState, mainReducer } from "./contexts/Context";
import { ToastProvider } from "react-toast-notifications";
import Admin from "./views/admin/Admin";
import Auth from "./views/auth/Auth";
import Payment from "./views/payments/Payment.jsx";
import FlinksComplete from "./views/payments/paying/FlinksComplete";
import { AuthGateway } from "./gateways/AuthGateway";
import GQLProvider from "./api_client/client";
import VerificationSuccess from "./components/verification/VerificationSuccess";
import { AnalyticsGateway } from "./gateways/AnalyticsGateway";
import { MyCustomToast } from "./modules/CustomToast";
import { hotjar } from "react-hotjar";
import Onboarding from "./views/onboarding/Onboarding";
import OnboardingGateway from "./gateways/OnboardingGateway";
import NewPayment from "./views/admin/payments/NewPayment";
import { SimpleContextProvider } from "./contexts/SimpleContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ValidatedGateway from "./gateways/ValidatedGateway";
import RecurringPayment from "./views/payments/RecurringPayment";
import LostPage from "./components/LostPage";
import { BulkApproval } from "./views/admin/payments/credit/bulk_payments/bulk_approval/BulkApproval";
import { ENV, PRODUCTION, stripeKey } from "./modules/Helpers";
import Customer from "views/customer/Customer";
import TermsOfService from "views/tos";
import SupplierInvite from "views/supplier-invite/SupplierInvite";
import { WireToEFTModeGateway } from "gateways/WireToEFTModeGateway";

const hist = createBrowserHistory();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey);

function App() {
  {
    ENV === PRODUCTION && hotjar.initialize("2204336", "6");
  }
  return (
    <GQLProvider>
      <ContextProvider initialState={initialState()} reducer={mainReducer}>
        <ToastProvider
          components={{ Toast: MyCustomToast }}
          autoDismissTimeout={7000}
        >
          <Elements stripe={stripePromise}>
            <Router history={hist}>
              <AnalyticsGateway>
                <WireToEFTModeGateway>
                  <Switch>
                    <Route
                      path={[
                        "/payment/recurring/accept/:identifier",
                        "/payment/recurring/:identifier",
                        "/payment/recurring/accept/:identifier/*",
                      ]}
                      render={(props) => (
                        <SimpleContextProvider>
                          <RecurringPayment {...props} />
                        </SimpleContextProvider>
                      )}
                    />
                    <Route
                      path={[
                        "/payment/*/:identifier/*",
                        "/payment/*/:identifier",
                      ]}
                      render={(props) => (
                        <SimpleContextProvider>
                          <Payment {...props} />
                        </SimpleContextProvider>
                      )}
                    />
                    <Route
                      path="/plan"
                      render={(props) => (
                        <SimpleContextProvider>
                          <Payment {...props} />
                        </SimpleContextProvider>
                      )}
                    />
                    <Route
                      path="/admin"
                      render={(props) => (
                        <AuthGateway>
                          <Admin {...props} />
                        </AuthGateway>
                      )}
                    />
                    <Route
                      path="/customer"
                      render={(props) => <Customer {...props} />}
                    />
                    <Route
                      path={[
                        "/supplier-invite/:identifier",
                        "/supplier-invite/:identifier/*",
                      ]}
                      render={(props) => (
                        <SimpleContextProvider>
                          <SupplierInvite {...props} />
                        </SimpleContextProvider>
                      )}
                    />
                    <Route
                      path="/new-payment"
                      render={(props) => (
                        <AuthGateway>
                          <ValidatedGateway>
                            <NewPayment {...props} />
                          </ValidatedGateway>
                        </AuthGateway>
                      )}
                    />
                    <Route
                      path="/bulk-payment/credit/approve"
                      render={(props) => (
                        <AuthGateway>
                          <ValidatedGateway>
                            <SimpleContextProvider>
                              <BulkApproval {...props} />
                            </SimpleContextProvider>
                          </ValidatedGateway>
                        </AuthGateway>
                      )}
                    />
                    <Route
                      path="/bulk-payment/credit/create"
                      render={(props) => (
                        <AuthGateway>
                          <ValidatedGateway>
                            <SimpleContextProvider>
                              <NewPayment {...props} />
                            </SimpleContextProvider>
                          </ValidatedGateway>
                        </AuthGateway>
                      )}
                    />
                    <Route
                      path="/bulk-debit/debit/create"
                      render={(props) => (
                        <AuthGateway>
                          <ValidatedGateway>
                            <SimpleContextProvider>
                              <NewPayment {...props} />
                            </SimpleContextProvider>
                          </ValidatedGateway>
                        </AuthGateway>
                      )}
                    />
                    <Route
                      path="/onboarding"
                      render={(props) => (
                        <AuthGateway>
                          <OnboardingGateway>
                            <Onboarding {...props} />
                          </OnboardingGateway>
                        </AuthGateway>
                      )}
                    />
                    <Route
                      path="/auth"
                      render={(props) => <Auth {...props} />}
                    />
                    <Route
                      path="/bank-connected"
                      render={(props) => <FlinksComplete {...props} />}
                    />
                    <Route
                      path="/verification-complete"
                      render={(props) => <VerificationSuccess {...props} />}
                    />
                    <Route
                      path={"/lost"}
                      render={(props) => <LostPage {...props} />}
                    />
                    <Route
                      path="/tos"
                      render={(props) => <TermsOfService {...props} />}
                    />

                    <Redirect from="/*" to="/auth/" />
                  </Switch>
                </WireToEFTModeGateway>
              </AnalyticsGateway>
            </Router>
          </Elements>
        </ToastProvider>
      </ContextProvider>
    </GQLProvider>
  );
}

export default App;
