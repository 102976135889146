import React from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import {
  CreditCardWithdrawalAccount,
  WithdrawalAccount,
} from "./WithdrawalAccount";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

function RecurringPlanPaidFromCard(props) {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col>
          <h4 className="font-weight-600 mt-0 mb-2">Paid from</h4>
        </Col>
      </Row>
      <Card className="details-card shadow-sm border">
        <Row className="details-row border-bottom">
          <Col>
            <h5>Contact</h5>
            {props.recurringPlan.contact ? (
              <p
                className="text-primary cursor-pointer"
                onClick={() =>
                  history.push({
                    search: `overlay=true&type=${OVERLAY_TYPES.CONTACT}&id=${props.recurringPlan.contact.identifier}`,
                  })
                }
              >
                {props.recurringPlan.contact?.name ||
                  props.recurringPlan.contact?.email}
              </p>
            ) : (
              <p className="text-muted font-italic">
                Pending payment acceptance
              </p>
            )}
          </Col>
          <Col sm="6">
            <h5>Delivery method</h5>
            <p>{props.recurringPlan.contact.email}</p>
          </Col>
        </Row>
        <Row className="details-row border-bottom">
          <Col>
            {props.recurringPlan.customerCreditCard ? (
              <CreditCardWithdrawalAccount
                customerCreditCard={props.recurringPlan.customerCreditCard}
              />
            ) : (
              <WithdrawalAccount
                fromBankAccount={props.recurringPlan.fromBankAccount}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default RecurringPlanPaidFromCard;
RecurringPlanPaidFromCard.propTypes = {
  recurringPlan: PropTypes.object,
};
