/* eslint react/prop-types: 0 */
import React from "react";
import { payableFormat } from "modules/csv";
import { allContacts } from "api_client/queries/contacts";
import useGQL from "api_client/UseGQL";
import { BaseBulkCreditDetailsPage } from "./components/BaseBulkCreditDetailsPage";

export const BulkCreditDetails = () => {
  let gqlHooks = useGQL(true);

  const sampleImportData = [
    [
      "name (max 64 characters)",
      "email",
      "amount (min $10 & max $600000)",
      "statement (max 30 characters)",
      "note (max 200 characters)",
    ],
    [
      "Kevin",
      "kevin@mail.com",
      "388.81",
      "INV120",
      "Reimbursement for computer monitor",
    ],
    ["Company", "company@mail.com", "2200", "INV122", "cleaning fee"],
    [
      "AnotherCompany",
      "acompany@mail.com",
      "609.20",
      "INV122",
      "dishwasher repair",
    ],
    ["Sarah", "sarah@mail.com", "992.10", "INV121", "Year end bonus"],
    ["John", "john@mail.com", "9.89", "INV122", "Reimbursement for charger"],
  ];

  async function getContactsByEmail(emailList) {
    let output = {
      identifier: true,
      name: true,
      createdAt: true,
      email: true,
      contactType: true,
      tags: true,
      bankAccounts: {
        identifier: true,
        state: true,
        institution: true,
        title: true,
        accountNumber: true,
      },
    };
    let input = {
      limit: 100,
      offset: 0,
      contacts: emailList,
    };
    let response = await allContacts(input, output, gqlHooks);
    return response;
  }

  async function jsonToTransactions(json) {
    let newTransactions = [];
    let emails = json.map((contact) => {
      return contact.email;
    });

    let response = await getContactsByEmail(emails);
    let bankAccountsDict = {};
    let identifersDict = {};
    response.data.forEach((contact) => {
      bankAccountsDict[contact.email] = contact.bankAccounts;
      identifersDict[contact.email] = contact.identifier;
    });
    newTransactions = json.map((row, index) => {
      let label = "";
      let bankAccounts = [];
      let identifier = null;

      if (row.email in bankAccountsDict) {
        let bankAccount =
          bankAccountsDict[row.email][bankAccountsDict[row.email].length - 1];
        label = bankAccount?.accountNumber;
        bankAccounts = bankAccountsDict[row.email];
      }
      if (row.email in identifersDict) {
        identifier = identifersDict[row.email];
      }
      let transactionObject = {
        toBankAccount: bankAccounts[bankAccounts.length - 1]?.identifier,
        toBankAccountLabel: label ? label : "Let recipient decide",
        amount: row.amount.toString().replace("$", ""),
        statement: row.statement,
        note: row.note,
        contact: {
          name: row.name || row.email.split("@")[0],
          email: row.email,
          tags: [],
          bankAccounts: bankAccounts,
        },
      };
      if (identifier) {
        transactionObject.contact.identifier = identifier;
        return transactionObject;
      } else {
        return transactionObject;
      }
    });
    return newTransactions;
  }

  return (
    <BaseBulkCreditDetailsPage
      csvImportFormat={payableFormat}
      sampleImportData={sampleImportData}
      jsonToTransactions={jsonToTransactions}
    />
  );
};
